import React from 'react'
import { CheckCheck, BadgeCheck } from 'lucide-react';

const Instructions = ({ type }) => {
    return (
        <div className='flex flex-col gap-4 bg-violet-100 px-6 py-5 rounded-3xl'>
            <div className='flex gap-2 items-start'>
                <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                <p className='text-sm'>Use a High-Resolution Camera and do the recording in a well-lit environment.</p>
            </div>
            {type === "video-training" && (
                <div className='flex flex-col gap-2'>
                    <div className='flex gap-2 items-start'>
                        <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                        <p className='text-sm'>Keep your face in 1 position for at least 5 seconds. The positions should be:</p>
                    </div>
                    <div className='flex flex-col gap-1 px-28'>
                        <p className='text-sm'>A. Facing front with mouth closed for 5 sec </p>
                        <p className='text-sm'>B. Turned to the right for 5 sec </p>
                        <p className='text-sm'>C. Turned to the left for 5 sec </p>
                        <p className='text-sm'>D. Facing front and speaking for 5 seconds. </p>
                    </div>
                </div>
            )}
            {type === "video-campaign" && (
                <div className='flex gap-2 items-start'>
                    <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                    <p className='text-sm'>Keep your recording to around 30-seconds for ideal performance </p>
                </div>
            )}
            <div className='flex gap-2 items-start'>
                <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                <p className='text-sm'>Just record your upper body (up to chest area) and DO NOT bring your hands into the frame.</p>
            </div>
            <div className='flex gap-2 items-start'>
                <BadgeCheck className='w-[4%] h-[4%] 2xl:w-[2.8%] 2xl:h-[2.8%] text-green-700 mt-1 rounded-sm' />
                <p className='text-sm'> Only one person should be present in the video frame. There should be NO other faces in the background (including any portraits hanging in the wall).</p>
            </div>
            <div className='flex gap-2 items-start'>
                <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                <p className='text-sm'>Do not wear glasses when recording the video.</p>
            </div>
            {type === "video-campaign" && (
                <div className='flex gap-2 items-start'>
                    <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                    <p className='text-sm'>DO NOT move your body too much. Keep your face and body centered to the camera frame.</p>
                </div>
            )}
        </div>
    )
}

export default Instructions




