import React, { useState, useEffect } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';

import Organism from "common/components/organisms"

const PublicAvatars = ({ publicAvatars }) => {
  const [initialCardsToShow, setInitialCardsToShow] = useState(4);
  const [showMorePublic, setShowMorePublic] = useState(false);


  useEffect(() => {
    const updateInitialCardsToShow = () => {
      if (window.matchMedia('(min-width: 1536px)').matches) {
        setInitialCardsToShow(8); // For 2xl screens
      } else if (window.matchMedia('(min-width: 1024px)').matches) {
        setInitialCardsToShow(6); // For 2xl screens
      } else if (window.matchMedia('(min-width: 768px)').matches) {
        setInitialCardsToShow(4); // For lg screens
      } else if (window.matchMedia('(min-width: 576px)').matches) {
        setInitialCardsToShow(3); // For md screens
      } else {
        setInitialCardsToShow(2); // For smaller screens
      }
    };

    updateInitialCardsToShow();
    window.addEventListener('resize', updateInitialCardsToShow);

    return () => window.removeEventListener('resize', updateInitialCardsToShow);
  }, []);

  const toggleShowMorePublic = () => setShowMorePublic(!showMorePublic);


  return (
    <div className='flex flex-col justify-center p-10'>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-8 gap-4">
        {publicAvatars.slice(0, showMorePublic ? publicAvatars.length : initialCardsToShow).map((avatar, index) => (
          <Organism.AvatarCard
            key={index}
            name={avatar.name}
            status={avatar.status}
            imageUrl={avatar.imageUrl}
            createdOn={avatar.createdOn}
          // isSelected={selectedAvatar === avatar.id}
          // onSelect={() => handleSelect(avatar)}
          />
        ))}
      </div>
      {publicAvatars.length > initialCardsToShow && (
        <button onClick={toggleShowMorePublic} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
          {showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
          <span className="mx-2">{showMorePublic ? 'Show Less' : 'Show More'}</span>
          {/* {showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
        </button>
      )}
    </div>
  )
}

export default PublicAvatars