import ApiCards from "./ApiCards";
import Customers from "./Customers";
import Integrations from "./Integrations";
import PublicAvatars from "./PublicAvatars";
import CustomerDetails from "./CustomerDetails";
import CampaignDetails from "./CampaignDetails";


const AdminOrganism = {
    ApiCards,
    Customers,
    Integrations,
    PublicAvatars,
    CustomerDetails,
    CampaignDetails
}

export default AdminOrganism;