import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Progress } from '../../../../components/ui/progress';
import { MoveLeft, MoveRight, Square, Mic } from 'lucide-react';
import { ThreeCircles } from 'react-loader-spinner';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { sendFiles } from 'apis/APIWrapper';
import AppContext from 'common/context/AppContext';
import Atom from '../../atoms';

const arrayOfParas = [
    {
        id: 1,
        value:
            "Big brown bears bounce on the bed. \nDancing ducks dare to dive in the deep. \nFive funny frogs frolic freely in the forest. \nGreen grapes grow gracefully on the grapevine.",
    },
    {
        id: 2,
        value:
            "Happy horses hop happily on the hill. \nJumping jaguars joyfully jump over the jungle. \nKangaroos kick kites in the kindled sky. \nLively lions lounge lazily under the large tree.",
    },
    {
        id: 3,
        value:
            "Marvelous monkeys munch on mangoes. \nNine nice nurses need new notebooks. \nPlayful puppies play with pink plastic balls. \nRambunctious rabbits race around the rusty railings",
    },
    {
        id: 4,
        value:
            "Silly snakes slither swiftly through the sand. \nTiny turtles take a trip to the tranquil lake. \nViolet violets very visibly bloom in the valley. \nWacky walruses wiggle while wearing wetsuits.",
    },
    {
        id: 5,
        value:
            "Zany zebras zigzag through the zoo's zone. \nShiny seashells shimmer on the shore. \nThe measure of pleasure brings leisure. \nThe father's feather is rather smooth.",
    },
    {
        id: 6,
        value:
            "The thin thief thought about that theory. \nUh-oh! The cat caught a bat. \nThe book took a good look at the cook. \nSee the sea and feel the heat.",
    },
    {
        id: 7,
        value:
            "The little kitten bit the mitten. \nElephants never forget to get the best of them. \nThe black cat sat on a mat. \nThe car started and ran far.",
    },
    {
        id: 8,
        value:
            "The sun is up above us. \nThe hot pot got a lot of water. A cup of tea for me, please. \nDo you choose the blue shoes or the boots?",
    },
    {
        id: 9,
        value:
            "The horse trotted across the orange orchard. \nI spy a bright kite in the sky. How now, brown cow? \nThe snowman goes down the slope.",
    },
    {
        id: 10,
        value:
            "They play on the great lakes. \nDear deer, here's a tear for you. \nThe bear shared a pear with a hare. \nThe pure cure for your allure.",
    },
];

const AudioTrainingStart = ({ step }) => {
    const { avatarId } = useParams();

    const navigate = useNavigate();
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const { avatarTrainingStep, setAvatarTrainingStep } = useContext(AppContext);
    const [isUploading, setIsUploading] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const mediaStreamRef = useRef(null);


    useEffect(() => {
        if (step !== undefined) {
            setAvatarTrainingStep(step);
        }
    }, [step]);

    const handleRecordClick = () => {
        setAudioBlob(null);
        setIsRecording(true);
        audioChunksRef.current = [];

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaStreamRef.current = stream;

                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;

                mediaRecorder.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    setAudioBlob(audioBlob);
                };

                mediaRecorder.start();
            })
            .catch(error => console.error("Error accessing microphone:", error));
    };

    const handleStopClick = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }

        setIsRecording(false);
    };


    const handleContinueClick = async () => {
        setIsUploading(true);
        const current_step = {
            'step': avatarTrainingStep + 1
        }
        const file = new File([audioBlob], 'recordedAudio.mp3', { type: 'audio/mp3' });
        const response = await sendFiles(`/avatar/private/upload/${avatarId}?data_type=audio`, file, current_step);
        if (response) {
            if (avatarTrainingStep < arrayOfParas.length - 1) {
                setAvatarTrainingStep(avatarTrainingStep + 1);
                setAudioBlob(null);
            } else {
                navigate(`/avatars/create/video-training/instructions/${avatarId}`);
                setAudioBlob(null);
            }
        } else {
            console.log("Error in uploading audio data");
        }
        setIsUploading(false);
    };

    const handleBackClick = () => {
        if (avatarTrainingStep > 0) {
            setAvatarTrainingStep(avatarTrainingStep - 1);
            setAudioBlob(null);
        } else {
            console.log('back fail check');
        }
    };

    // const currentSentence = arrayOfParas[avatarTrainingStep];
    let currentSentence;

    if (avatarTrainingStep <= 9) {
        currentSentence = arrayOfParas[avatarTrainingStep];
    } else {
        currentSentence = arrayOfParas[avatarTrainingStep - 1];
    }



    return (
        <ScrollArea>
            {isUploading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div className='flex flex-col gap-5 2xl:gap-14 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-8 2xl:py-10 my-auto 2xl:my-28 rounded-2xl items-center'>
                <h2 className='text-2xl text-center font-extrabold text-gray-800'>Audio Training</h2>
                <Progress value={(avatarTrainingStep / (arrayOfParas.length - 1)) * 100} className="w-[60%] h-[13px]" />
                <div className='flex flex-col gap-5'>
                    <p className="text-[#868686] text-center">Press the record button and read the following sentences</p>
                    <div className='bg-[#EFEAFF] rounded-3xl p-10'>
                        <p className='text-center text-[#ADADAD] text-xl leading-8'>{currentSentence.value}</p>
                    </div>
                    <p className="text-[#868686] text-center italic">Don’t worry if you mispronounce any of the words, just read the correct words and keep going.</p>
                </div>
                <div>
                    {isRecording ? (
                        <div className='flex gap-2'>
                            {avatarTrainingStep !== 0 && (
                                <Atom.Button type="icon" className="p-3" disabled>
                                    <MoveLeft className='h-6 w-5' />
                                </Atom.Button>
                            )}
                            <Atom.Button
                                type="icon"
                                onClick={handleStopClick}
                                className="p-3 bg-[#FEE2E2] hover:bg-[#FEE2E2]/80 border-none text-gray-800"
                            >
                                <Square fill="#EF4444" strokeWidth={0} className='h-6 w-5 font-semibold' />
                            </Atom.Button>
                            <Atom.Button type="icon" className="p-3" disabled>
                                <MoveRight className='h-6 w-5' />
                            </Atom.Button>
                        </div>
                    ) : (
                        <div className='flex gap-2'>
                            {avatarTrainingStep !== 0 && (
                                <Atom.Button
                                    type="icon"
                                    onClick={handleBackClick}
                                    className="p-3"
                                    disabled={audioBlob === null}
                                >
                                    <MoveLeft className='h-6 w-5' />
                                </Atom.Button>
                            )}
                            <Atom.Button
                                type="icon"
                                onClick={handleRecordClick}
                                className="p-3"
                            >
                                <Mic className='h-6 w-5' />
                            </Atom.Button>
                            <Atom.Button
                                type="icon"
                                onClick={handleContinueClick}
                                className="p-3"
                                disabled={audioBlob === null}
                            >
                                <MoveRight className='h-6 w-5' />
                            </Atom.Button>
                        </div>
                    )}
                </div>
                <div>
                    {audioBlob && (
                        <audio controls style={{ display: "inline-block" }}>
                            <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                </div>
            </div>
        </ScrollArea>
    )
}

export default AudioTrainingStart;
