import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "components/ui/card";
import { CheckCircle2 } from "lucide-react";
import { Button } from "components/ui/button";
import React, { useState } from "react";
import { cn } from "lib/utils";
import Atom from "common/components/atoms";
import { ScrollArea } from "components/ui/scroll-area";

const PricingHeader = ({ title, subtitle }) => (
    <section className="text-center">
        <h2 className="text-3xl font-bold">{title}</h2>
        <p className="text-xl pt-1">{subtitle}</p>
        <br />
    </section>
);

// const PricingSwitch = ({ onSwitch }) => (
//     <Tabs defaultValue="0" className="w-[20rem]  mx-auto" onValueChange={onSwitch}>
//         <TabsList className="py-6 px-2 flex gap-2 bg-transparent">
//             <TabsTrigger value="0" className="text-base w-28">
//                 Monthly
//             </TabsTrigger>
//             <TabsTrigger value="1" className="text-base w-28">
//                 Yearly
//             </TabsTrigger>
//         </TabsList>
//     </Tabs>
// );

const PricingCard = ({ isYearly, title, monthlyPrice, yearlyPrice, description, features, actionLabel, popular, exclusive, paymentLink }) => (
    <Card
        className={cn(`w-72 rounded-2xl shadow-md flex flex-col justify-between py-1 ${popular ? "border-rose-400" : "border-zinc-700"} mx-auto sm:mx-0`, {
            "animate-background-shine bg-[linear-gradient(110deg,#e8e1fc,40%,#e0d7fc,55%,#d3cce8)] bg-[length:200%_100%] transition-colors":
                // "animate-background-shine bg-white dark:bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] transition-colors":
                exclusive,
        })}>
        <div>
            <CardHeader className="pb-8 pt-4">
                {isYearly && yearlyPrice && monthlyPrice ? (
                    <div className="flex justify-between">
                        <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg">{title}</CardTitle>
                        <div
                            className={cn("px-2.5 rounded-xl h-fit text-sm py-1 bg-zinc-200 text-black", {
                                "bg-gradient-to-r from-orange-400 to-rose-400": popular,
                            })}>
                            Save ${monthlyPrice * 12 - yearlyPrice}
                        </div>
                    </div>
                ) : (
                    <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg">{title}</CardTitle>
                )}
                <div className="flex gap-0.5">
                    <h3 className="text-3xl font-bold">{yearlyPrice && isYearly ? "$" + yearlyPrice : monthlyPrice ? "$" + monthlyPrice : "Custom"}</h3>
                    <span className="flex flex-col justify-end text-sm mb-1">{yearlyPrice && isYearly ? "/year" : monthlyPrice ? "/month" : null}</span>
                </div>
                <CardDescription className="pt-1.5 h-12">{description}</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col gap-2">
                {features.map((feature) => (
                    <CheckItem key={feature} text={feature} />
                ))}
            </CardContent>
        </div>
        <CardFooter className="mt-2">
            <Atom.Button
                type="primary"
                className="relative inline-flex w-[90%] mx-auto items-center justify-center rounded-3xl px-6 font-medium transition-all duration-300 focus:outline-none focus:scale-105"
                onClick={() => window.location.href = paymentLink}
            >
                <div
                // className="absolute -inset-0.5 -z-10 rounded-lg bg-gradient-to-b from-[#c7d2fe] to-[#8678f9] opacity-75 blur" 
                />
                {actionLabel}
            </Atom.Button>
        </CardFooter>
    </Card>
);

const CheckItem = ({ text }) => (
    <div className="flex gap-2">
        <CheckCircle2 size={18} className="my-auto text-green-400" />
        <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">{text}</p>
    </div>
);

const CheckoutForm = () => {
    const [isYearly, setIsYearly] = useState(false);
    // const togglePricingPeriod = (value) => setIsYearly(parseInt(value) === 1);

    const plans = [
        {
            title: "Starter",
            monthlyPrice: 20,
            yearlyPrice: 200,
            description: "Essential features you need to get started",
            features: ["Example Feature Number 1", "Example Feature Number 2", "Example Feature Number 3"],
            actionLabel: "Buy",
            popular: true,
            paymentLink: "https://buy.stripe.com/test_fZe7tifi4dQBatifYZ",

        },
        {
            title: "Business",
            monthlyPrice: 220,
            yearlyPrice: 2200,
            description: "Perfect for owners of small & medium businesses",
            features: ["Example Feature Number 1", "Example Feature Number 2", "Example Feature Number 3"],
            actionLabel: "Buy",
            popular: true,
            paymentLink: "https://buy.stripe.com/test_00g3d25HueUFdFu002",
        },
        {
            title: "Scale",
            monthlyPrice: 400,
            yearlyPrice: 4000,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["Example Feature Number 1", "Example Feature Number 2", "Example Feature Number 3", "Super Exclusive Feature"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_eVa28Y6Ly13P0SIcMM",
            exclusive: true,
        },
        {
            title: "Growth",
            monthlyPrice: 60,
            yearlyPrice: 600,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["Example Feature Number 1", "Example Feature Number 2", "Example Feature Number 3", "Super Exclusive Feature"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_28oaFuc5SeUF30QcMQ",
        },
        {
            title: "Pro",
            price: "Custom",
            monthlyPrice: 100,
            yearlyPrice: 1000,
            description: "Dedicated support and infrastructure to fit your needs",
            features: ["Example Feature Number 1", "Example Feature Number 2", "Example Feature Number 3", "Super Exclusive Feature"],
            actionLabel: "Buy",
            paymentLink: "https://buy.stripe.com/test_14k5lac5SdQB44U147",
        },
    ];
    return (
        <ScrollArea className="h-full w-full">
            <div className="py-8 2xl:py-16">
                <PricingHeader title="Pricing Plans" subtitle="Choose the plan that's right for you" />
                {/* <PricingSwitch onSwitch={togglePricingPeriod} /> */}
                <section className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-8 mt-8">
                    {plans.map((plan) => {
                        return <PricingCard key={plan.title} {...plan} isYearly={isYearly} />;
                    })}
                </section>
            </div>
        </ScrollArea>
    );
}

export default CheckoutForm;
