import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Textarea } from "components/ui/textarea";
import Atom from "common/components/atoms";
import {
   Form,
   FormControl,
   FormField,
   FormItem,
   FormLabel,
   FormMessage,
} from "components/ui/form";
import { ScrollArea } from 'components/ui/scroll-area';
import { fetchData } from 'apis/APIWrapper';
import AppContext from 'common/context/AppContext';
import { ThreeCircles } from 'react-loader-spinner';


const FormSchema = Yup.object().shape({
   businessDescription: Yup.string()
      .required('Please describe how your business does'),
   productServiceHelp: Yup.string()
      .required('Please describe how your product/service helps your audience'),
   callToAction: Yup.string()
      .required('Please provide a call to action for your audience'),
});

const ScriptSchema = Yup.object().shape({
   script: Yup.string()
      .required('Please enter your script'),
});


const PrepareScript = () => {
   const [isFormVisible, setIsFormVisible] = useState(true);
   const [generatedScript, setGeneratedScript] = useState('');
   const { campaignId, step } = useParams();
   const navigate = useNavigate();
   const { setScript } = useContext(AppContext)
   const [selectedAvatar, setSelectedAvatar] = useState("Public");
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      fetchAvatarType();
   }, [])

   const fetchAvatarType = async () => {
      setIsLoading(true);
      const response = await fetchData(`/campaigns/avatar/${campaignId}`)
      if (response) {
         setSelectedAvatar(response);
      }
      setIsLoading(false);
   }

   const form = useForm({
      resolver: yupResolver(FormSchema),
   });

   const scriptForm = useForm({
      resolver: yupResolver(ScriptSchema),
   });


   const handleContinue = async (data) => {
      setIsLoading(true);

      if (!data) {
         setIsLoading(false);
         return;
      }

      try {
         const payload = {
            "script": data['script'],
            "avatar_id": selectedAvatar.id,
         };

         setScript(data);

         let response;

         if (selectedAvatar.type === "Public") {
            response = await fetchData(`/campaigns/script/${campaignId}/${parseInt(step) + 3}`, 'POST', payload);

            if (response) {
               navigate(`/campaigns/create/variables/${campaignId}/${parseInt(step) + 3}`);
            }
         } else {
            response = await fetchData(`/campaigns/script/${campaignId}/${parseInt(step) + 1}`, 'POST', payload);

            if (response) {
               navigate(`/campaigns/create/instructions/${campaignId}/${parseInt(step) + 1}`);
            }
         }
      } catch (error) {
         console.error('Error during handleContinue:', error);
      } finally {
         setIsLoading(false);
      }
   };


   const handleGenerateScript = (data) => {
      console.log(data);
      setGeneratedScript(data);
      setIsFormVisible(false);
   }

   return (
      <>
         {isLoading ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
               <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#e6a6e0"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
               />
            </div>

         ) :
            <ScrollArea className="h-full">
               <div className='flex flex-col justify-center gap-6 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-10 my-auto lg:my-6 2xl:my-28 bg-[#f8f8f8] rounded-2xl'>
                  <h2 className='text-2xl font-bold text-center'>Prepare video script</h2>
                  <Tabs defaultValue="type" className="">
                     <TabsList className="flex gap-3 w-full">
                        <TabsTrigger value="type">Type your script</TabsTrigger>
                        <TabsTrigger value="ai">Generate with AI</TabsTrigger>
                     </TabsList>
                     <TabsContent value="type">
                        <Form {...scriptForm}>
                           <form onSubmit={scriptForm.handleSubmit(handleContinue)} className='flex flex-col gap-6 w-full'>
                              <FormField
                                 control={scriptForm.control}
                                 name="script"
                                 render={({ field }) => (
                                    <FormItem>
                                       <FormLabel>Enter your script</FormLabel>
                                       <FormControl>
                                          <Textarea
                                             placeholder="Hi Name, Personiq is a personalized video outreach platform that allows you to clone hundreds or thousands of videos from just one single video. We can really help company, scale its sales efforts. Do you have a few mins this week to get on a quick call?"
                                             className="py-4 px-6 min-h-[30vh] resize-none"
                                             {...field}
                                          />
                                       </FormControl>
                                       <FormMessage />
                                    </FormItem>
                                 )}
                              />
                              <Atom.Button
                                 type="primary"
                                 className="mx-auto min-w-[14%]"
                              >Continue
                              </Atom.Button>
                           </form>
                        </Form>
                     </TabsContent>
                     <TabsContent value="ai">
                        {isFormVisible ? (

                           <Form {...form}>
                              <form onSubmit={form.handleSubmit(handleGenerateScript)} className="flex flex-col gap-6 w-full">
                                 <FormField
                                    control={form.control}
                                    name="businessDescription"
                                    render={({ field }) => (
                                       <FormItem>
                                          <FormLabel>How does your business do?</FormLabel>
                                          <FormControl>
                                             <Textarea
                                                placeholder="E.g: Personiq is a personalized video outreach platform that allows you to clone hundreds or thousands of videos from just one single video. "
                                                className="py-4 px-6 resize-none"
                                                {...field}
                                             />
                                          </FormControl>
                                          <FormMessage />
                                       </FormItem>
                                    )}
                                 />
                                 <FormField
                                    control={form.control}
                                    name="productServiceHelp"
                                    render={({ field }) => (
                                       <FormItem>
                                          <FormLabel>How does your product/service/company help your audience?</FormLabel>
                                          <FormControl>
                                             <Textarea
                                                placeholder="E.g: We can really help you scale your sales outreach efforts"
                                                className="py-4 px-6 resize-none"
                                                {...field}
                                             />
                                          </FormControl>
                                          <FormMessage />
                                       </FormItem>
                                    )}
                                 />
                                 <FormField
                                    control={form.control}
                                    name="callToAction"
                                    render={({ field }) => (
                                       <FormItem>
                                          <FormLabel>What is the call to action for your audience?</FormLabel>
                                          <FormControl>
                                             <Textarea
                                                placeholder="E.g: Book a meeting"
                                                className="py-4 px-6 resize-none"
                                                {...field}
                                             />
                                          </FormControl>
                                          <FormMessage />
                                       </FormItem>
                                    )}
                                 />
                                 <Atom.Button type="primary" className="mx-auto w-[18%]">Generate Script</Atom.Button>
                              </form>
                           </Form>
                        ) : (
                           <div className="flex flex-col gap-6 w-full">
                              <Textarea
                                 placeholder="Generated script will appear here..."
                                 value={generatedScript}
                                 className="py-4 px-6 min-h-[30vh] resize-none"
                              />
                              <div className='flex gap-3 justify-center'>
                                 <Atom.Button
                                    type="secondary"
                                    onClick={() => setIsFormVisible(true)}
                                 >
                                    Go back to form
                                 </Atom.Button>
                                 <Atom.Button
                                    type="primary"
                                    className="min-w-[14%]"
                                    onClick={handleContinue}
                                 >Continue
                                 </Atom.Button>
                              </div>
                           </div>
                        )}
                     </TabsContent>
                  </Tabs>
               </div>
            </ScrollArea>
         }
      </>
   )
}

export default PrepareScript;