import React from 'react'
import AvatarOrganism from '../../common/components/organisms/avatars';
import Template from '../../common/components/templates';


const AudioTrainingInstructionsPage = () => {
    return (
        <Template.AvatarSetup>
            <AvatarOrganism.AudioTrainingInstructions />
        </Template.AvatarSetup>
    )
}

export default AudioTrainingInstructionsPage;