import React, { useState } from 'react'

import { Upload, CircleCheck, CircleAlert, Ellipsis } from 'lucide-react';


const useFileUpload = ({
    label = "label",
    description = "Drag & Drop or Browse",
    onChange,
    onDrop,
    acceptType
}) => {
    const [labelText, setLabelText] = useState(label);
    const [status, setStatus] = useState("default");
    const [descriptionText, setDescriptionText] = useState(description); //should be description that comes through prop instead of '' 
    const [showAlert, setShowAlert] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);

    const stopDefaults = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            setIsMouseOver(false);
        },
        onDragEnter: (e) => {
            stopDefaults(e);
            setIsDragOver(true);
        },
        onDragLeave: (e) => {
            stopDefaults(e);
            setIsDragOver(false);
        },
        onDragOver: stopDefaults,
        onDrop: (e) => {
            stopDefaults(e);
            setIsDragOver(false);
            setShowAlert(false);
            if (e.dataTransfer.files.length > 0) {
                const droppedFile = e.dataTransfer.files[0];

                if (acceptType.includes(droppedFile.type)) {
                    setDescriptionText(droppedFile.name);
                    setStatus("uploading");

                    try {
                        onDrop(e);
                        setTimeout(() => setStatus("uploaded"), 2000);
                    } catch (error) {
                        setStatus("error");
                    }
                } else {
                    setShowAlert(true);
                    setStatus("error");
                }
            }
        }
    };

    const handleChange = (event) => {
        // console.log(event.target.files);
        try {
            setDescriptionText(event.target?.files[0]?.name);
            setStatus("uploading");
            onChange(event);
            setTimeout(() => setStatus("uploaded"), 1000);
        } catch (error) {
            setStatus("error");
        }
    };

    return { status, labelText, descriptionText, dragEvents, handleChange, showAlert };
};


export default function UploadButtons(props) {
    const { status, labelText, descriptionText, dragEvents, handleChange, showAlert } =
        useFileUpload(props);
        const inputId = `contained-button-file-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <div
            style={{
                border: `1px ${props.border} #D5D5D5`,
                backgroundColor: "#EFEAFF",
                width: props.width || "100%",
                height: props.height || "60px",
                borderRadius: props.borderRadius || "1px",
                ": hover": {
                    border: `1px ${props.border} blue`,
                },
                justifyContent: "center",
                display: "flex",
                overflow: 'hidden'
            }}
            className='text-xs text-ellipsis lg:text-sm'
        //     className={`bg-[#EFEAFF] flex justify-center 
        //     ${props.width ? `w-[${props.width}]` : 'w-full'}
        //     ${props.height ? `h-[${props.height}]` : 'h-[60px]'}
        //     ${props.borderRadius ? `rounded-[${props.borderRadius}]` : 'rounded-[1px]'}
        //     border ${props.border ? `border-[${props.border}]` : ''} border-[#D5D5D5]
        //     hover:${props.border ? `border-[${props.border}]` : ''} hover:border-blue-500
        //   `}
        >
            <label htmlFor={inputId} {...dragEvents}>
                <input
                    onChange={handleChange}
                    accept={props.acceptType}
                    id={inputId}
                    type="file"
                    className="opacity-0 absolute cursor-pointer"
                />
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            my: "auto",
                            mx: "7px",
                            width: 40,
                            minWidth: 40,
                            " .MuiSvgIconRoot": {
                                fontSize: "2.25rem",
                            },
                        }}
                    >
                        {status === "default" ? (
                            props?.icons?.defaultIcon ?? <Upload className='w-4 lg:w-6'/>
                        ) : status === "uploaded" ? (
                            props?.icons?.successIcon ?? <Upload />
                        ) : status === "error" ? (
                            props?.icons?.errorIcon ?? <Upload />
                        ) : status === "uploading" ? (
                            <Upload size={32} />
                        ) : null}
                    </div>

                    <div
                        style={{
                            minWidth: 0,
                            display: "flex",
                            flexGrow: 1,
                        }}
                    >
                        <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis">
                            {descriptionText}
                        </div>

                    </div>

                    {status === "uploaded" && (
                        // <Stack direction="row" spacing={1.5} style={{ mr: 1.5 }}>
                        //     {props?.action?.map((action, index) => {
                        //         const { icon, value, color, onClick = () => { } } = action;
                        //         return (
                        //             <div key={`${value}-${index}`}>
                        //                 <Tooltip title={value}>
                        //                     <IconButton
                        //                         disableRipple={true}
                        //                         disableFocusRipple={true}
                        //                         style={{
                        //                             width: 15,
                        //                             minWidth: 15,
                        //                             color: color,
                        //                         }}
                        //                         onClick={() => onClick()}
                        //                     >
                        //                         {icon}
                        //                     </IconButton>
                        //                 </Tooltip>
                        //             </div>
                        //         );
                        //     })}
                        // </Stack>
                        <div className="flex space-x-1.5 mr-4">
                            {props?.action?.map((action, index) => {
                                const { icon, value, color, onClick = () => { } } = action;
                                return (
                                    <div key={`${value}-${index}`} className="flex items-center">
                                        {/* <Tooltip title={value}>
                                            <button
                                                className={`w-4 min-w-4 ${color}`}
                                                onClick={() => onClick()}
                                            >
                                                {icon}
                                            </button>
                                        </Tooltip> */}
                                    </div>
                                );
                            })}
                        </div>

                    )}
                </div>
            </label>
        </div>
    )
}
