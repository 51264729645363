import React from 'react';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';

const RecordVideoPage = () => {
    return (
            <Template.CampaignSetup>
                <CampaignsOrganism.RecordVideo />
            </Template.CampaignSetup>
    )
}

export default RecordVideoPage;