import React, { useState, useRef, useEffect } from "react";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../components/ui/tooltip"; // Adjust the import path as necessary
import {
    Command,
    CommandGroup,
    CommandList,
} from '../../../components/ui/command';
import { Textarea } from "../../../components/ui/textarea";

const TextAreaWithSuggestion = (props) => {
    const [inputText, setInputText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const inputRef = useRef(null);
    const currentCharacterCount = inputText.length;

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const getUniqueTitles = (variables) => {
        const uniqueTitlesSet = new Set();
        const uniqueTitlesArray = variables.reduce((result, variable) => {
            if (!uniqueTitlesSet.has(variable.value)) {
                uniqueTitlesSet.add(variable.value);
                result.push(variable.value);
            }
            return result;
        }, []);

        return uniqueTitlesArray;
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= props.totalWords) {
            setInputText(value);
            const inputWords = value
                .split(/\s+/)
                .filter((word) => word.trim() !== "");
            let lastWord = inputWords[inputWords.length - 1];

            if (lastWord === undefined) lastWord = "";
            const fetchedSuggestions = getUniqueTitles(props.list);
            const filteredSuggestions = fetchedSuggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(lastWord.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            if (filteredSuggestions.length > 0 && lastWord.trim() !== "") {
                setTooltipOpen(true);
            } else {
                setTooltipOpen(false);
            }
            props?.triggerTextChange(e);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const inputWords = inputText
            .split(/\s+/)
            .filter((word) => word.trim() !== "");
        inputWords[inputWords.length - 1] = suggestion;
        const updatedText = inputWords.join(" ");
        setInputText(updatedText);
        setTooltipOpen(false);

        if (inputRef.current) {
            inputRef.current.focus();
        }
        props?.triggerTextChange({ target: { value: updatedText } });
    };

    return (
        <TooltipProvider>
            <Tooltip open={tooltipOpen}>
                <TooltipTrigger asChild>
                    <Textarea
                        value={inputText}
                        onChange={handleInputChange}
                        placeholder={props.text}
                        ref={inputRef}
                        className="py-3 px-6 resize-none"
                    />
                </TooltipTrigger>
                <TooltipContent className="p-0">
                    <Command>
                        <CommandList>
                            <CommandGroup>
                                {suggestions.map((suggestion, index) => (
                                    <button
                                        key={index}
                                        value={suggestion}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        className={`w-full rounded-md py-2 px-4 text-left block whitespace-no-wrap text-sm focus:outline-none bg-gray-200 text-gray-500 hover:bg-violet-100 hover:text-violet-600 ${index !== suggestions.length - 1 ? 'mb-1' : ''}`}
                                    >
                                        {suggestion}
                                    </button>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </TooltipContent>
            </Tooltip>
            <p
                className="mt-[2%] text-xs ml-auto"
            >
                Characters: {currentCharacterCount || 0} / {props.totalWords}
            </p>
        </TooltipProvider>
    );
};

export default TextAreaWithSuggestion;
