import React, { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import CampaignsOrganism from '.';
import Atom from '../../atoms';
import {
   Tooltip,
   TooltipContent,
   TooltipProvider,
   TooltipTrigger,
} from "../../../../components/ui/tooltip";
import AppContext from '../../../context/AppContext';
import { fetchData } from "../../../../apis/APIWrapper"
import { useToast } from "../../../../components/ui/use-toast"
import { ToastAction } from "../../../../components/ui/toast"
import { ThreeCircles } from 'react-loader-spinner';
import { ScrollArea } from 'components/ui/scroll-area';


const VariablesAssign = () => {
   const { toast } = useToast()
   const { setThumbnailData, videoUrl, setVideoUrl, variables, setVariables, setSelectedVariables, transcription, setTranscription } = useContext(AppContext);
   const videoRef = useRef(null);
   const [highlightedWordIndex, setHighlightedWordIndex] = useState(-1);
   const [selectedWordIndices, setSelectedWordIndices] = useState([]);
   const [showDropdown, setShowDropdown] = useState(false);
   const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
   const [assignedVariable, setAssignedVariable] = useState("");
   const [assignedVariables, setAssignedVariables] = useState({});
   const [hasConfirmed, setHasConfirmed] = useState(false); // State to track if the user has confirmed the warning
   const { campaignId, step } = useParams()
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);

   const words = transcription.split(" ");

   const handleWordClick = (index, event) => {
      setHighlightedWordIndex(index);
      setShowDropdown(true);
      setDropdownPosition({ x: event.pageX, y: event.pageY });
   };

   const updateAssignedVariables = (index, variable) => {
      setAssignedVariables(prevState => ({
         ...prevState,
         [index]: variable
      }));
   };

   const handleDropdownSelect = (option) => {
      setSelectedWordIndices((prevIndices) => [
         ...prevIndices,
         highlightedWordIndex,
      ]);
      setShowDropdown(false);
   };

   const handleDropdownClose = () => {
      setHighlightedWordIndex(-1);
      setShowDropdown(false);
   };

   const removeVariableByTitle = (index) => {
      setAssignedVariables((prevAssignedVariables) => {
         const updatedVariables = { ...prevAssignedVariables };
         delete updatedVariables[index];
         return updatedVariables;
      });
      setShowDropdown(false);
   };

   useEffect(() => {
      const assignedVariableIndices = Object.keys(assignedVariables);
      setSelectedWordIndices(prevSelectedWordIndices => {
         return prevSelectedWordIndices.filter(index => assignedVariableIndices.includes(index) || assignedVariables[index]);
      });
   }, [assignedVariables]);

   useEffect(() => {
      const fetchVideo = async () => {
         setIsLoading(true);
         try {
            const response = await fetchData(`/campaigns/landingPage/data/${campaignId}`);
            if (response) {
               console.log(response);
               setVideoUrl(response.video_url);
               setVariables(response.variables);
               setTranscription(response.script);
               setIsLoading(false);
            }
         } catch (error) {
            console.error('Error fetching data:', error);
         }
      };
      fetchVideo();
   }, [campaignId]);


   const handleContinue = async () => {
      const selectedVariablePairs = selectedWordIndices.map((index) => {
         return { index, variable: assignedVariables[index] };
      });

      const filteredVariablePairs = selectedVariablePairs.filter((pair) => pair.variable !== undefined);
      console.log(filteredVariablePairs); //index and variable as object key value pairs

      if (filteredVariablePairs.length === 0) {
         toast({
            duration: "4000",
            variant: "error",
            title: "No variables assigned!",
            description: "You need to assign variables to your script to proceed",
            action: <ToastAction altText="Try again">Try again</ToastAction>,
         });
         return;
      }

      // if (filteredVariablePairs.length !== variables.length && !hasConfirmed) {
      //    toast({
      //       duration: "5000",
      //       variant: "warning",
      //       title: "Incomplete variable assignment",
      //       description: "Not all variables are assigned. Do you still want to continue?",
      //       action: (
      //          <ToastAction
      //             altText="Continue"
      //             onClick={() => setHasConfirmed(true)}
      //          >
      //             Ignore it
      //          </ToastAction>
      //       ),
      //    });
      //    return;  // Prevent further execution until user confirms
      // }

      const response = await fetchData(`/campaigns/add/variables/${campaignId}/${parseInt(step) + 1}`, 'POST', filteredVariablePairs);
      if (response) {
         setSelectedVariables(filteredVariablePairs);
         navigate(`/campaigns/create/thumbnail/${campaignId}/${parseInt(step) + 1}`);
      }
   };


   return (
      <ScrollArea>
         {isLoading && (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 " style={{ zIndex: 1050 }}>
               <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#e6a6e0"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
               />
            </div>
         )}

         <div className='flex flex-col justify-center gap-10 mx-auto lg:w-[80%] 2xl:w-[80%] px-16 py-10 my-[2%] md:my-[4%] xl:my-[2%] 2xl:my-[6%] bg-[#f8f8f8] rounded-2xl'>
            <h2 className='text-2xl font-bold text-center'>Assign Variables</h2>
            <p className='text-sm text-gray-500 italic text-center'>Click on any word in the script to open a dropdown and select a variable to assign</p>
            <div className='grid grid-cols-2 gap-10 items-center'>
               <div className="relative rounded-3xl overflow-hidden w-full after:content-[''] after:block after:pt-[56.25%]">
                  <video
                     autoPlay
                     loop
                     controls
                     className="absolute top-0 left-0 w-full h-full"
                     src={videoUrl}
                     ref={videoRef}
                  />
               </div>

               <div>
                  <p>
                     {words?.map((word, index) => (
                        <TooltipProvider>
                           <Tooltip>
                              <TooltipTrigger asChild>
                                 <span
                                    key={index}
                                    onClick={(event) => handleWordClick(index, event)}
                                    style={{
                                       backgroundColor:
                                          highlightedWordIndex === index ||
                                             selectedWordIndices.includes(index) // Check if the word is selected
                                             ? "#CFC5EF"
                                             : "transparent",
                                       cursor: "pointer",
                                       borderRadius: "1rem",
                                       padding: "0.1rem 0.2rem",
                                    }}
                                 >
                                    {word}{" "}
                                 </span>
                              </TooltipTrigger>
                              {assignedVariables[index] && (
                                 <TooltipContent>
                                    <p>{assignedVariables[index]}</p>
                                 </TooltipContent>
                              )}
                           </Tooltip>
                        </TooltipProvider>
                     ))}
                  </p>
               </div>
               {showDropdown && (
                  <>
                     <CampaignsOrganism.VariableDropdown
                        x={dropdownPosition.x}
                        y={dropdownPosition.y}
                        dropdownSelect={handleDropdownSelect}
                        handleClose={handleDropdownClose}
                        vars={variables}
                        setVars={setVariables}
                        highlightedWordIndex={highlightedWordIndex}
                        setHighlightedWordIndex={setHighlightedWordIndex}
                        removeVars={removeVariableByTitle}
                        assignedVariable={assignedVariable}
                        setAssignedVariable={setAssignedVariable}
                        assignedVariables={assignedVariables}
                        updateAssignedVariables={updateAssignedVariables}
                     />
                  </>
               )}
            </div>
            <Atom.Button type="primary" className="mx-auto min-w-[15%]" onClick={handleContinue}
            >Continue</Atom.Button>
         </div>

      </ScrollArea>
   )
}

export default VariablesAssign