import CampaignsPage from "../components/pages/CampaignsPage";
import CampaignSetupPage from "../components/pages/CampaignSetupPage";
import ChooseAvatarPage from "../components/pages/ChooseAvatarPage"
import PrepareScriptPage from "../components/pages/PrepareScriptPage";
import VideoCampaignInstructionsPage from "../components/pages/VideoCampaignInstructionsPage";
import RecordVideoPage from "../components/pages/RecordVideoPage";
import VariablesAssignPage from "../components/pages/VariablesAssignPage";
import CustomizeThumbnailPage from "../components/pages/CustomizeThumbnailPage";
import CustomizeLandingPage from "../components/pages/CustomizeLandingPage";
import CampaignSummaryPage from "../components/pages/CampaignSummaryPage";
import AvatarsPage from "../components/pages/AvatarsPage";
import AudioTrainingInstructionsPage from "../components/pages/AudioTrainingInstructionsPage";
import AudioTrainingStartPage from "../components/pages/AudioTrainingStartPage";
import VideoTrainingInstructionsPage from "../components/pages/VideoTrainingInstructionsPage";
import VideoTrainingStartPage from "../components/pages/VideoTrainingStartPage";
import LibraryPage from "../components/pages/LibraryPage";
import LoginPage from "../components/pages/LoginPage";
import SignUpPage from "../components/pages/SignUpPage";
import LandingPage from "components/pages/LandingPage";
import CheckoutFormPage from "components/pages/CheckoutFormPage";
import AfterPaymentPage from "components/pages/AfterPaymentPage";
import notFoundPage from "components/pages/notFoundPage";

// Admin panel pages
import PublicAvatarsPage from "components/pages/adminPanelPages/PublicAvatarsPage";
import IntegrationsPage from "components/pages/adminPanelPages/IntegrationsPage";
import CustomersPage from "components/pages/adminPanelPages/CustomersPage";
import CustomerDetailsPage from "components/pages/adminPanelPages/CustomerDetailsPage";
import CampaignDetailsPage from "components/pages/adminPanelPages/CampaignDetailsPage";



export const CAMPAIGNS_PATH = "/campaigns";
export const CAMPAIGN_SETUP_PATH = "/campaigns/create/:campaignId/:step";
export const CHOOSE_AVATAR_PAGE = "/campaigns/create/avatar/:campaignId/:step";
export const PREPARE_SCRIPT_PAGE = "/campaigns/create/script/:campaignId/:step";
export const CAMPAIGN_VIDEO_INSTRUCTIONS_PAGE = "/campaigns/create/instructions/:campaignId/:step";
export const RECORD_VIDEO_PAGE = "/campaigns/create/video/:campaignId/:step";
export const VARIABLES_ASSIGN_PAGE = "/campaigns/create/variables/:campaignId/:step";
export const ADD_THUMBNAIL_PAGE = "/campaigns/create/thumbnail/:campaignId/:step";
export const CUSTOMIZE_LANDING_PAGE = "/campaigns/create/landing-page/:campaignId/:step";
export const CAMPAIGN_SUMMARY_PAGE = "/campaigns/summary/:campaignId";
export const AVATAR_PATH = "/avatars";
export const AUDIO_TRAINING_PAGE = "/avatars/create/audio-training/instructions/:avatarId";
export const AUDIO_TRAINING_START_PAGE = "/avatars/create/audio-training/start/:avatarId";
export const VIDEO_TRAINING_INSTRUCTIONS_PAGE = "/avatars/create/video-training/instructions/:avatarId";
export const VIDEO_TRAINING_START_PAGE = "/avatars/create/video-training/start/:avatarId";
export const LIBRARY_PATH = "/library";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signUp";
export const LANDING_PAGE_PATH = "/landingPage/:landingPageId";
export const CHECKOUTPAGE_PATH = "/plans";
export const AFTERPAYMENT_PAGE_PATH = "/payment-status";
export const NOT_FOUND_PAGE_PATH = "/not-found"


// Admin Panel Paths
export const PUBLIC_AVATARS_PAGE_PATH = "/admin/public-avatars";
export const INTEGRATIONS_PAGE_PATH = "/admin/integrations";
export const CUSTOMERS_PAGE_PATH = "/admin/customers";
export const CUSTOMER_DETAILS_PAGE_PATH = "/admin/customers/details/:customerId";
export const CAMPAIGN_DETAILS_PAGE_PATH = "/admin/customers/details/:customerId/campaign";



export const AUTH_ROUTES = [
  {
    path: LOGIN_PATH,
    component: LoginPage,
  },
  {
    path: SIGNUP_PATH,
    component: SignUpPage,
  },
];

export const MAIN_ROUTES = [
  {
    path: CAMPAIGNS_PATH,
    component: CampaignsPage,
  },
  {
    path: CAMPAIGN_SETUP_PATH,
    component: CampaignSetupPage,
  },
  {
    path: CHOOSE_AVATAR_PAGE,
    component: ChooseAvatarPage,
  },
  {
    path: PREPARE_SCRIPT_PAGE,
    component: PrepareScriptPage,
  },
  {
    path: CAMPAIGN_VIDEO_INSTRUCTIONS_PAGE,
    component: VideoCampaignInstructionsPage,
  },
  {
    path: RECORD_VIDEO_PAGE,
    component: RecordVideoPage,
  },
  {
    path: VARIABLES_ASSIGN_PAGE,
    component: VariablesAssignPage,
  },
  {
    path: ADD_THUMBNAIL_PAGE,
    component: CustomizeThumbnailPage,
  },
  {
    path: CUSTOMIZE_LANDING_PAGE,
    component: CustomizeLandingPage,
  },
  {
    path: CAMPAIGN_SUMMARY_PAGE,
    component: CampaignSummaryPage,
  },
  {
    path: AVATAR_PATH,
    component: AvatarsPage,
  },
  {
    path: AUDIO_TRAINING_PAGE,
    component: AudioTrainingInstructionsPage,
  },
  {
    path: AUDIO_TRAINING_START_PAGE,
    component: AudioTrainingStartPage,
  },
  {
    path: VIDEO_TRAINING_INSTRUCTIONS_PAGE,
    component: VideoTrainingInstructionsPage,
  },
  {
    path: VIDEO_TRAINING_START_PAGE,
    component: VideoTrainingStartPage,
  },
  {
    path: LIBRARY_PATH,
    component: LibraryPage,
  },
  {
    path: CHECKOUTPAGE_PATH,
    component: CheckoutFormPage,
  },
  // {
  //   path: LANDING_PAGE_1_PATH,
  //   component: LandingPage1,
  // },
  // {
  //   path: LANDING_PAGE_2_PATH,
  //   component: LandingPage2,
  // },
  // {
  //   path: ADMIN_PANEL_PAGE_PATH,
  //   component: AdminPanelPage,
  // },

];

export const LANDING_PAGE_ROUTES = [
  {
    path: LANDING_PAGE_PATH,
    component: LandingPage,
  },
  {
    path: AFTERPAYMENT_PAGE_PATH,
    component: AfterPaymentPage,
  },
  {
    path: NOT_FOUND_PAGE_PATH,
    component: notFoundPage,
  }
];

export const ADMIN_PANEL_ROUTES = [
  {
    path: PUBLIC_AVATARS_PAGE_PATH,
    component: PublicAvatarsPage,
  },
  {
    path: CUSTOMERS_PAGE_PATH,
    component: CustomersPage,
  },
  {
    path: CUSTOMER_DETAILS_PAGE_PATH,
    component: CustomerDetailsPage,
  },
  {
    path: CAMPAIGN_DETAILS_PAGE_PATH,
    component: CampaignDetailsPage,
  },
  {
    path: INTEGRATIONS_PAGE_PATH,
    component: IntegrationsPage,
  },
]

