import Organism from "../organisms";
import SignUpOrganism from "../organisms/SignUp";


const SignUp = ({
    initialValues,
    validationSchema,
    onSubmit,
    onCheckboxChange,
    isChecked,
    formError,

}) => {
    return (
        <div className="grid grid-cols-1 max-w-lg mx-auto h-full">
            <div className="md:my-auto rounded-2xl shadow-none md:shadow-2xl py-1 2xl:py-3">
                <Organism.Logo />
                <SignUpOrganism.SignUpForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    onCheckboxChange={onCheckboxChange}
                    isChecked={isChecked}
                    formError={formError}
                />
            </div>
        </div >
    )
}

export default SignUp;