import React from 'react';
import Organism from '../organisms';


const AvatarSetupTemplate = ({ children, avatarName }) => {

    return (
        <div className='flex flex-col h-full gap-1'>
            <Organism.Header title={avatarName} elements={[]} />
            <main className='flex flex-col h-full overflow-hidden'>{children}</main>
        </div>
    )
}

export default AvatarSetupTemplate;