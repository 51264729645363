import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useSignIn } from "@clerk/clerk-react";
import * as Yup from "yup";
import { setAuthHeaders } from '../../apis/axios';
import { setToSessionStorage } from '../../lib/storage';
import { fetchData } from "../../apis/APIWrapper";
import Template from '../../common/components/templates';
import AppContext from '../../common/context/AppContext';
import { isClerkAPIResponseError } from '@clerk/clerk-react';

import { resetAuthTokens } from "apis/axios";
import { clearSessionStorageCredentials } from 'lib/storage';
import { useClerk } from "@clerk/clerk-react";


const LoginPage = () => {
  const { currentUser } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { signIn, setActive } = useSignIn();
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");

  const { signOut } = useClerk();


  const LOGIN_FORM_INITIAL_VALUES = {
    email: "",
    password: "",
  };

  const LOGIN_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  // Doing this to log the user out when login screen appears 
  const handleLogout = async () => {
    resetAuthTokens();
    clearSessionStorageCredentials();
    await signOut();
  }

  useEffect(() => {
    handleLogout();
  }, []);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);

      const { email, password } = values;

      const response = await signIn.create({
        identifier: email,
        password: password,
      });

      if (response.status !== "complete") {
        throw new Error("Username/Password incorrect"); // Generic error message
      }

      // Handle successful login...
      if (response.status === "complete") {
        await setActive({ session: response.createdSessionId });
        let session_id = response.createdSessionId;
        setToSessionStorage("authEmail", email);
        setToSessionStorage("authToken", session_id);
        setAuthHeaders();
        const apiResponse = await fetchData("/user/signin");
        if (apiResponse.status === 200) {
          setToSessionStorage("name", apiResponse.name);
          setToSessionStorage("company", apiResponse.company);
          setIsLoading(false);
          if (currentUser.role === "Admin") {

            navigate("/admin/public-avatars")
          } else {
            navigate("/campaigns");
          }
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }

    } catch (error) {
      setFormError("You have entered an incorrect email or password. Please use the correct login credential and try again.");


      setIsLoading(false);
    } finally {
      console.log(formError);
      setSubmitting(false);
    }
  };


  return (
    <Template.Login
      initialValues={LOGIN_FORM_INITIAL_VALUES}
      validationSchema={LOGIN_FORM_VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      isPasswordVisible={isPasswordVisible}
      setIsPasswordVisible={setIsPasswordVisible}
      formError={formError}
    />
  );
};

export default LoginPage;