import React from "react";

const TextListWithRoundedBoxes = (props) => {
    const { textList } = props;

    const getUniqueTitles = (variables) => {
        const uniqueTitlesSet = new Set();
        const uniqueTitlesArray = variables.reduce((result, v) => {
            if (!uniqueTitlesSet.has(v.variable)) {
                uniqueTitlesSet.add(v.variable);
                result.push(v.variable);
            }
            return result;
        }, []);
        return uniqueTitlesArray;
    };

    return (
        <div className="flex flex-wrap gap-2">
            {getUniqueTitles(textList).map((text, index) => (
                <div key={index} className="rounded-xl bg-violet-100 text-violet-600 py-1 px-2 m-1 text-center inline-block">
                    <p className="text-xs">{text}</p>
                </div>
            ))}
        </div>
    );
};

export default TextListWithRoundedBoxes;
