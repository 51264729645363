import Atom from '../../atoms';
import { CreateCampaign } from '../../../assets/images';

const CampaignsDefault = ({ handleOpenDialog }) => {
    return (
        <div className='flex flex-1 flex-col gap-8 p-4 items-center justify-center w-full'>
            <div>
                <img
                    src={CreateCampaign}
                    alt="Create Campaign"
                />
            </div>
            <div>
                <h2 className='text-sm md:text-xl font-bold'>Create your first magical campaign</h2>
            </div>
            <div>
                <Atom.Button
                    type="primary"
                    className="px-5 py-[.6rem]"
                    onClick={handleOpenDialog}
                >
                    Create Campaign
                </Atom.Button>
            </div>
        </div>
    )
}

export default CampaignsDefault;