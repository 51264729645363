import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { SketchPicker } from 'react-color';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import AppContext from '../../../context/AppContext';
import Atom from '../../atoms';
import Organism from '..';
import { Label } from '../../../../components/ui/label';
import { Plus } from 'lucide-react';
import { Textarea } from '../../../../components/ui/textarea';
import { Switch } from "../../../../components/ui/switch";
import { ScrollArea, ScrollBar } from '../../../../components/ui/scroll-area';

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import { debounce } from 'lodash';
import { sendFormData, fetchData } from 'apis/APIWrapper';

import { MainLogo } from 'common/assets/images';
import { useToast } from "../../../../components/ui/use-toast"
import { ToastAction } from "../../../../components/ui/toast"
import { ThreeCircles } from 'react-loader-spinner';
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group"
import { removeDuplicateVariables } from 'lib/storage';




const CustomizeLanding = ({ isEditing, returnPath }) => {
    const navigate = useNavigate();
    const { toast } = useToast()
    const { thumbnailData, variables, selectedVariables, variablesWithoutDuplicates, setVariablesWithoutDuplicates } = useContext(AppContext);
    const [logoImageUrl, setLogoImageUrl] = useState();
    const [logoFile, setLogoFile] = useState()
    const [layout, setLayout] = useState('theme 2');
    const [backgroundColor, setBackgroundColor] = useState('[#eef1f6]');
    const [activeBgColorButton, setActiveBgColorButton] = useState(backgroundColor);
    const [fontColor, setFontColor] = useState("[#0a1539]");
    const [activeFontColorButton, setActiveFontColorButton] = useState(fontColor);
    const [buttonColor, setButtonColor] = useState('[#612fff]');
    const [activeButtonColorSet, setActiveButtonColorSet] = useState(buttonColor);
    const [showButton, setShowButton] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [textAreas, setTextAreas] = useState({
        titleText: { value: "", charCount: 0 },
        descriptionText: { value: "", charCount: 0 },
        buttonText: { value: "Lets Go", charCount: 0 },
        buttonLink: { value: "", charCount: 0 },
    });
    const [logoWidth, setLogoWidth] = useState(10);
    const [widthError, setWidthError] = useState('');
    const [tempWidthValue, setTempWidthValue] = useState(10);
    const [openPicker, setOpenPicker] = useState(null);
    const [isBgColorSelected, setIsBgColorSelected] = useState(false);
    const [isFontColorSelected, setIsFontColorSelected] = useState(false);
    const [isButtonColorSelected, setIsButtonColorSelected] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [eventUrl, setEventUrl] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [iframeSrc, setIframeSrc] = useState('');
    const [iframeScript, setIframeScript] = useState('');
    const widgetRef = useRef(null);
    const iframeRef = useRef(null);
    const { campaignId, step } = useParams()
    const [errors, setErrors] = useState({});
    const [localSelectedVariables, setLocalSelectedVariables] = useState();
    const buttonText = isEditing ? 'Save changes' : 'Publish & Add Contacts';


    useEffect(() => {
        if (iframeScript) {
            const iframe = iframeRef.current;
            if (iframe) {
                const doc = iframe.contentWindow.document;
                doc.open();
                doc.write(`
              <!DOCTYPE html>
              <html lang="en">
                <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Embedded Script</title>
                </head>
                <body>
                  ${iframeScript}
                </body>
              </html>
            `);
                doc.close();
            }
        }
    }, [iframeScript]);

    useEffect(() => {
        const fetchVideo = async () => {
            setIsLoading(true);
            try {
                const response = await fetchData(`/campaigns/landingPage/data/${campaignId}`);
                if (response) {
                    setVideoUrl(response.video_url);
                    // Remove duplicates if exist
                    setVariablesWithoutDuplicates(removeDuplicateVariables(response.variables));
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchVideo();
    }, [campaignId]);

    const fileUploadProp = {
        border: "solid",
        borderRadius: "100px",
        acceptType: "image/*",
        onChange: (event) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
                console.log(`Saving ${event.target.value}`);
                const file = event.target.files[0];
                setLogoFile(file)
                if (file) {
                    const imageURL = URL.createObjectURL(file);
                    setLogoImageUrl(imageURL);
                    clearError('logoError');
                    // props.setLandingPageUploadError(false)
                }
                // setLandingPageData((prevState) => ({
                //     ...prevState,
                //     logoImageFile: file,
                // }));
            }
        },
        onDrop: (event) => {
            console.log(`Drop ${event.dataTransfer.files[0].name}`);
        },
    };

    const handleChange = (name, event) => {
        const newCharCount = event.target.value.length;
        setTextAreas(prevState => ({
            ...prevState,
            [name]: { value: event.target.value, charCount: newCharCount },
        }));

        clearError(name);
    };


    const showButtonHandler = () => {
        setShowButton(!showButton);
    }


    const validateFields = () => {
        let newErrors = {};

        // if (!logoImageUrl) newErrors.logoError = "Logo is required";
        // if (!textAreas.titleText.value) newErrors.titleText = "Title is required";
        // if (!textAreas.descriptionText.value) newErrors.descriptionText = "Description is required";
        // if (!iframeScript) newErrors.iframeScript = "Iframe code is required";

        setErrors(newErrors);
        return newErrors;
    };

    const clearError = (field) => {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[field];
            return newErrors;
        });
    };

    const PublishButtonHandler = () => {
        const errors = validateFields();
        const isValid = Object.keys(errors).length === 0;
        console.log("Is valid:", isValid);

        if (isValid) {
            setOpenDialog(true);
        } else {
            const unfilledFields = Object.values(errors).join(", ");
            toast({
                duration: 4000,
                variant: "error",
                title: "Please fill mandatory fields",
                description: `${unfilledFields}`,
                action: <ToastAction altText="Try again">Try again</ToastAction>,
            });
        }
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handlePublishCampaign = async () => {
        setIsLoading(true);
        // setOpenDialog(false);
        const formData = new FormData();
        if (logoFile !== undefined && logoFile !== null) {
            formData.append("logo_image_url", logoFile);
        }
        formData.append("logo_width", logoWidth);
        formData.append("theme", layout);
        formData.append("background_color", backgroundColor);
        formData.append("title", textAreas.titleText.value);
        formData.append("description", textAreas.descriptionText.value);
        formData.append("button_enabled", showButton);
        formData.append("button_text", textAreas.buttonText.value);
        formData.append("button_link", textAreas.buttonLink.value);
        formData.append("font_color", fontColor);
        formData.append("button_color", buttonColor);
        formData.append("script", iframeScript);
        try {
            const response = await sendFormData(`/campaigns/add/landingPage/${campaignId}/${parseInt(step) + 1}`, formData);

            if (response) {
                if (isEditing) {
                    navigate(returnPath || `/campaigns`);
                } else {
                    toast({
                        duration: 3000,
                        variant: "success",
                        title: "Campaign successfully published",
                    });
                    navigate(`/campaigns/summary/${campaignId}`);
                }
            } else {
                throw new Error("No response received from the server");
            }
        } catch (error) {
            console.error("Error publishing campaign:", error);
            setIsLoading(false);
            toast({
                duration: 5000,
                variant: "destructive",
                title: "Failed to publish campaign",
                description: "An error occurred while publishing your campaign. Please try again.",
            });
        } finally {
            setOpenDialog(false);
            setIsLoading(false);
        }
    };

    const backgroundColors = [
        '#eef1f6',
        '#00203f',
        '#52796f',
        '#beefff',
        '#765ac4',
    ];

    const fontColors = [
        '#0a1539',
        '#eef1f6',
        '#a5bff8',
        '#0a3939',
        '#d7c49e',
    ];

    const buttonColors = [
        '#612fff',
        '#0a1539',
        '#a5bff8',
        '#0a3939',
        '#d7c49e',
    ];

    const Item = ({ entity }) => <div>{`${entity.value}`}</div>;
    const Loading = ({ data }) => <div>Loading..</div>;

    const rtaRef = useRef(null);

    const titleTextareaRef = useRef(null);
    const descriptionTextareaRef = useRef(null);

    const parseTextWithHighlights = (text) => {
        const parts = text.split(/(@\w+)/);
        return parts.map((part, index) => {
            if (part.startsWith('@')) {
                return (
                    <span key={index} className="text-violet-600 font-[500]">
                        {part}
                    </span>
                );
            }
            return part;
        });
    };

    const parseDescriptionText = (text) => {
        const paragraphs = text.split('\n');

        return paragraphs.map((paragraph, paragraphIndex) => {
            const parts = paragraph.split(/(@\w+)/);
            const formattedParts = parts.map((part, index) => {
                if (part.startsWith('@')) {
                    return (
                        <span key={index} className="text-violet-600 font-medium">
                            {part}
                        </span>
                    );
                }
                return part;
            });

            return (
                <p key={paragraphIndex} className="indent-8 mb-4">
                    {formattedParts}
                </p>
            );
        });
    };



    const titleText = textAreas.titleText.value;
    const descriptionText = textAreas.descriptionText.value;

    const displayTitleText = titleText ? parseTextWithHighlights(titleText) : "Here is your titleText";
    const displayDescriptionText = descriptionText ? parseDescriptionText(descriptionText) : "Here is your sub heading to explain what you mean in detail";

    const validateWidth = useMemo(() => debounce((value) => {
        if (value >= 0 && value <= 50) {
            setLogoWidth(value);
            setWidthError('');
        } else {
            setWidthError('you can not set the size beyond 50');
        }
    }, 500), []);

    const handleWidthChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setTempWidthValue(value);
        validateWidth(value);
    };

    const debouncedSetEventUrl = debounce((value) => {
        // setEventUrl(value);
        setInputValue(value);
    }, 500);

    const handleCalenderUrlInputChange = useCallback((value) => {
        debouncedSetEventUrl(value);
        clearError("iframeScript");
    }, []);


    const handleEmbedButtonClick = () => {
        // setEventUrl(inputValue);
        setIframeScript(inputValue);
    };


    const handleTextPosition = (value) => {
        if (value === "theme 1") {
            setLayout('theme 1');

        } else {
            setLayout('theme 2')
        };
    }

    return (
        <ScrollArea>
            {isLoading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 " style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div className="grid grid-flow-row lg:grid-flow-col grid-cols-7 gap-4 px-8 h-full">
                <div className="col-span-7 md:col-span-4 xl:col-span-4 2xl:col-span-5 space-y-3 h-[99vh]">
                    <h2 className='text-2xl font-bold text-center'>Customize Landing Page</h2>
                    {/* Landing page preview */}
                    <ScrollArea className={`w-full ${iframeScript ? 'h-[90vh]' : 'h-full'} 2xl:h-[94vh]`}>
                        <div className="w-full overflow-auto">
                            <div className={`bg-[#f8f8f8] h-[100%] min-h-[600px] md:min-h-[870px] 2xl:min-h-[94vh] flex flex-grow flex-col gap-4 p-5  ${layout === 'theme 1' ? 'items-start' : 'items-center'}`} style={{ backgroundColor: backgroundColor }}>  {/* 2xl:min-h-[905px] */}
                                <img
                                    src={logoImageUrl || MainLogo}
                                    alt="Logo"
                                    // className="mb-4 w-60 h-24"
                                    style={{ width: `${logoWidth}%` }}
                                    className="mb-4"

                                />
                                <div className={`flex ${layout === 'theme 1' ? 'flex-row' : 'flex-col'} items-center justify-center gap-4`}>
                                    <div className="flex flex-col items-center text-wrap">
                                        <div className={`flex flex-col gap-1 items-center max-w-[260px] 2xl:max-w-[600px] min-w-[260px] 2xl:min-w-[600px]`}
                                            style={{ overflowWrap: 'break-word' }}
                                        >
                                            <h1 className="text-2xl mb-2 font-semibold" style={{ color: fontColor, overflowWrap: 'anywhere' }}>
                                                {displayTitleText}
                                            </h1>
                                            <p className="text-lg mb-4"
                                                // style={{ color: fontColor, overflowWrap: 'anywhere' }}
                                                style={{ color: fontColor, overflowWrap: 'anywhere' }}
                                            >
                                                {displayDescriptionText}
                                            </p>
                                        </div>

                                        {showButton && (
                                            <Atom.Button type="primary" style={{ backgroundColor: buttonColor, border: "none", minWidth: '10%' }}>
                                                {textAreas.buttonText.value}
                                            </Atom.Button>
                                        )}
                                    </div>
                                    <video
                                        autoPlay
                                        loop
                                        controls
                                        src={videoUrl}
                                        className="mb-4 rounded-3xl w-full max-w-[500px] min-w-[270px] 2xl:min-w-[470px] max-h-[260px] min-h-[100px] 2xl:min-h-[260px] object-cover"

                                    />
                                </div>
                                {iframeScript && (
                                    <iframe
                                        ref={iframeRef}
                                        className="w-[100%] h-[750px]"
                                        title="Embedded Script"
                                    />
                                )}
                            </div>
                        </div>
                        <ScrollBar orientation="horizontal" className="w-full" />
                    </ScrollArea>
                </div>

                {/* Customization segment */}
                <div className="col-span-7 md:col-span-3 xl:col-span-3 2xl:col-span-2 border p-4 py-0 3xl:py-4">
                    <div className='flex flex-col gap-6 justify-between xl:max-h-[85%] 3xl:max-h-[90%]'>
                        <div>
                            <Tabs defaultValue='message' className='flex flex-col gap-2 3xl:gap-4'>
                                <TabsList className="bg-transparent flex gap-1 lg:gap-3 w-full">
                                    <TabsTrigger value="message" className="p-1 text-xs lg:px-3.5 lg:py-1.5 lg:text-sm">Content</TabsTrigger>
                                    <TabsTrigger value="branding" className="px-1.5 py-1 text-xs lg:px-3.5 lg:py-1.5 lg:text-sm">Branding</TabsTrigger>
                                    <TabsTrigger value="iframe" className="p-1 text-xs lg:px-3.5 lg:py-1.5 lg:text-sm">iframe</TabsTrigger>
                                </TabsList>
                                <div>
                                    <TabsContent value="branding" >
                                        <div className="flex flex-col gap-4 xl:gap-4 3xl:gap-6 mb-3 3xl:mb-4">
                                            {/* Logo section */}
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Upload Your Logo</Label>
                                                <Organism.UploadButtons {...fileUploadProp} />
                                                {errors.logoError && <p className="text-red-500 text-xs mt-1 text-center">{errors.logoError}</p>}
                                                <div className='flex flex-col justify-center items-center mt-0'>
                                                    <div className='flex gap-4 items-center col-span-2 xl:col-span-1'>
                                                        <Label className="text-sm font-semibold">Logo Size</Label>
                                                        <div className='flex gap-1 items-center'>
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                max="50"
                                                                value={tempWidthValue}
                                                                onChange={handleWidthChange}
                                                                className="file:border-0 file:bg-transparent file:text-sm bg-background text-sm ring-offset-background placeholder:text-muted-foreground text-gray-600 focus-visible:outline-none border border-gray-300 hover:border-gray-500 focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-50 w-12 lg:w-16 p-1 lg:p-2 rounded-2xl"

                                                            />
                                                            <p className='text-xs lg:text-sm'>%</p>
                                                        </div>
                                                    </div>
                                                    {widthError && <p className="text-red-500 text-xs mt-1 text-center">{widthError}</p>}
                                                </div>
                                            </div>
                                            {/* Layout section */}
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Themes</Label>
                                                <div className='flex gap-4 items-center justify-center'>
                                                    <RadioGroup defaultValue="theme 2" onValueChange={handleTextPosition} className="flex gap-20">
                                                        <div className="flex gap-1">
                                                            <RadioGroupItem value="theme 1" id="r1" />
                                                            <Label htmlFor="r1">Theme 1</Label>
                                                        </div>
                                                        <div className="flex gap-1">
                                                            <RadioGroupItem value="theme 2" id="r3" />
                                                            <Label htmlFor="r3">Theme 2</Label>
                                                        </div>
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                            {/* Bg-color section*/}
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Background Color</Label>
                                                <div className='flex justify-start lg:justify-evenly gap-2 lg:gap-0 flex-wrap'>
                                                    {backgroundColors.map((color) => (
                                                        <Atom.Button
                                                            key={color}
                                                            type="round"
                                                            style={{ backgroundColor: color }}
                                                            className={`${activeBgColorButton === color ? 'border-2 border-violet-600 shadow-md' : ''}`}
                                                            onClick={() => {
                                                                setBackgroundColor(color);
                                                                setActiveBgColorButton(color);
                                                                setIsBgColorSelected(false);
                                                            }}
                                                        ></Atom.Button>
                                                    ))}
                                                    {/* With the react-color library */}
                                                    <Atom.Button
                                                        type="round"
                                                        className={`bg-[#eef1f6] relative hover:bg-[#eef1f6]/90 px-3 rounded-full ${openPicker === 'background' || isBgColorSelected ? 'border-2 border-violet-600' : ''}`}
                                                        onClick={() => setOpenPicker(openPicker === 'background' ? null : 'background')}
                                                    >
                                                        <Plus className="h-4 w-4 text-[#612FFF]" />
                                                    </Atom.Button>
                                                    {openPicker === 'background' &&
                                                        <div className='absolute z-2'>
                                                            <div className='fixed top-0 right-0 bottom-0 left-0' onClick={() => setOpenPicker(null)} />
                                                            <SketchPicker
                                                                color={backgroundColor}
                                                                onChange={(color) => {
                                                                    setBackgroundColor(color.hex)
                                                                }}
                                                                onChangeComplete={(color) => {
                                                                    setBackgroundColor(color.hex)
                                                                    setIsBgColorSelected(true);
                                                                    setActiveBgColorButton(null);
                                                                }}
                                                                className='absolute z-50 bottom-0 left-0'
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {/* Font color section*/}
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Font Color</Label>
                                                <div className='relative flex justify-start lg:justify-evenly gap-2 lg:gap-0 flex-wrap'>
                                                    {fontColors.map((color) => (
                                                        <Atom.Button
                                                            key={color}
                                                            type="round"
                                                            style={{ backgroundColor: color }}
                                                            className={` ${activeFontColorButton === color ? 'border-2 border-violet-600 shadow-md' : ''}`}
                                                            onClick={() => {
                                                                setFontColor(color);
                                                                setActiveFontColorButton(color);
                                                                setIsFontColorSelected(false);
                                                            }}
                                                        ></Atom.Button>
                                                    ))}
                                                    {/* With the react-color library */}
                                                    <Atom.Button
                                                        type="round"
                                                        className={`bg-[#eef1f6] relative hover:bg-[#eef1f6]/90 px-3 rounded-full ${openPicker === 'font' || isFontColorSelected ? 'border-2 border-violet-600' : ''}`}
                                                        onClick={() => setOpenPicker(openPicker === 'font' ? null : 'font')}
                                                    >
                                                        <Plus className="h-4 w-4 text-[#612FFF]" />
                                                    </Atom.Button>
                                                    {openPicker === 'font' &&
                                                        <div className='absolute z-2'>
                                                            <div className='fixed top-0 right-0 bottom-0 left-0' onClick={() => setOpenPicker(null)} />
                                                            <SketchPicker
                                                                color={fontColor}
                                                                onChange={(color) => {
                                                                    setFontColor(color.hex)
                                                                }}
                                                                onChangeComplete={(color) => {
                                                                    setFontColor(color.hex)
                                                                    setIsFontColorSelected(true);
                                                                    setActiveFontColorButton(null);

                                                                }}
                                                                className='absolute z-50 bottom-0 left-0'
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {/* Button color section */}
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Button Color</Label>
                                                <div className='relative flex justify-start lg:justify-evenly gap-2 lg:gap-0 flex-wrap'>
                                                    {buttonColors.map((color) => (
                                                        <Atom.Button
                                                            key={color}
                                                            type="round"
                                                            style={{ backgroundColor: color }}
                                                            className={`${activeButtonColorSet === color ? 'border-2 border-violet-600 shadow-md' : ''}`}
                                                            onClick={() => {
                                                                setButtonColor(color);
                                                                setActiveButtonColorSet(color);
                                                                setIsButtonColorSelected(false);
                                                            }}
                                                        ></Atom.Button>
                                                    ))}
                                                    {/* With the react-color library */}
                                                    <Atom.Button
                                                        type="round"
                                                        className={`bg-[#eef1f6] relative hover:bg-[#eef1f6]/90 px-3 rounded-full ${openPicker === 'button' || isButtonColorSelected ? 'border-2 border-violet-600' : ''}`}
                                                        onClick={() => setOpenPicker(openPicker === 'button' ? null : 'button')}
                                                    >
                                                        <Plus className="h-4 w-4 text-[#612FFF]" />
                                                    </Atom.Button>
                                                    {openPicker === 'button' &&
                                                        <div className='absolute z-2'>
                                                            <div className='fixed top-0 right-0 bottom-0 left-0' onClick={() => setOpenPicker(null)} />
                                                            <SketchPicker
                                                                color={buttonColor}
                                                                onChange={(color) => {
                                                                    setButtonColor(color.hex)
                                                                }}
                                                                onChangeComplete={(color) => {
                                                                    setButtonColor(color.hex)
                                                                    setIsButtonColorSelected(true);
                                                                    setActiveButtonColorSet(null);

                                                                }}
                                                                className='absolute z-50 bottom-0 left-0'
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="message">
                                        <div className='flex flex-col gap-4 xl:gap-4 3xl:gap-8'>
                                            <div className='relative flex flex-col gap-1 3xl:gap-2'>
                                                <Label className="text-sm font-semibold">Title</Label>
                                                <ReactTextareaAutocomplete
                                                    className="flex md:w-[50%] lg:w-[75%] xl:w-full 2xl:w-[80%] 3xl:w-full rounded-3xl file:border-0 file:bg-transparent file:text-sm bg-background text-sm ring-offset-background placeholder:text-muted-foreground text-gray-600 focus-visible:outline-none border border-gray-300 hover:border-gray-500 focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-50 py-2 px-6 resize-none min-h-[4vh]"
                                                    loadingComponent={Loading}
                                                    style={{ maxHeight: "200px", flexGrow: 0, }}
                                                    ref={rta => rtaRef.current = rta}
                                                    // innerRef={(textarea) => {
                                                    //     titleTextareaRef.current = textarea;
                                                    //     // Currently setting the maxLength with direct DOM manipulation
                                                    //     if (titleTextareaRef.current) {
                                                    //         titleTextareaRef.current.maxLength = 30;
                                                    //     }
                                                    // }}
                                                    containerStyle={{ width: 400, }}
                                                    dropdownStyle={{ position: 'absolute', zIndex: 1000, top: '100%', left: 0, backgroundColor: '#efefef', borderRadius: '1rem', minWidth: 160, width: 190, padding: "10px", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                                                    minChar={0}
                                                    trigger={{
                                                        "@": {
                                                            dataProvider: () => variablesWithoutDuplicates.slice(0, 10).map(v => ({ value: v.variable })),
                                                            component: ({ entity, selected }) => <div className='rounded-2xl text-sm p-1 text-gray-700 hover:bg-violet-100 hover:text-violet-600'>{`${entity.value}`}</div>,
                                                            output: (item, trigger) => {
                                                                if (typeof item === 'string') {
                                                                    return item;
                                                                } else if (typeof item === 'object' && item !== null) {
                                                                    // return "@" + item.value;
                                                                    return `@${item.value}`;
                                                                }
                                                            },
                                                        }
                                                    }}
                                                    value={textAreas.titleText.value}
                                                    onChange={(e) => handleChange("titleText", e)}
                                                    placeholder="Enter your title text here.."
                                                />
                                                {/* <p className="text-right text-xs text-gray-500">
                                                    Characters: {textAreas.titleText.charCount}/30
                                                </p> */}
                                                {errors.titleText && <p className="text-red-500 text-xs mt-1 text-center">{errors.titleText}</p>}

                                                <p className='text-xs text-center text-gray-500 px-2'>Type @ to open a dropdown menu and select variables to insert into your title text</p>
                                            </div>
                                            <div className='relative flex flex-col gap-1 3xl;gap-2'>
                                                <Label className="text-sm font-semibold">Description</Label>
                                                <ReactTextareaAutocomplete
                                                    className=" flex md:w-[50%] lg:w-[75%] xl:w-full 2xl:w-[80%] 3xl:w-full rounded-3xl file:border-0 file:bg-transparent file:text-sm bg-background text-sm ring-offset-background placeholder:text-muted-foreground text-gray-600 focus-visible:outline-none border border-gray-300 hover:border-gray-500 focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-50 py-2 px-6 resize-none xl:min-h-[23vh] 2xl:min-h-[15vh]"
                                                    loadingComponent={Loading}
                                                    style={{ maxHeight: "300px", flexGrow: 0, }}
                                                    ref={rta => rtaRef.current = rta}
                                                    // innerRef={(textarea) => {
                                                    //     descriptionTextareaRef.current = textarea;
                                                    //     if (descriptionTextareaRef.current) {
                                                    //         descriptionTextareaRef.current.maxLength = 250;
                                                    //     }
                                                    // }}
                                                    containerStyle={{ width: 400, }}
                                                    dropdownStyle={{ position: 'absolute', zIndex: 100, top: '100%', left: 0, backgroundColor: '#efefef', borderRadius: '1rem', minWidth: 160, width: 190, padding: "10px", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                                                    minChar={0}
                                                    trigger={{
                                                        "@": {
                                                            dataProvider: () => variablesWithoutDuplicates.slice(0, 10).map(v => ({ value: v.variable })),
                                                            component: ({ entity }) => <div className='rounded-2xl text-sm p-1 text-gray-700 hover:bg-violet-100 hover:text-violet-600'>{`${entity.value}`}</div>,
                                                            output: (item, trigger) => {
                                                                if (typeof item === 'string') {
                                                                    return item;
                                                                } else if (typeof item === 'object' && item !== null) {
                                                                    return `@${item.value}`;
                                                                }
                                                            },
                                                        }
                                                    }}
                                                    value={textAreas.descriptionText.value}
                                                    onChange={(e) => handleChange("descriptionText", e)}
                                                    placeholder="Enter your description text here.."
                                                />
                                                {/* <p className="text-right text-xs text-gray-500">
                                                    Characters: {textAreas.descriptionText.charCount}/250
                                                </p> */}
                                                {errors.descriptionText && <p className="text-red-500 text-xs mt-1 text-center">{errors.descriptionText}</p>}
                                                <p className='text-xs text-center text-gray-500 px-2'>Type @ to open a dropdown menu and select variables to insert into your description text</p>
                                            </div>
                                            <div className='flex flex-col gap-1 3xl:gap-2'>
                                                <div className='flex justify-between'>
                                                    <Label className="text-sm font-semibold">Button</Label>
                                                    <Switch
                                                        checked={showButton}
                                                        onCheckedChange={showButtonHandler}
                                                    />
                                                </div>
                                                <div className='grid grid-cols-2 gap-1 3xl:gap-2'>
                                                    <div className='col-span-2'>
                                                        <Textarea
                                                            // value={textAreas.buttonText.value}
                                                            onChange={(e) => handleChange("buttonText", e)}
                                                            maxLength={15}
                                                            placeholder="Enter your button text here.."
                                                            className="py-1 px-6 resize-none min-h-[5vh]"
                                                        />
                                                        <p className="text-right text-xs text-gray-500">
                                                            Characters: {textAreas.buttonText.charCount}/15
                                                        </p>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <Textarea
                                                            // value={buttonLink}
                                                            onChange={(e) => handleChange("buttonLink", e)}
                                                            maxLength={100}
                                                            placeholder="Enter your button link here.."
                                                            className="py-1 px-6 resize-none min-h-[5vh]"
                                                        />
                                                        <p className="text-right text-xs text-gray-500">
                                                            Characters: {textAreas.buttonLink.charCount}/100
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="iframe">
                                        <div className='flex flex-col gap-2'>
                                            <Label className="text-sm font-semibold">Add iframe</Label>
                                            <Textarea
                                                // value={iframeScript}
                                                onChange={(e) => handleCalenderUrlInputChange(e.target.value)}
                                                placeholder="Enter code here..."
                                                className="resize-none min-h-[30vh] 2xl:min-h-[20vh] text-xs"
                                            />
                                            {errors.iframeScript && <p className="text-red-500 text-xs mt-1 text-center">{errors.iframeScript}</p>}
                                            <Atom.Button
                                                type="outline"
                                                onClick={handleEmbedButtonClick}
                                            >
                                                Embed iframe
                                            </Atom.Button>
                                            <p className='text-xs italic text-gray-500 mt-2'>click the "Embed iframe" button to push the iframe code onto your landing page preview</p>
                                        </div>
                                    </TabsContent>
                                </div>
                            </Tabs>
                        </div>
                        <Atom.Button type="primary" className="mx-auto" onClick={PublishButtonHandler}>{buttonText}</Atom.Button>
                    </div>
                </div >
                <Organism.Modal
                    open={openDialog}
                    setOpen={setOpenDialog}
                    handleClose={handleCloseDialog}
                    showInput="false"
                    title="Are you sure you want to publish your campaign?"
                    showSubHeading="true"
                    subHeading="Please make sure you have reviewed all the details before publishing. you would be able to add your contacts after you publish the campaign"
                    confirmLabel="Publish Campaign"
                    onCancel={handleCloseDialog}
                    onConfirm={handlePublishCampaign}
                />
            </div >

        </ScrollArea >
    )
}

export default CustomizeLanding;