import { useState, useEffect } from 'react'
import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { resetAuthTokens } from "../../../apis/axios";
import { clearSessionStorageCredentials } from '../../../lib/storage';
import Atom from '../atoms';
import { Search, ChevronDown, ArrowRight, BadgeHelp, Settings, LogOut, Receipt } from 'lucide-react'
import React from 'react'
import { Input } from '../../../components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu"

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../components/ui/tooltip";
import { Separator } from 'components/ui/separator';


const Header = ({ title, buttonLabel, inputPlaceholder, elements, onButtonClick, listPage }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const { signOut } = useClerk();
    const [userInfo, setUserInfo] = useState({});


    useEffect(() => {
        setUserInfo({
            name: sessionStorage.getItem('name').replace(/^"|"$/g, ''),
            firstName: sessionStorage.getItem('name').trim().split(/\s+/)[0].replace(/^"|"$/g, ''),
            email: sessionStorage.getItem('authEmail').replace(/^"|"$/g, ''),
        });
    }, []);

    const onChange = (e) => {
        setValue(e.target.value)
    }


    const handleLogout = async () => {
        resetAuthTokens();
        clearSessionStorageCredentials();
        await signOut();
        window.location.href = "/login"
    };

    const handleBilling = () => {
        navigate("/plans")
    }


    const handleHelp = () => {
        console.log('help');
    };

    const handleSettings = () => {
        console.log('settings');
    };

    return (
        <div className='flex sm:flex-row gap-4 p-6 py-4 3xl:py-6 w-full justify-between xs:items-center'>
            {
                listPage ? (
                    <div className='flex-1'>
                        <h1 className='text-3xl font-bold'>{title}</h1>
                    </div>
                ) : (
                    <div className=" bg-clip-text text-transparent bg-no-repeat bg-gradient-to-br from-violet-700 via-purple-600 to-pink-700 py-4">
                        <span className="text-3xl font-bold">{title}</span>
                    </div>
                )
            }

            {elements.includes("SEARCH") && (
                <div className="relative w-80 sm:w-auto">
                    <Input
                        onChange={onChange}
                        value={value}
                        placeholder={inputPlaceholder}
                        className="pr-10 pl-5 rounded-3xl text-xs text-[#9B9B9B] border-[#c1c1c1]"
                    />
                    <Search className="absolute text-[#9B9B9B] h-4 w-4 top-3 right-4" />
                </div>
            )}
            {elements.includes("BUTTON") && (
                <div className='sm:ml-4'>
                    <Atom.Button
                        type="primary"
                        className="px-5 py-[.6rem]"
                        onClick={onButtonClick}
                    >
                        {buttonLabel}
                    </Atom.Button>
                </div>
            )
            }
            <TooltipProvider>
                <DropdownMenu>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <DropdownMenuTrigger asChild>
                                <div
                                    className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg active:scale-105 transition-all duration-300"
                                >
                                    <Atom.Avatar size="small" />
                                    <p>
                                        <span className="text-gray-400 text-14">Hi,</span>{' '}
                                        <span className="text-gray-400 font-bold ml-1 text-14">
                                            {userInfo['firstName']}
                                        </span>
                                    </p>
                                    <ChevronDown className="text-gray-400 text-14 h-5 w-5" />
                                </div>
                            </DropdownMenuTrigger>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>profile</p>
                        </TooltipContent>
                    </Tooltip>
                    <DropdownMenuContent className="p-4 flex flex-col gap-6 rounded-lg shadow-xl bg-[#f1eef8]">
                        <div className='flex flex-col gap-4'>
                            <div className='flex gap-4 justify-center items-center'>
                                <Atom.Avatar size="large" />
                                <div>
                                    <h2 className='text-lg font-medium'>{userInfo['name']}</h2>
                                    <p className='text-sm text-gray-500'>{userInfo['email']}</p>
                                </div>
                            </div>
                            <Separator />
                        </div>
                        <div className='flex flex-col space-y-3 px-4 pb-4'>
                            <div onClick={handleHelp} className='flex items-center p-2 group rounded-lg'>
                                <BadgeHelp className="w-5 h-5 group-hover:scale-105 group-hover:text-violet-600" />
                                <span className="ml-3 group-hover:scale-105 group-hover:text-violet-600">Help</span>
                                <ArrowRight className='w-4 h-4 ml-auto group-hover:scale-105 group-hover:text-violet-600' />
                            </div>
                            {/* <Separator /> */}
                            <div onClick={handleSettings} className='flex items-center p-2 group rounded-lg'>
                                <Settings className="w-5 h-5 group-hover:scale-105 group-hover:text-violet-600" />
                                <span className="ml-3 group-hover:scale-105 group-hover:text-violet-600">Settings</span>
                                <ArrowRight className='w-4 h-4 ml-auto group-hover:scale-105 group-hover:text-violet-600' />
                            </div>
                            {/* <Separator /> */}
                            <div onClick={handleBilling} className='flex items-center p-2 group rounded-lg'>
                                <Receipt className="w-5 h-5 group-hover:scale-105 group-hover:text-violet-600" />
                                <span className="ml-3 group-hover:scale-105 group-hover:text-violet-600">Billing</span>
                                <ArrowRight className='w-4 h-4 ml-auto group-hover:scale-105 group-hover:text-violet-600' />
                            </div>
                            <div onClick={handleLogout} className='flex items-center p-2 group rounded-lg'>
                                <LogOut className="w-5 h-5 group-hover:scale-105 group-hover:text-violet-600" />
                                <span className="ml-3 group-hover:scale-105 group-hover:text-violet-600">Logout</span>
                                <ArrowRight className='w-4 h-4 ml-auto group-hover:scale-105 group-hover:text-violet-600' />
                            </div>
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>
            </TooltipProvider>
        </div >
    )
}

export default Header