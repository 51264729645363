import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import Organism from '../../organisms';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import Atom from '../../atoms';
import AvatarOrganism from '.';
import { useToast } from "components/ui/use-toast"
import { fetchData } from "apis/APIWrapper";
import { ThreeCircles } from 'react-loader-spinner';


const AvatarsList = ({
    publicAvatars,
    myAvatars,
    setMyAvatars,
    handleOpenDialog,
    handleCloseDialog,
    openDialog,
    setOpenDialog,
}) => {
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [showMorePublic, setShowMorePublic] = useState(false);
    const [showMoreMy, setShowMoreMy] = useState(false);
    const [initialCardsToShow, setInitialCardsToShow] = useState(4);
    const navigate = useNavigate();
    const { toast } = useToast()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [avatarToDelete, setAvatarToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const updateInitialCardsToShow = () => {
            if (window.matchMedia('(min-width: 1536px)').matches) {
                setInitialCardsToShow(5);
            } else if (window.matchMedia('(min-width: 1279px)').matches) {
                setInitialCardsToShow(4)
            } else if (window.matchMedia('(min-width: 1024px)').matches) {
                setInitialCardsToShow(3);
            } else if (window.matchMedia('(min-width: 768px)').matches) {
                setInitialCardsToShow(4);
            } else if (window.matchMedia('(min-width: 576px)').matches) {
                setInitialCardsToShow(3);
            } else {
                setInitialCardsToShow(2);
            }
        };

        updateInitialCardsToShow();
        window.addEventListener('resize', updateInitialCardsToShow);

        return () => window.removeEventListener('resize', updateInitialCardsToShow);
    }, []);

    const handleSelect = (avatar) => {
        setSelectedAvatar(avatar.id);
        if (avatar.step <= 9) {
            navigate(`/avatars/create/audio-training/start/${avatar.id}`, { state: { step: avatar.step } });
        } else if (avatar.step > 9 && avatar.status === 'Draft') {
            navigate(`/avatars/create/video-training/instructions/${avatar.id}`);
        } else if (avatar.status === "Trained") {
            toast({
                duration: 5000,
                variant: "success",
                title: "Avatar already trained!",
                description: "This avatar is already fully trained and ready to use in your campaign.",
            });
            return;
        }
    };

    const deleteButtonHandler = (avatar) => {
        setAvatarToDelete(avatar);
        setOpenDeleteDialog(true);
    }

    const handleDeleteAvatar = async (avatarId) => {
        setIsLoading(true);
        try {
            const response = await fetchData(`/avatar/private/delete/${avatarId}`, 'DELETE');
            if (response) {
                setMyAvatars((prevMyAvatars) => prevMyAvatars.filter(avatar => avatar.id !== avatarId));
                setOpenDeleteDialog(false);
                // window.location.reload();
            }
        } catch (error) {
            console.error('Failed to delete avatar:', error);
            setIsLoading(false);
            setOpenDeleteDialog(false);

        } finally {
            setIsLoading(false);
        }
    };


    const handleCloseDeleteDialog = () => {
        setAvatarToDelete(null);
        setOpenDeleteDialog(false);
    }

    const toggleShowMorePublic = () => setShowMorePublic(!showMorePublic);
    const toggleShowMoreMy = () => setShowMoreMy(!showMoreMy);

    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <ScrollArea>
                    <div className='overflow-auto mx-auto lg:w-[90%] xl:w-[87%] 2xl:w-[85%] 3xl:w-[70%] px-16 py-10 my-auto rounded-2xl'>
                        <Atom.Visibility state={publicAvatars?.length !== 0}>
                            <div className='flex flex-col'>
                                <h2 className="text-xl font-bold mb-4">Public Avatars</h2>
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                                    {publicAvatars.slice(0, showMorePublic ? publicAvatars.length : initialCardsToShow).map((avatar, index) => (
                                        <Organism.AvatarCard
                                            key={index}
                                            name={avatar.name}
                                            status={avatar.status}
                                            imageUrl={avatar.imageUrl}
                                            createdOn={avatar.createdOn}
                                            // isSelected={selectedAvatar === avatar.id}
                                            // onSelect={() => handleSelect(avatar)}
                                            className="w-48 h-60"
                                        />
                                    ))}
                                </div>
                                {publicAvatars.length > initialCardsToShow && (
                                    <button onClick={toggleShowMorePublic} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
                                        {showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
                                        <span className="mx-2">{showMorePublic ? 'Show Less' : 'Show More'}</span>
                                        {/* {showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
                                    </button>
                                )}
                            </div>
                        </Atom.Visibility>

                        <Atom.Visibility state={myAvatars?.length !== 0}>
                            <div className='flex flex-col mb-4'>
                                <h2 className="text-xl font-bold mb-4 mt-8">My Avatars</h2>
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                                    {myAvatars.slice(0, showMoreMy ? myAvatars.length : initialCardsToShow).map((avatar, index) => (
                                        <Organism.AvatarCard
                                            key={index}
                                            name={avatar.name}
                                            status={avatar.status || "Trained"}
                                            imageUrl={avatar.imageUrl || '/draft.png'}
                                            createdOn={avatar.createdOn}
                                            // isSelected={selectedAvatar === avatar.id}
                                            onSelect={() => handleSelect(avatar)}
                                            className="w-48 h-60"
                                            type="avatars-list"
                                            onDelete={() => deleteButtonHandler(avatar)}

                                        />
                                    ))}
                                </div>
                                {myAvatars.length > initialCardsToShow && (
                                    <button onClick={toggleShowMoreMy} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
                                        {showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
                                        <span className="mx-2">{showMoreMy ? 'Show Less' : 'Show More'}</span>
                                        {/* {showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
                                    </button>
                                )}
                            </div>
                        </Atom.Visibility>

                    </div>
                    {/* </Atom.Visibility> */}
                    {/* <Atom.Visibility state={publicAvatars?.length === 0 && myAvatars?.length === 0}>
                <AvatarOrganism.AvatarsDefault handleOpenDialog={handleOpenDialog} />
            </Atom.Visibility> */}
                    <AvatarOrganism.CreateNewAvatar
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        handleCloseDialog={handleCloseDialog}
                    />

                    <Organism.Modal
                        open={openDeleteDialog}
                        setOpen={setOpenDeleteDialog}
                        handleClose={handleCloseDeleteDialog}
                        showInput="false"
                        title={`Are you sure you want to delete your avatar ${avatarToDelete?.name}?`}
                        confirmLabel="Delete"
                        onCancel={handleCloseDeleteDialog}
                        onConfirm={() => handleDeleteAvatar(avatarToDelete.id)}

                    />
                </ScrollArea>
            }
        </>
    )
}

export default AvatarsList;