import TableRow from "./TableRow";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import ContentTable from "./ContentTable";
import ContentPagination from "./ContentPagination";


const Molecule = {
  TableRow,
  TableHead,
  TableBody,
  ContentTable,
  ContentPagination,
}

export default Molecule;
