import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogFooter } from "../../../components/ui/dialog";
import { Input } from '../../../components/ui/input';
import Atom from '../atoms';
import UploadButtons from './FileUploader';
import { cn } from "../../../lib/utils";

const Modal = ({
    open,
    setOpen,
    handleClose,
    handleNameChange,
    inputValue,
    errorMessage,
    title,
    inputPlaceholder,
    confirmLabel,
    onCancel,
    onConfirm,
    showInput = "false",
    showSubHeading = "false",
    subHeading,
    showConsentSection = "false",
    onCheckboxChange,
    isChecked,
    type,
    handleFileSubmit,
    fileUploadProp,
    className,
    charCount,
    setCharCount,
    maxCharLimit,
}) => {

    const campaignFileUploadProp = {
        border: "dashed",
        acceptType: "video/mp4",
        onChange: (event) => {
            handleFileSubmit(event.target)
        },
        onDrop: (event) => {
            handleFileSubmit(event.dataTransfer)
        },
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxCharLimit) {
            setCharCount(value.length);
            handleNameChange(e);
        }
    };

    return (
        <Dialog open={open} DialogClose={handleClose} onOpenChange={setOpen}>
            <DialogContent className={cn('py-10 px-16 flex flex-col gap-10 bg-[#F3F3F6]', className)}>
                <DialogHeader>
                    <DialogTitle className="mx-auto text-xl font-bold">{title}</DialogTitle>
                </DialogHeader>
                {showInput === "true" ?
                    <DialogDescription className="flex flex-col gap-4">
                        <div className='flex flex-col'>
                            <Input
                                placeholder={inputPlaceholder}
                                className="pr-10 pl-5 rounded-3xl text-xs text-[#9B9B9B] border-[#c1c1c1]"
                                onChange={handleInputChange}
                                value={inputValue}
                                maxLength={maxCharLimit}
                            />
                            {type === "createAvatar" || type === "createCampaign" ? <p className="ml-auto text-xs text-gray-500 mt-2">{`${charCount}/${maxCharLimit} characters`}</p> : null}
                        </div>
                        {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
                    </DialogDescription>
                    : null
                }
                {showSubHeading === "true" ?
                    <DialogDescription className="flex flex-col gap-4">
                        <div className='flex flex-col'>
                            <p className='text-sm'>{subHeading}</p>
                        </div>
                    </DialogDescription>
                    : null
                }
                {showConsentSection === "true" ?
                    <DialogDescription className="flex justify-start w-full items-center space-x-2">
                        <Atom.Checkbox checked={isChecked} onChange={onCheckboxChange} />
                        <p className="text-gray-500 text-xs font-light italic">
                            I consent to the use of my voice & facial video for creating an AI generated avatar to be utilized with my personalized video campaigns.
                        </p>
                    </DialogDescription>
                    : null
                }

                {type === "video-campaign" ?
                    <UploadButtons {...campaignFileUploadProp} />
                    : null
                }

                {type === "video-training" ?
                    <div className='flex flex-col gap-4'>
                        <p className='text-sm text-gray-500'>For the best result, you need speak for at least 2 mins to record your face and expressions.</p>
                        <UploadButtons {...fileUploadProp('video1')} />
                        {/* <UploadButtons {...fileUploadProp('video2')} />
                        <UploadButtons {...fileUploadProp('video3')} /> */}

                        <div className='flex flex-col gap-3 text-center font-semibold text-[#868686]'>
                            <p>3 things to keep in mind while recording:</p>
                            <div className=" flex flex-col gap-2 text-[#868686] text-center italic text-sm">
                                <p>1. Stay 2-3 ft (50-100cm) from the camera</p>
                                <p>2. Minimize head/face movement</p>
                                <p>3. Keep the background simple</p>
                            </div>
                        </div>
                    </div>
                    : null
                }

                <DialogFooter className="md:flex flex-2">
                    <Atom.Button type="secondary" width="fullwidth" onClick={onCancel}>
                        Cancel
                    </Atom.Button>
                    <Atom.Button type="primary" width="fullwidth" onClick={onConfirm} disabled={showConsentSection === "true" ? !isChecked : false}>
                        {confirmLabel}
                    </Atom.Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default Modal;
