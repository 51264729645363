import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignUp } from "@clerk/clerk-react";
import * as Yup from "yup";
import Template from "../../common/components/templates";
import { fetchData } from "../../apis/APIWrapper";
import { setToSessionStorage } from "../../lib/storage";
import { useToast } from "components/ui/use-toast"


const SignUpPage = () => {
	const { signUp, setActive } = useSignUp();
	const navigate = useNavigate();
	const { toast } = useToast()


	const [isChecked, setIsChecked] = useState(false);
	const [formError, setFormError] = useState("");


	const SIGNUP_FORM_INITIAL_VALUES = {
		name: "",
		email: "",
		companyName: "",
		password: "",
		confirmPassword: "",
	};

	const SIGNUP_FORM_VALIDATION_SCHEMA = Yup.object({
		name: Yup.string().required("Enter your name"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Enter your email"),
		companyName: Yup.string().required("Enter your company name"),
		password: Yup.string()
			.min(8, "Password is too short - should be   8 chars minimum")
			.required("Enter your password"),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref("password"), null],
			"Passwords must match"
		),
	});

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			let payload = {
				name: values.name,
				company_name: values.companyName,
				email: values.email,
				password: values.password,
			};
			const response = await signUp.create({
				emailAddress: values.email,
				password: values.password,
				// publicMetadata: {
					// role: "User" // including a default user role in publicMetadata
				// },
			});

			if (response.status === "complete") {
				const apiResponse = await fetchData("/user/signup", "POST", payload);
				if (apiResponse.status === 200) {
					toast({
						duration: 3000,
						variant: "success",
						title: "Account successfully created",
					});
					navigate("/login");
				} else {
					console.log(apiResponse, "personiq error response");
					// show signup errors from personiq backend
				}
			} else {
				console.log(response, "error in sign up");
			}
		} catch (error) {
			if (error.status === 422) {
				setFormError(error.errors[0].message);
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleCheckboxChange = (event) => {
		setIsChecked(event.target.checked);
	};

	return (
		<Template.SignUp
			initialValues={SIGNUP_FORM_INITIAL_VALUES}
			validationSchema={SIGNUP_FORM_VALIDATION_SCHEMA}
			isChecked={isChecked}
			onCheckboxChange={handleCheckboxChange}
			onSubmit={handleSubmit}
			formError={formError}
		/>
	);
};

export default SignUpPage;
