import React, { useState, useEffect } from 'react';
import Organism from '../organisms';
import { fetchData } from 'apis/APIWrapper';
import { useParams, useNavigate } from "react-router-dom";
import { NOT_FOUND_PAGE_PATH } from "routes/routeConstants";

const CampaignSetupTemplate = ({ children, titleText }) => {
    const [campaignName, setCampaignName] = useState();
    const [loading, setLoading] = useState(true); // Adding a loading state to prevent screen flicker in case of wrong url 
    const { campaignId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!titleText) {
            fetchCampaignData();
        } else {
            setLoading(false);
        }
    }, [titleText, campaignId]);

    const fetchCampaignData = async () => {
        try {
            if (!campaignId) {
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            }

            const response = await fetchData(`/summary/campaign/${campaignId}`);
            if (response && response.campaign_name) {
                setCampaignName(response.campaign_name);
            } else {
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            }
        } catch (error) {
            console.error("Error fetching campaign data:", error);
            navigate(NOT_FOUND_PAGE_PATH);
            return;
        } finally {
            setLoading(false);
        }
    };

    // Return nothing (null) if loading to prevent any screen flicker
    if (loading) {
        return null;
    }

    return (
        <div className='flex flex-col h-full gap-1'>
            <Organism.Header title={titleText || campaignName} elements={[]} />
            <main className='flex flex-col h-full overflow-auto'>{children}</main>
        </div>
    );
};

export default CampaignSetupTemplate;
