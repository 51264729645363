import { useState } from 'react';

function Checkbox({ checked, onChange }) {
    return (
        <label className="relative block cursor-pointer text-xl select-none">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className="absolute opacity-0 cursor-pointer h-0 w-0"
            />
            <div
                className={`relative h-6 w-6 bg-gray-300 rounded-full transition-all duration-300 ${checked ? 'bg-indigo-600 rounded-md animate-pulse' : ''
                    }`}
            >
                {checked && (
                    <div className="absolute left-2 top-1.5 w-[.4rem] h-[.6rem] border-solid border-2 border-t-0 border-l-0 border-white rotate-45"></div>
                )}
            </div>
        </label>
    );
}

export default Checkbox;
