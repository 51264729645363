import React from 'react'
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';


const CampaignSetupPage = () => {
    

    return (
        <Template.CampaignSetup>
            <CampaignsOrganism.CampaignSetup/>
        </Template.CampaignSetup>
    )
}

export default CampaignSetupPage;