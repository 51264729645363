import React from "react";
import { cn } from "../../../lib/utils";

const ContentPagination = ({ children, className }) => {
    return (
        <div className="border-t border-gray-200">
            <div className={cn(`flex items-center justify-center h-[75px]`, className)} >
                {children}
            </div>
        </div>
    );
}

export default ContentPagination;
