import React from 'react';
import { Form, Formik, Field, ErrorMessage } from "formik";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { Link } from "react-router-dom";
import { Separator } from '../../../../components/ui/separator';
import Atom from '../../atoms';

const LoginForm = ({
    initialValues,
    validationSchema,
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    formError,
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <div className="flex flex-col w-full h-full mt-0 space-y-6 rounded-md p-8 items-center">
                        <h2 className="text-3xl font-semibold text-gray-950">Sign In</h2>
                        <div className="flex flex-col space-y-1 w-full">
                            <label className="block text-gray-500 text-md font-semibold" htmlFor="email">
                                Email
                            </label>
                            <Field
                                required
                                data-cy="login-email-text-field"
                                label="Email"
                                name="email"
                                placeholder="oliver@example.com"
                                type="email"
                                className="w-full h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                            />
                            <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                        </div>
                        <Field name="password">
                            {({ field }) => (
                                <div className="relative w-full">
                                    <input
                                        {...field}
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        placeholder="******"
                                        className="w-full h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={() => setIsPasswordVisible(prev => !prev)}
                                    >
                                        {isPasswordVisible ? <EyeSlashIcon className="h-4 w-4 text-gray-500" /> : <EyeIcon className="h-4 w-4 text-gray-500" />}
                                    </div>
                                </div>
                            )}
                        </Field>
                        <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                        {formError && (
                            <div className="w-full text-center text-red-500 text-sm mb-4">
                                {formError}
                            </div>
                        )}
                        <Atom.Button
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="primary"
                            width="fullwidth"
                        >
                            Login
                        </Atom.Button>
                        <div className='flex gap-3 justify-center text-sm'>
                            <div>
                                <Link to="#" className='text-blue-500 font-semibold'>
                                    Forgot password?
                                </Link>
                            </div>
                            <div >
                                <Separator orientation="vertical" className='bg-gray-300' />
                            </div>
                            <div>
                                <Link to="/signup" className='text-gray-500'>
                                    Don't have an account? <span className='text-blue-500 underline font-semibold'>Sign Up</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default LoginForm;