import axios from "axios";

const BASE_URI = process.env.REACT_APP_BASE_URI;

export const fetchData = async (uri, method = "GET", body = null) => {
	try {
		const config = {
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
			data: body,
		};
		const response = await axios(BASE_URI + uri, config);
		if (response.status >= 200 && response.status < 300) {
			return response.data;
		} else {
			throw new Error(`Request failed with status ${response.status}`);
		}
	} catch (error) {
		// Handle any Axios or network errors here
		throw error;
	}
};

export const sendFiles = async (uri, file, options = {}) => {
	try {
		const formData = new FormData();
		formData.append("file", file);
		Object.entries(options).forEach(([key, value]) => {
			formData.append(key, value);
		});

		const response = await axios.post(BASE_URI + uri, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error:", error);
	}
};

export const sendFormData = async (uri, formData) => {
	try {
		const response = await axios.post(BASE_URI + uri, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error:", error);
	}
};
