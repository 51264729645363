import { MainLogo, Personiq } from "../../../common/assets/images";

const Logo = () => {
    return (
        <div className="flex gap-2 flex-shrink-0 items-center justify-center pr-4 py-3">
            <img
                alt="Personiq Logo"
                className="h-[1.5rem] md:h-[1.8rem] 2xl:h-[2.2rem]"
                src={MainLogo}
            />
            <img
                alt="Personiq"
                src={Personiq}
                className='h-[1.5rem] md:h-[1.8rem] 2xl:h-[2.2rem]'
            />
        </div>
    )
}

export default Logo;