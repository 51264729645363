import React, { useState, useEffect } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';
import { fetchData } from 'apis/APIWrapper';
import Organism from 'common/components/organisms';
import { useParams } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner';


const CustomerDetailsPage = () => {
    const { customerId } = useParams();
    const [customerDetails, setCustomerDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            const details = await fetchData(`/admin/list/customers/${customerId}`, 'GET');
            if (details) {
                setCustomerDetails(details);
            }
            setIsLoading(false);
        };

        loadData();
    }, [customerId]);


    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.AdminPanel titleText={customerDetails?.User}>
                    <Organism.BreadcrumbTrails
                        breadcrumbs={[
                            { href: "/admin/customers", text: "Customers" },
                            { text: customerDetails?.User }
                        ]}
                    />
                    <AdminOrganism.CustomerDetails customer={customerDetails?.Campaigns} customAvatars={customerDetails?.Avatars} />
                </Template.AdminPanel>
            }
        </>
    )

}

export default CustomerDetailsPage;