import Campaigns from "./Campaigns";
import Login from "./Login";
import SignUp from "./SignUp";
import CampaignSetup from "./CampaignSetup";
import Avatars from "./Avatars";
import AvatarSetup from "./AvatarSetup";
import CustomizeLanding from "./CustomizeLanding";
import AdminPanel from "./AdminPanel";

const Template = {
    Campaigns,
    Login,
    SignUp,
    CampaignSetup,
    Avatars,
    AvatarSetup,
    CustomizeLanding,
    AdminPanel,
}

export default Template;