import React, { useState, useEffect } from 'react'
import Template from '../../common/components/templates';
import AvatarOrganism from '../../common/components/organisms/avatars';
import { fetchData } from 'apis/APIWrapper';
import { getFromSessionStorage } from 'lib/storage';
import { ThreeCircles } from 'react-loader-spinner';
import Atom from "common/components/atoms";

const AvatarsPage = () => {

  const [openDialog, setOpenDialog] = useState(false);
  const [myAvatars, setMyAvatars] = useState([]);
  const [publicAvatars, setPublicAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const dummyPublicAvatars = [
  //   { id: '1', name: 'John', status: 'Trained', imageUrl: 'https://media.istockphoto.com/id/1275842510/photo/young-man-waving-with-hand-on-video-call-at-home-office.jpg?s=612x612&w=0&k=20&c=Jjnme5ARHRHRf6MN57HYK45ZLPIAAW4RxVOshBDMeIY=', createdOn: '10 Nov 23', },
  //   { id: 2, name: 'Ian', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1198252585/photo/happy-indian-woman-look-at-webcam-doing-job-interview-videochat.jpg?s=612x612&w=0&k=20&c=4xvWW9lNc_VKMdMx9WYR491-NEoNt9su5Ug5CSHdD64=', createdOn: '15 Dec 23', },
  //   { id: 3, name: 'Steve', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1319103417/photo/inspired-female-teacher-in-headphones-meet-students-online-give-class.jpg?s=612x612&w=0&k=20&c=cSGIQIwPFpVi7m4MR2_SLlwDcpBELdnNnLNgdtH6Bak=', createdOn: '15 Dec 23', },
  //   { id: 4, name: 'David', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1357265437/photo/young-smiling-woman-with-bluetooth-headphones-having-video-call-at-home.jpg?s=612x612&w=0&k=20&c=qvAcfXFfpo7uAARmuw8J20_n1CcsTvFClPkutKzx3RU=', createdOn: '15 Dec 23', },
  //   { id: 5, name: 'Chris', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1329932069/photo/man-doing-a-video-call-at-home-webcam-point-of-view.jpg?s=612x612&w=0&k=20&c=jIcEu4Vx0plLVQhPL2s60WpUTrjgbMFYrsxmD4R91Jk=', createdOn: '15 Dec 23', },
  //   { id: 6, name: 'Martin', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1203044192/photo/african-applicant-look-at-webcam-conference-calling-for-job-interview.jpg?s=612x612&w=0&k=20&c=MVmJPxhxl7EKqVidxJ4LblqTC02rzfqNkrRi-4PTC1M=', createdOn: '15 Dec 23', },
  //   { id: 7, name: 'Erling', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1295425402/photo/businesswoman-talking-during-video-call-in-the-office.jpg?s=612x612&w=0&k=20&c=ql2yfi5_sEpxWwasArETDOA9o3k1fP5SACj3e3G407o=', createdOn: '15 Dec 23', },
  //   { id: 8, name: 'Mathew', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1256299197/photo/the-busy-but-flexible-life-of-a-freelancer.jpg?s=612x612&w=0&k=20&c=1tfRytxf0k1ryQbnYx0zli0C86oUvO7sBLxe0zXJ1NU=', createdOn: '15 Dec 23', },
  //   { id: 9, name: 'Andrew', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1332741385/photo/man-doing-a-virtual-business-call-at-home-webcam-point-of-view.jpg?s=612x612&w=0&k=20&c=TvGnfwksyVcPYp0nv_q_4UAgRrug2RUl_3phKAzbOoo=', createdOn: '15 Dec 23', },
  //   { id: 10, name: 'Shane', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1176848710/photo/businessman-making-video-call-looking-at-camera-and-talking.jpg?s=612x612&w=0&k=20&c=AQDZDL--JSOBEXp-_pAhLcMVfxQ6F5bpQlBXqdl_ks4=', createdOn: '15 Dec 23', },
  //   { id: 11, name: 'Michael', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1164377489/photo/happy-old-mature-online-teacher-tutor-looking-at-camera.jpg?s=612x612&w=0&k=20&c=M_2Pz2cBOztuPyqKTuqe4ANMoo7s0t3pjY2fjak6z3Y=', createdOn: '15 Dec 23', },
  //   { id: 12, name: 'Kane', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1277612580/photo/headshot-of-young-woman-have-video-call-at-home.jpg?s=612x612&w=0&k=20&c=GKuzbutPbjIwSJKZJHepmRIvlbFotOriNSJNhA3L5fY=', createdOn: '15 Dec 23', },
  //   { id: 13, name: 'Kobe', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1299493619/photo/smiling-senior-woman-talking-on-video-call-at-home.jpg?s=612x612&w=0&k=20&c=8XUyszMrv-7M5IxmJGBC3_oimwJ1IPHENVi3ZkjFoRo=', createdOn: '15 Dec 23', },
  // ];

  // useEffect(() => {
  //   const loadData = async () => {
  //     const Avatars = await fetchData("/avatar/private", 'POST', { created_by_company: getFromSessionStorage("company") })
  //     if (Avatars) {
  //       setMyAvatars(Avatars);
  //       setPublicAvatars(Avatars)
  //     }
  //   };
  //   loadData();
  // }, []);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const Avatars = await fetchData("/avatar/private", 'POST', { created_by_company: getFromSessionStorage("company") });
      if (Avatars) {
        setIsLoading(false);
        const privateAvatars = Avatars.filter(avatar => avatar.type === 'Private');
        const publicAvatars = Avatars.filter(avatar => avatar.type === 'Public');
        setMyAvatars(privateAvatars);
        setPublicAvatars(publicAvatars);
      }
    };

    loadData();
  }, []);


  return (
    <>
      <Atom.Visibility state={isLoading}>
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
          <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#e6a6e0"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </Atom.Visibility>
      <Template.Avatars handleOpenDialog={handleOpenDialog}>
        <AvatarOrganism.AvatarsList
          publicAvatars={publicAvatars}
          myAvatars={myAvatars}
          setMyAvatars={setMyAvatars}
          handleOpenDialog={handleOpenDialog}
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </Template.Avatars>
    </>
  )
}

export default AvatarsPage