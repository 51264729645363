import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../../../../components/ui/label"
import { RadioGroup, RadioGroupItem } from "../../../../components/ui/radio-group"
import AppContext from '../../../context/AppContext';
import { Separator } from '../../../../components/ui/separator';
import Organism from '..';
import Atom from '../../atoms';
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { sendFormData, fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { removeDuplicateVariables } from 'lib/storage';


const CustomizeThumbnail = ({ isEditing, returnPath }) => {
  const navigate = useNavigate();
  const { thumbnailData, setThumbnailData, selectedVariables, setSelectedVariables, variablesWithoutDuplicates, setVariablesWithoutDuplicates } = useContext(AppContext);
  const [textPosition, setTextPosition] = useState({ bottom: "0px" });
  const [text, setText] = useState("Hello, How are you doing");
  const [showText, setShowText] = useState(true);
  const { campaignId, step } = useParams()
  const [isUploading, setIsUploading] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const [imageUrl, setImageUrl] = useState("");

  const buttonText = isEditing ? 'Save changes' : 'Continue';

  useEffect(() => {
    const fetchThumbnailData = async () => {
      setIsUploading(true);
      try {
        const response = await fetchData(`/campaigns/landingPage/data/${campaignId}`);
        if (response) {
          setImageUrl(response.thumbnail.thumbnail_url);
          // Remove duplicates if exist
          setVariablesWithoutDuplicates(removeDuplicateVariables(response.variables));

          const imageResponse = await fetch(response.thumbnail.thumbnail_url);
          const imageBlob = await imageResponse.blob();
          const file = new File([imageBlob], 'thumbnail.jpg', { type: imageBlob.type });

          setThumbnailData((prevState) => ({ ...prevState, image: file }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsUploading(false);
      }
    };
    fetchThumbnailData();
  }, [campaignId]);

  const rtaRef = useRef(null);
  const textareaRef = useRef(null);

  const Loading = ({ data }) => <div>Loading..</div>;

  const fileUploadProp = {
    border: "solid",
    borderRadius: "100px",
    acceptType: "image/*",
    onChange: (event) => {
      if (event.target.files !== null && event.target?.files?.length > 0) {
        const file = event.target.files[0];
        setThumbnailData((prevState) => ({ ...prevState, image: file }));
        if (file) {
          const imageURL = URL.createObjectURL(file);
          setImageUrl(imageURL);
        }
      }
    },
    onDrop: (event) => {
      console.log(`Drop ${event.dataTransfer.files[0].name}`);
    },
  };

  const handleTextPosition = (value) => {
    if (value === "bottom") {
      setShowText(true);
      setTextPosition({ bottom: "0px" });
      setThumbnailData((prevState) => ({
        ...prevState,
        textPosition: "bottom",
      }));
    } else if (value === "top") {
      setShowText(true);
      setTextPosition({ top: "0px" });
      setThumbnailData((prevState) => ({
        ...prevState,
        textPosition: "top",
      }));
    } else {
      setShowText((prevState) => !prevState);
    }
  };

  const triggerTextChange = (event) => {
    setText(event.target.value);
    setCharCount(event.target.value.length);
    setThumbnailData((prevState) => ({
      ...prevState,
      text: event.target.value,
    }));
  };

  const handleContinue = async () => {
    setIsUploading(true)
    const formData = new FormData();
    formData.append("thumbnail", thumbnailData.image);
    // formData.append("show_text", showText);
    if(showText) {
      formData.append("text", text);
      formData.append("text_location", Object.keys(textPosition)[0]);
    }
    const response = await sendFormData(`/campaigns/add/thumbnail/${campaignId}/${parseInt(step) + 1}`, formData)
    if (response) {
      setIsUploading(false);
      if (isEditing) {
        navigate(returnPath || `/campaigns`);
      } else {
        navigate(`/campaigns/create/landing-page/${campaignId}/${parseInt(step) + 1}`);
      }
    }
  }


  return (
    <ScrollArea>
      {isUploading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#e6a6e0"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

      )}
      <div className='flex flex-col justify-center gap-10 overflow-auto mx-auto lg:w-[80%] 2xl:w-[80%] px-16 py-10 my-auto 2xl:my-[6%] bg-[#f8f8f8] rounded-2xl'>
        <h2 className='text-2xl font-bold text-center'>Customize Thumbnail</h2>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div className='my-auto'>
            <div className='rounded-3xl relative ' style={{ aspectRatio: '16 / 9' }}>
              <img src={imageUrl} alt="Description" className='object-cover rounded-3xl w-full h-full' />
              {showText && (
                <div className={`absolute left-0 right-0 bg-[#18084B] text-[#DAFC09] px-3 py-2 md:text-sm 2xl:text-lg text-center ${textPosition.bottom ? 'rounded-b-3xl' : textPosition.top ? 'rounded-t-3xl' : ''}`} style={textPosition}>
                  {text}
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col px-4'>
            <div className='flex flex-col gap-2'>
              <Label className="text-lg font-semibold">Change Thumbnail Image</Label>
              <Organism.UploadButtons {...fileUploadProp} />
              <p className='text-[#9B9B9B] text-sm font-medium'>Recommended dimension: 800x400px</p>
            </div>
            <Separator className="my-4" />
            <div className='flex flex-col gap-2'>
              <Label className="text-lg font-semibold">Dynamic Text Position</Label>
              <RadioGroup defaultValue="bottom" onValueChange={handleTextPosition} className="flex gap-20">
                <div className="flex gap-1">
                  <RadioGroupItem value="top" id="r1" />
                  <Label htmlFor="r1">Top</Label>
                </div>
                <div className="flex gap-1">
                  <RadioGroupItem value="bottom" id="r3" />
                  <Label htmlFor="r3">Bottom</Label>
                </div>
                <div className="flex gap-1">
                  <RadioGroupItem value="none" id="r3" />
                  <Label htmlFor="r3">None</Label>
                </div>
              </RadioGroup>
            </div>
            <Separator className="my-4" />
            <div className='relative flex flex-col gap-2'>
              <Label className="text-lg font-semibold">Dynamic Content</Label>
              {/* <Organism.TextAreaWithSuggestion
                sx={{ maxHeight: "200px", flexGrow: 0, }}
                text="Add your sub heading text here..."
                list={variables}
                triggerTextChange={triggerTextChange}
                totalWords={60}
              /> */}
              <ReactTextareaAutocomplete
                className=" flex w-[100%] xl:w-full rounded-3xl file:border-0 file:bg-transparent file:text-sm bg-background text-sm ring-offset-background placeholder:text-muted-foreground text-gray-600 focus-visible:outline-none border border-gray-300 hover:border-gray-500 focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out disabled:cursor-not-allowed disabled:opacity-50 py-2 px-6 resize-none min-h-[6vh] xl:min-h-[13vh] 2xl:min-h-[8vh]"
                loadingComponent={Loading}
                style={{ maxHeight: "200px", flexGrow: 0, }}
                ref={rta => rtaRef.current = rta}
                innerRef={(textarea) => {
                  textareaRef.current = textarea;
                  if (textareaRef.current) {
                    textareaRef.current.maxLength = 60;
                  }
                }}
                containerStyle={{}}
                dropdownStyle={{ position: 'absolute', zIndex: 1000, top: '100%', left: 0, backgroundColor: '#efefef', borderRadius: '1rem', minWidth: 160, width: 190, padding: "10px", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                minChar={0}
                trigger={{
                  "@": {
                    dataProvider: () => variablesWithoutDuplicates.slice(0, 10).map(v => ({ value: v.variable })),
                    component: ({ entity, selected }) => <div className='rounded-2xl text-sm p-1 text-gray-700 hover:bg-violet-100 hover:text-violet-600'>{`${entity.value}`}</div>,
                    output: (item, trigger) => {
                      if (typeof item === 'string') {
                        return item;
                      } else if (typeof item === 'object' && item !== null) {
                        // return "@" + item.value;
                        return `@${item.value}`;
                      }
                    },
                  }
                }}
                // value={textAreas.titleText.value}
                onChange={triggerTextChange}
                placeholder="Enter your dynamic thumbnail content here.."
              />
              <p className="text-right text-xs text-gray-500">
                Characters: {charCount}/60
              </p>
              <p className='text-xs text-center text-gray-500 px-2'>Type @ to open a dropdown menu and select variables to insert into your title text</p>
              {/* <Organism.Tags
                sx={{ maxHeight: "200px", flexGrow: 0, }}
                textList={selectedVariables}
              /> */}
            </div>
          </div>
        </div>
        <Atom.Button type="primary" className="mx-auto sm:w-[35%] md:w-[20%] xl:w-[20%] 2xl:w-[15%]" onClick={handleContinue}>{buttonText}</Atom.Button>
      </div>
    </ScrollArea>
  )
}

export default CustomizeThumbnail;