import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';
import { fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';
import { NOT_FOUND_PAGE_PATH } from 'routes/routeConstants';

const CampaignSummaryPage = () => {
    const [campaignData, setCampaignData] = useState({});
    const { campaignId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCampaignData();
    }, [campaignId]);

    const fetchCampaignData = async () => {
        try {
            const response = await fetchData(`/summary/campaign/${campaignId}`);
            if (response && response.campaign_name) {
                setCampaignData(response);
            } else {
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            }
        } catch (error) {
            console.error("Error fetching campaign data:", error);
            navigate(NOT_FOUND_PAGE_PATH);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.CampaignSetup titleText={campaignData.campaign_name}>
                    <CampaignsOrganism.CampaignSummary campaignData={campaignData} isLoading={isLoading} setIsLoading={setIsLoading} />
                </Template.CampaignSetup>
            }
        </>
    )
}

export default CampaignSummaryPage;