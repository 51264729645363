import React, { useState, useEffect } from 'react';
import Template from '../../common/components/templates';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import { fetchData } from "../../apis/APIWrapper";
import { getFromSessionStorage } from 'lib/storage';
import { ThreeCircles } from 'react-loader-spinner';
import Atom from "common/components/atoms";


const CampaignsPage = () => {

   const [openDialog, setOpenDialog] = useState(false);
   const [campaigns, setCampaigns] = useState();
   const [isLoading, setIsLoading] = useState(false);


   const handleOpenDialog = () => {
      setOpenDialog(true);
   };

   const handleCloseDialog = () => {
      setOpenDialog(false);
   };

   useEffect(() => {
      if (!window.sessionStorage.length) {
         localStorage.clear();
         window.location.reload();
      }
   }, []);

   useEffect(() => {
      const fetchCampaignData = async () => {
         setIsLoading(true);
         try {
            const response = await fetchData('/campaigns', 'POST', { "created_by_company": getFromSessionStorage("company") });
            if (response) {
               setCampaigns(response);
            }
         } catch (error) {
            console.error('Error fetching campaigns:', error);
         } finally {
            setIsLoading(false);
         }
      };
      fetchCampaignData();
   }, []);


   return (
      <>
         <Atom.Visibility state={isLoading}>
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
               <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#e6a6e0"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
               />
            </div>
         </Atom.Visibility>
         {campaigns && (
            <Template.Campaigns handleOpenDialog={handleOpenDialog}>
               <CampaignsOrganism.CampaignsList
                  campaigns={campaigns}
                  setCampaigns={setCampaigns}
                  handleOpenDialog={handleOpenDialog}
                  handleCloseDialog={handleCloseDialog}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
               />
            </Template.Campaigns>
         )}
      </>
   );

};

export default CampaignsPage;
