import React from 'react';
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import Atom from '../../atoms';

const SignUpForm = ({
   initialValues,
   validationSchema,
   onSubmit,
   onCheckboxChange,
   isChecked,
   formError,
}) => {
   return (
      <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
      >
         {({ isSubmitting }) => (
            <Form >
               <div className="flex flex-col w-full h-full mt-0 space-y-4 2xl:space-y-5 rounded-md px-8 pt-3 pb-5 2xl:p-8 items-center">
                  <h2 className="text-2xl md:text-3xl font-semibold text-gray-950">Sign Up</h2>
                  <div className="flex flex-col space-y-1 w-full">
                     <label className="block text-gray-500 text-sm 2xl:text-base font-semibold" htmlFor="name">
                        Name
                     </label>
                     <Field
                        className="w-full h-8 2xl:h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                        id="name"
                        type="text"
                        placeholder="Name *"
                        name="name"
                     />
                     <ErrorMessage name="name" component="div" className="text-red-500 text-xs" />
                  </div>
                  <div className="flex flex-col space-y-1 w-full">
                     <label className="block text-gray-500 text-sm 2xl:text-base font-semibold" htmlFor="name">
                        Email
                     </label>
                     <Field
                        className="w-full h-8 2xl:h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                        id="email"
                        type="text"
                        placeholder="Email *"
                        name="email"
                     />
                     <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                  </div>
                  <div className="flex flex-col space-y-1 w-full">
                     <label className="block text-gray-500 text-sm 2xl:text-base font-semibold" htmlFor="name">
                        Company Name
                     </label>
                     <Field
                        className="w-full h-8 2xl:h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                        id="companyName"
                        type="text"
                        placeholder="Company Name *"
                        name="companyName"
                     />
                     <ErrorMessage name="companyName" component="div" className="text-red-500 text-xs" />
                  </div>
                  <div className="flex flex-col space-y-1 w-full">
                     <label className="block text-gray-500 text-sm 2xl:text-base font-semibold" htmlFor="name">
                        Password
                     </label>
                     <Field
                        className="w-full h-8 2xl:h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                        id="password"
                        type="password"
                        placeholder="Password *"
                        name="password"
                     />
                     <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                  </div>
                  <div className="flex flex-col space-y-1 w-full">
                     <label className="block text-gray-500 text-sm 2xl:text-base font-semibold" htmlFor="name">
                        Confirm Password
                     </label>
                     <Field
                        className="w-full h-8 2xl:h-10 px-3 border border-gray-300 hover:border-gray-500 rounded-lg focus:outline-none focus:border-[#612FFF] transition duration-300 ease-in-out"
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirm Password *"
                        name="confirmPassword"
                     />
                     <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs" />
                  </div>
                  <div className="flex justify-start w-full items-center space-x-2">
                     <Atom.Checkbox checked={isChecked} onChange={onCheckboxChange} />
                     <span className="text-gray-600 font-medium">
                        I agree to the <span className="text-blue-500 underline">Terms of Service</span>
                     </span>
                  </div>
                  {formError && (
                     <div className="w-full text-center text-red-500 text-sm mb-4">
                        {formError}
                     </div>
                  )}
                  <Atom.Button
                     disabled={isSubmitting || !isChecked}
                     loading={isSubmitting}
                     type="primary"
                     width="fullwidth"
                  >
                     Sign Up
                  </Atom.Button>
                  <div className='flex gap-3 justify-center text-sm'>
                     <div>
                        <Link to="/login" className='text-gray-500'>
                           Already have an account? <span className='text-blue-500 underline font-semibold'>Login</span>
                        </Link>
                     </div>
                  </div>
               </div>
            </Form>
         )}
      </Formik>
   )
}

export default SignUpForm;