import CampaignsDefault from "./CampaignsDefault";
import CampaignsList from "./CampaignsList";
import CreateNewCampaign from "./CreateNewCampaign";
import CampaignSetup from "./CampaignSetup";
import ChooseAvatar from "./ChooseAvatar";
import PrepareScript from "./PrepareScript";
import RecordVideo from "./RecordVideo";
import VariablesAssign from "./VariablesAssign";
import VariableDropdown from "./VariableDropdown";
import CustomizeThumbnail from "./CustomizeThumbnail";
import CustomizeLanding from "./CustomizeLanding";
import CampaignSummary from "./CampaignSummary";
import SummaryTable from "./SummaryTable";

const CampaignsOrganism = {
    CampaignsDefault,
    CampaignsList,
    CreateNewCampaign,
    CampaignSetup,
    ChooseAvatar,
    PrepareScript,
    RecordVideo,
    VariablesAssign,
    VariableDropdown,
    CustomizeThumbnail,
    CustomizeLanding,
    CampaignSummary,
    SummaryTable,
}

export default CampaignsOrganism;