import React from 'react'

const CampaignsIcon = ({ isIconActive }) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8808 4.34601C14.136 5.44428 11.2061 6.00576 8.24978 6.00001H7.49978C6.18252 5.99811 4.91267 6.49146 3.9422 7.38218C2.97174 8.27291 2.37157 9.4959 2.2608 10.8085C2.15002 12.1211 2.53672 13.4274 3.34418 14.4681C4.15163 15.5089 5.32083 16.2081 6.61978 16.427C6.9508 17.7818 7.41229 19.1013 7.99778 20.367C8.46178 21.371 9.67178 21.687 10.5798 21.163L11.2368 20.784C12.1168 20.276 12.4018 19.192 12.0088 18.316C11.7726 17.7912 11.563 17.2549 11.3808 16.709C13.2988 16.967 15.1408 17.459 16.8808 18.155C17.6243 15.9289 18.0022 13.597 17.9998 11.25C17.9998 8.83701 17.6068 6.51501 16.8808 4.34601ZM18.2598 3.74001C19.0834 6.1581 19.5024 8.69551 19.4998 11.25C19.5024 13.8045 19.0834 16.3419 18.2598 18.76C18.2048 18.921 18.1488 19.082 18.0898 19.242C18.0214 19.4289 18.03 19.6352 18.1137 19.8157C18.1974 19.9962 18.3494 20.1361 18.5363 20.2045C18.7231 20.2729 18.9295 20.2643 19.11 20.1806C19.2905 20.0969 19.4304 19.9449 19.4988 19.758C20.257 17.6879 20.7329 15.5251 20.9138 13.328C21.451 12.7697 21.7507 12.0248 21.7498 11.25C21.7498 10.444 21.4308 9.71001 20.9138 9.17201C20.7318 6.97505 20.2558 4.81243 19.4988 2.74201C19.4649 2.6495 19.4131 2.56456 19.3464 2.49205C19.2797 2.41954 19.1994 2.36088 19.11 2.31942C18.9295 2.23569 18.7231 2.22709 18.5363 2.29551C18.3494 2.36394 18.1974 2.50379 18.1137 2.68429C18.03 2.86479 18.0214 3.07117 18.0898 3.25801C18.1488 3.41801 18.2058 3.57801 18.2598 3.74001Z" stroke={isIconActive ? "#612FFF" : "#444444"} fill={isIconActive ? "#612FFF" : "none"} />
        </svg>

    )
}

export default CampaignsIcon;
