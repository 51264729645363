import React from 'react'
import AvatarOrganism from '../../common/components/organisms/avatars';
import Template from '../../common/components/templates';


const VideoTrainingStartPage = () => {
    return (
        <Template.AvatarSetup>
            <AvatarOrganism.VideoTrainingStart />
        </Template.AvatarSetup>
    )
}

export default VideoTrainingStartPage;