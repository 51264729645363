import React, { useState, useEffect } from "react";
import Atom from "../../atoms";
import Molecule from "../../molecules";
import Organism from "../../organisms";

const CampaignDetails = ({ campaign }) => {
    console.log(campaign);
    const [columns, setColumns] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const totalPages = Math.ceil(campaign.details?.length / itemsPerPage);

    const getStartingIndex = () => (currentPage - 1) * itemsPerPage;

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const DataCell = ({ requiredData }) => {
        return (
            <div className="flex justify-center items-center">
                {requiredData}
            </div>
        );
    };

    const getDataSource = (campaignDetails, variables) => {
        return campaignDetails
            ?.slice(getStartingIndex(), getStartingIndex() + itemsPerPage)
            .map((campaignDetail) => {
                const dynamicVariables = variables.reduce((acc, { variable }) => {
                    acc[variable] = <DataCell requiredData={campaignDetail?.[variable]} />;
                    return acc;
                }, {});

                return {
                    key: campaignDetail.id,
                    email: <DataCell requiredData={campaignDetail?.email} />,
                    status: <DataCell requiredData={campaignDetail?.status} />,
                    ...dynamicVariables,
                    videoUrl: <DataCell requiredData={campaignDetail?.videoUrl} />,
                    landingPageUrl: <DataCell requiredData={campaignDetail?.landingPageUrl} />,
                    thumbnailUrl: <DataCell requiredData={campaignDetail?.thumbnailUrl} />,
                };
            });
    };

    const dataSource = getDataSource(campaign.details, campaign.variables);

    console.log(dataSource);

    useEffect(() => {
        const fixedColumnsStart = [
            { title: "EMAIL", dataIndex: "email" },
            { title: "STATUS", dataIndex: "status" },
        ];

        const variableColumns = campaign.variables?.map(variable => ({
            title: variable.variable.toUpperCase().replace(/_/g, ' '),
            dataIndex: variable.variable,
            key: variable.variable
        }));

        const fixedColumnsEnd = [
            { title: "VIDEO URL", dataIndex: "videoUrl" },
            { title: "LANDING PAGE URL", dataIndex: "landingPageUrl" },
            { title: "THUMBNAIL URL", dataIndex: "thumbnailUrl" }
        ];

        const updatedColumns = [...fixedColumnsStart, ...variableColumns, ...fixedColumnsEnd];

        setColumns(updatedColumns);
        setIsTableVisible(true);
    }, [campaign]);

    return (
        <Organism.Content className="px-5 gap-4 2xl:gap-6">
            <h1 className="text-2xl font-semibold">{campaign?.campaignName}</h1>
            <Atom.Visibility state={campaign?.details.length !== 0}>
                <Molecule.ContentTable className="px-0">
                    <Atom.Visibility state={isTableVisible}>
                        <Organism.Table dataSource={dataSource} columns={columns} type="campaignDetails" />
                    </Atom.Visibility>
                </Molecule.ContentTable>
                <Molecule.ContentPagination>
                    <Organism.CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Molecule.ContentPagination>
            </Atom.Visibility>
        </Organism.Content>
    );
};

export default CampaignDetails;
