const setToSessionStorage = (key, value) => {
    if (value !== null) {
        sessionStorage.setItem(key, JSON.stringify(value));
    } else sessionStorage.removeItem(key);
};

const getFromSessionStorage = key => {
    const value = sessionStorage.getItem(key);
    const response = value ? JSON.parse(value) : "";

    return response;
};

const clearSessionStorageCredentials = () => {
    setToSessionStorage("authId", null);
    setToSessionStorage("authToken", null);
    setToSessionStorage("name", null);
};

const removeDuplicateVariables = (variables) => {
    const uniqueVariables = variables.reduce((acc, current) => {
        const x = acc.find(item => item.variable === current.variable);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
    return uniqueVariables;
};

export { setToSessionStorage, getFromSessionStorage, clearSessionStorageCredentials, removeDuplicateVariables };
