import React from "react";
import { Button } from "../../../components/ui/button"
import { cn } from "../../../lib/utils";

const CustomButton = ({ type, width, onClick, children, weight = "default", disabled = false, className, style }) => {
  return (
    <Button
      // type={type === "submit" ? "submit" : "button"}
      className={cn({
        "rounded-3xl grow md:grow-0 text-center border border-[#612FFF] bg-[#612FFF] hover:bg-[#612FFF]/90 text-sm font-semibold text-white active:shadow-2xl active:scale-105 transition-all duration-300": type === "primary" && weight === "default",
        "rounded-3xl grow md:grow-0 text-center border border-[#612FFF] bg-[#612FFF] hover:bg-[#612FFF]/90 text-sm font-semibold text-white w-full active:shadow-2xl active:scale-105 transition-all duration-300": type === "primary" && width === "fullwidth" && weight === "default",
        // "rounded-3xl grow md:grow-0 text-center border border-[#612FFF] bg-[#612FFF] hover:bg-[#612FFF]/90 text-sm font-semibold text-white w-[30%]": type === "primary" && width === "30%" && weight === "default",
        "rounded-3xl grow md:grow-0 text-center border border-[#E8E6ED] bg-[#E8E6ED] hover:bg-[#E8E6ED]/90 text-sm font-semibold text-[#612FFF] w-full active:shadow-2xl active:scale-105 transition-all duration-300": type === "secondary" && width === "fullwidth" && weight === "default",
        "rounded-lg grow md:grow-0 text-center border border-[#1674f8] bg-[#1674f8] text-white active:shadow-2xl active:scale-105 transition-all duration-300": type === "primary" && weight === "large",
        "rounded-3xl grow md:grow-0 text-center border border-[#E8E6ED] bg-[#E8E6ED] hover:bg-[#E8E6ED]/90 text-sm font-semibold text-[#612FFF] active:shadow-2xl active:scale-105 transition-all duration-300": type === "secondary" && weight === "default",
        "rounded-3xl grow md:grow-0 text-center border border-[#612FFF] bg-transparent hover:bg-violet-100/90 text-sm font-semibold text-[#612FFF] active:shadow-2xl active:scale-105 transition-all duration-300": type === "outline" && weight === "default",
        "group flex flex-shrink-0 items-center justify-start rounded-3xl px-5 py-[.6rem] text-lg font-semibold text-gray-600 bg-[#F3F3F6] hover:bg-[#F3F3F6]/90 hover:text-gray-900 w-full": type === "sidebar",
        "border-none bg-transparent text-[#612FFF] hover:text-[#612FFF]/90 underline hover:bg-transparent": type === "skull",
        "rounded-full grow md:grow-0 text-center border-none bg-[#612FFF] hover:bg-[#612FFF]/90 text-sm font-semibold text-white": type === "icon" && weight === "default",
        "rounded-full text-center p-5" : type === "round" && weight === "default",
        // "rounded-3xl grow md:grow-0 text-center border border-[#612FFF] bg-[#612FFF] hover:bg-[#612FFF]/90 text-sm font-semibold text-white w-full": type === "submit" && weight === "default",
      },
        className
      )}
      style={style}
      size={weight}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default CustomButton;



