import React from 'react';
import Sidebar from './common/components/organisms/Sidebar/Sidebar';

const Layout = ({ children }) => {
    return (
        <div className="">
            <div className="flex-col inset-y-0">
                <Sidebar />
            </div>
            <div className="md:pl-60 h-[100vh]">
                {children}
            </div>
        </div>
    );
};

export default Layout;


