import React from 'react';

const Loader = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-blue-500 rounded-full"></div>
        </div>
    );
};

export default Loader;
