import React, { createContext, useState } from 'react';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
   const [variables, setVariables] = useState([{
      index: "1",
      value: "first_name",
   },
   {
      index: "2",
      value: "company_name",
   },
   ]);
   const [selectedVariables, setSelectedVariables] = useState([]);
   const [variablesWithoutDuplicates, setVariablesWithoutDuplicates] = useState([]);
   const [videoUrl, setVideoUrl] = useState("");
   const [landingPageUrl, setLandingPageUrl] = useState("landingpageurl.com");
   const [transcription, setTranscription] = useState("");
   const [thumbnailData, setThumbnailData] = useState({
      textPosition: "bottom",
      text: "",
   });
   const [landingPageData, setLandingPageData] = useState({
      titleText: "",
      descriptionText: "",
   });
   const [avatarId, setAvatarId] = useState()
   const [script, setScript] = useState()

   const currentUser = {
      role: "User"
   }

   const [avatarTrainingStep, setAvatarTrainingStep] = useState(0);


   return (
      <AppContext.Provider value={{
         thumbnailData,
         setThumbnailData,
         videoUrl,
         setVideoUrl,
         landingPageUrl,
         setLandingPageUrl,
         variables,
         setVariables,
         selectedVariables,
         setSelectedVariables,
         landingPageData,
         setLandingPageData,
         transcription,
         setTranscription,
         avatarId,
         setAvatarId,
         script,
         setScript,
         currentUser,
         variablesWithoutDuplicates,
         setVariablesWithoutDuplicates,
         avatarTrainingStep,
         setAvatarTrainingStep,
      }}>
         {children}
      </AppContext.Provider>
   );
};

export default AppContext;
