import React, { useState, useEffect } from "react";
import Atom from "../../atoms";
import Molecule from "../../molecules";
import Organism from "../../organisms";

import { isBreakpoint, createColumnsForTable } from "../../../../lib/index";
import { ScrollArea } from "components/ui/scroll-area";

const Customers = ({
	customers,
}) => {
	const [columns, setColumns] = useState([]);
	const [isTableVisible, setIsTableVisible] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;
	const totalPages = Math.ceil(customers?.length / itemsPerPage);

	const getStartingIndex = () => (currentPage - 1) * itemsPerPage;

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
		// Here we can also fetch new data based on the new page if the data is fetched from an API
	};

	const DataCell = ({ requiredData }) => {
		return (
			<div className="flex justify-center items-center px-2">
				{requiredData}
			</div>
		);
	};

	const DateCell = ({ requiredData }) => {
		return (
			<div className="flex justify-center items-center">
				{formatDate(requiredData)}
			</div>
		);
	};

	const dataSource = customers
		?.slice(getStartingIndex(), getStartingIndex() + itemsPerPage)
		.map((customer, index) => ({
			key: customer.id,
			customerName: <DataCell requiredData={customer?.name} />,
			email: <DataCell requiredData={customer?.email} />,
			signUpDate: <DateCell requiredData={customer?.signup_date} />,
			billingPlan: <DataCell requiredData={customer?.billing_plan} />,
			lastBillingDate: <DateCell requiredData={customer?.last_billing_date} />,
			lastActivityDate: <DateCell requiredData={customer?.last_activity_date} />,
			noOfAvatars: <DataCell requiredData={customer?.no_of_avatars} />,
			noOfCampaigns: <DataCell requiredData={customer?.no_of_campaigns} />,
			noOfContacts: <DataCell requiredData={customer?.no_of_contacts} />,
			videosGenerated: <DataCell requiredData={customer?.videos_generated} />,

		}));

	console.log(dataSource);

	useEffect(() => {
		let adjustedColumn = createColumnsForTable(["CUSTOMER NAME", "BILLING PLAN"]);

		if (isBreakpoint("md")) {
			adjustedColumn = createColumnsForTable(["CUSTOMER NAME", "NO. OF CAMPAIGNS", "BILLING PLAN"]);
		}

		if (isBreakpoint("lg")) {
			adjustedColumn = createColumnsForTable([
				"CUSTOMER NAME",
				"EMAIL",
				"SIGNUP DATE",
				"BILLING PLAN",
				"LAST BILLING DATE",
				"LAST ACTIVITY DATE",
				"NO. OF AVATARS",
				"NO. OF CAMPAIGNS",
				"NO. OF CONTACTS",
				"VIDEOS GENERATED",

			]);
			adjustedColumn.push({
				title: "",
				dataIndex: "edit",
				key: "edit",
			});
		}
		setColumns(adjustedColumn);
		setIsTableVisible(true);
	}, []);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		const formattedDay = day < 10 ? "0" + day : day;
		const formattedMonth = month < 10 ? "0" + month : month;

		return `${formattedDay}-${formattedMonth}-${year}`;
	};

	return (
		<ScrollArea>
			<Organism.Content className="px-5 py-10">
				<Atom.Visibility state={customers?.length !== 0}>
					<Molecule.ContentTable className="flex-none border px-0 rounded-xl">
						<Atom.Visibility state={isTableVisible}>
							<Organism.Table dataSource={dataSource} columns={columns} type="customers" />
						</Atom.Visibility>
					</Molecule.ContentTable>
					{/* <Molecule.ContentPagination>
						<Organism.CustomPagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Molecule.ContentPagination> */}
				</Atom.Visibility>
				<Atom.Visibility state={customers?.length === 0}>
					<div>No Customers currently</div>
				</Atom.Visibility>
			</Organism.Content>
		</ScrollArea>
	);
};

export default Customers;