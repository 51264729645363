import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import Atom from '../../atoms';
import { Input } from '../../../../components/ui/input';
import { Label } from '../../../../components/ui/label';
import { CardBody, CardContainer, CardItem } from "../../../../components/ui/3d-card";
import { cn } from "../../../../lib/utils";


const ApiCards = ({ logo, header, subHeader, keyLabel, inputValue, isEditing, onEdit, onSave, onChange, logoClassName, additionalInputs = [] }) => {
    const maskValue = (value) => {
        if (value.length <= 3) return value;
        return '*'.repeat(value.length - 3) + value.slice(-3);
    };

    return (
        <CardContainer className="inter-var">
            <CardBody className="bg-violet-100 flex flex-col gap-4 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] rounded-xl p-6 border xl:w-60 xl:h-60 2xl:w-64 2xl:h-64">
                <div className='flex justify-between'>
                    <div>
                        <CardItem
                            translateZ="50"
                            className="text-xl font-bold text-neutral-600 dark:text-white"
                        >
                            {header}
                        </CardItem>
                        <CardItem
                            as="p"
                            translateZ="60"
                            className="text-neutral-500 text-xs max-w-sm mt-2 dark:text-neutral-300"
                        >
                            {subHeader}
                        </CardItem>
                    </div>
                    <div>
                        <CardItem translateZ="100" className="w-full">
                            <img
                                src={logo}
                                className={cn('w-14 h-14 rounded-xl group-hover/card:shadow-xl', logoClassName)}
                                alt="thumbnail"
                            />
                        </CardItem>
                    </div>

                </div>
                <div className='flex flex-col gap-2 text-sm'>
                    <CardItem>
                        {isEditing ? (
                            <>
                                <CardItem>
                                    <Input
                                        value={inputValue}
                                        onChange={(e) => onChange(e.target.value)}
                                    />
                                </CardItem>
                                {additionalInputs.map((input, index) => (
                                    <CardItem key={index} className="mt-1">
                                        <Input
                                            value={input.value}
                                            onChange={(e) => input.onChange(e.target.value)}
                                            placeholder={input.placeholder}
                                        />
                                    </CardItem>
                                ))}
                            </>
                        ) : (
                            <CardItem
                                className="px-2 py-2 rounded-xl text-xs font-normal"
                            >
                                {/* {keyValue} */}
                                {/* {inputValue} */}
                                {maskValue(inputValue)}
                            </CardItem>
                        )}

                    </CardItem>
                    <div className='flex justify-between items-center'>
                        <Label>{keyLabel}</Label>
                        <CardItem translateX={10}>
                            {isEditing ? (
                                <Atom.Button className="p-0 text-sm" type="skull" onClick={onSave}>Save</Atom.Button>
                            ) : (
                                <Atom.Button className="p-0 text-sm" type="skull" onClick={onEdit}>Edit →</Atom.Button>
                            )}
                        </CardItem>
                    </div>
                </div>
            </CardBody>
        </CardContainer>
    );
};

export default ApiCards;

