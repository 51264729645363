import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Organism from '../../organisms';
import { fetchData } from "../../../../apis/APIWrapper";
import AppContext from 'common/context/AppContext';
import { getFromSessionStorage } from 'lib/storage';

const CreateNewCampaign = ({
    openDialog,
    setOpenDialog,
    handleCloseDialog,

}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [avatarName, setAvatarName] = useState("");
    const [avatarNameError, setAvatarNameError] = useState("");
    const { setAvatarId, setAvatarTrainingStep } = useContext(AppContext);
    const navigate = useNavigate();
    const [charCount, setCharCount] = useState(avatarName.length || 0);
    const maxCharLimit = 15;

    useEffect(() => {
        if (!openDialog) {
            setCharCount(0);
        }
    }, [openDialog]);


    const handleNameChange = (event) => {
        setAvatarName(event.target.value);
        if (avatarNameError) {
            setAvatarNameError("");
        }
    };

    const handleCreateAvatar = async (event) => {
        event.preventDefault();
        let isError = false;
        if (!avatarName.trim()) {
            setAvatarNameError("Avatar name cannot be empty");
            isError = true;
        } else {
            setAvatarNameError("");
        }

        if (isError) {
            return;
        }

        const avatarObject = {
            avatar_title: avatarName,
            type: 'Private',
            created_by: getFromSessionStorage("authEmail"),
            company: getFromSessionStorage("company")
        };

        try {
            const response = await fetchData(`/avatar/private/create`, 'POST', avatarObject);

            if (response.status === 409) {
                setAvatarNameError(response.message);
                return;
            }

            if (response) {
                setAvatarTrainingStep(0);
                setAvatarId(response.id)
                navigate("/avatars/create/audio-training/instructions/" + response.id);
            }
        } catch (error) {
            console.error('Error in creating avatar:', error);
        }

        // setAvatarName("");

        handleCloseDialog();
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };


    return (
        <Organism.Modal
            open={openDialog}
            setOpen={setOpenDialog}
            handleClose={handleCloseDialog}
            showInput="true"
            handleNameChange={handleNameChange}
            inputvalue={avatarName}
            errorMessage={avatarNameError}
            title="Name your avatar"
            inputPlaceholder="Avatar name"
            confirmLabel="Train Your Avatar"
            onCancel={handleCloseDialog}
            onConfirm={handleCreateAvatar}
            showConsentSection="true"
            isChecked={isChecked}
            onCheckboxChange={handleCheckboxChange}
            type="createAvatar"
            charCount={charCount}
            setCharCount={setCharCount}
            maxCharLimit={maxCharLimit}
        />
    )
}

export default CreateNewCampaign;