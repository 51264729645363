import AvatarsList from "./AvatarsList";
import CreateNewAvatar from "./CreateNewAvatar";
import AvatarsDefault from "./AvatarsDefault";
import AudioTrainingInstructions from "./AudioTrainingInstructions";
import AudioTrainingStart from "./AudioTrainingStart";
import VideoTrainingInstructions from "./VideoTrainingInstructions";
import VideoTrainingStart from "./VideoTrainingStart";


const AvatarOrganism = {
    AvatarsList,
    CreateNewAvatar,
    AvatarsDefault,
    AudioTrainingInstructions,
    AudioTrainingStart,
    VideoTrainingInstructions,
    VideoTrainingStart,
}

export default AvatarOrganism;