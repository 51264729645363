import Organism from "../organisms";
import LoginOrganism from "../organisms/login";

const Login = ({
    initialValues,
    validationSchema,
    onSubmit,
    isLoading,
    isPasswordVisible,
    setIsPasswordVisible,
    formError,
}) => {
    return (
        <>
            <Organism.Overlay isVisible={isLoading} />
            <div className="grid grid-cols-1 max-w-lg mx-auto h-full">
                <div className='my-auto rounded-2xl shadow-2xl py-3'>
                    <Organism.Logo />
                    <LoginOrganism.LoginForm
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        isPasswordVisible={isPasswordVisible}
                        setIsPasswordVisible={setIsPasswordVisible}
                        formError={formError}
                    />
                </div>
            </div>

        </>
    )
}

export default Login;