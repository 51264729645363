import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import Organism from 'common/components/organisms';
import Atom from 'common/components/atoms';
import { ScrollArea } from "components/ui/scroll-area";
import { fetchData } from 'apis/APIWrapper';
import { useToast } from "components/ui/use-toast"


const ChooseAvatar = ({ publicAvatars, myAvatars }) => {
	const [selectedAvatar, setSelectedAvatar] = useState(null);
	const [showMorePublic, setShowMorePublic] = useState(false);
	const [showMoreMy, setShowMoreMy] = useState(false);
	const [initialCardsToShow, setInitialCardsToShow] = useState(4);
	const navigate = useNavigate();
	const { campaignId, step } = useParams();
	const { toast } = useToast()


	useEffect(() => {
		const updateInitialCardsToShow = () => {
			if (window.matchMedia('(min-width: 1536px)').matches) {
				setInitialCardsToShow(5); // For 2xl screens
			} else if (window.matchMedia('(min-width: 1279px)').matches) {
				setInitialCardsToShow(4)
			} else if (window.matchMedia('(min-width: 1024px)').matches) {
				setInitialCardsToShow(3); // For 2xl screens
			} else if (window.matchMedia('(min-width: 768px)').matches) {
				setInitialCardsToShow(4); // For lg screens
			} else if (window.matchMedia('(min-width: 576px)').matches) {
				setInitialCardsToShow(3); // For md screens
			} else {
				setInitialCardsToShow(2); // For smaller screens
			}
		};

		updateInitialCardsToShow();
		window.addEventListener('resize', updateInitialCardsToShow);

		return () => window.removeEventListener('resize', updateInitialCardsToShow);
	}, []);

	const handleSelect = (avatar) => {
		if (avatar.imageUrl === '') {
			toast({
				duration: "4000",
				variant: "warning",
				title: "Avatar not fully trained!",
				description: "You need to complete the avatar training to add to your campaign",
			});
			return;
		}
		setSelectedAvatar(avatar.id);
	};

	const toggleShowMorePublic = () => setShowMorePublic(!showMorePublic);
	const toggleShowMoreMy = () => setShowMoreMy(!showMoreMy);

	const handleSubmitAvatar = async () => {
		const payload = {
			"avatar": selectedAvatar
		}
		if (!selectedAvatar) {
			toast({
				duration: "4000",
				variant: "error",
				title: "No avatar selected!",
				description: "You need to select an avatar to proceed",
			});
			return;
		} else {
			const response = await fetchData(`/campaigns/avatar/${campaignId}/${parseInt(step) + 1}`, 'POST', payload)
			if (response) {
				navigate(`/campaigns/create/script/${campaignId}/${parseInt(step) + 1}`);
			}
		}
	}

	return (
		<ScrollArea>
			{/* <div className='flex flex-col gap-6 overflow-auto mx-auto lg:w-[75%] 2xl:w-[75%] 3xl:w-[60%] px-16 py-10 my-auto bg-[#f8f8f8] rounded-2xl'> */}
			<div className='flex flex-col gap-6 overflow-auto mx-auto lg:w-[90%] xl:w-[87%] 2xl:w-[85%] 3xl:w-[70%] px-16 py-10 my-auto bg-[#f8f8f8] rounded-2xl'>
				<h2 className='text-2xl font-bold text-center'>Choose avatar for the campaign</h2>
				<Atom.Visibility state={publicAvatars.length !== 0}>
					<div className='flex flex-col'>
						<h2 className="text-xl font-bold mb-4">Public Avatars</h2>
						<div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
							{publicAvatars.slice(0, showMorePublic ? publicAvatars.length : initialCardsToShow).map((avatar, index) => (
								<Organism.AvatarCard
									key={index}
									name={avatar.name}
									status={avatar.status}
									imageUrl={avatar.imageUrl}
									createdOn={avatar.createdOn}
									isSelected={selectedAvatar === avatar.id}
									onSelect={() => handleSelect(avatar)}
									className="w-48 h-60"
								/>
							))}
						</div>
						{publicAvatars.length > initialCardsToShow && (
							<button onClick={toggleShowMorePublic} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
								{showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
								<span className="mx-2">{showMorePublic ? 'Show Less' : 'Show More'}</span>
								{/* {showMorePublic ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
							</button>
						)}
					</div>
				</Atom.Visibility>

				<div className='flex flex-col mb-4'>
					<h2 className="text-xl font-bold mb-4 mt-8">My Avatars</h2>
					<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
						{/* <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-8 gap-4"> */}
						{myAvatars.slice(0, showMoreMy ? myAvatars.length : initialCardsToShow).map((avatar, index) => (
							<Organism.AvatarCard
								key={index}
								name={avatar.name}
								status={avatar.status}
								imageUrl={avatar.imageUrl || '/draft.png'}
								createdOn={avatar.createdOn}
								isSelected={selectedAvatar === avatar.id}
								onSelect={() => handleSelect(avatar)}
								className="w-48 h-60"
								// isDisabled={avatar.imageUrl === ''}
								type="campaigns"
							/>
						))}
					</div>
					{myAvatars.length > initialCardsToShow && (
						<button onClick={toggleShowMoreMy} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
							{showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
							<span className="mx-2">{showMoreMy ? 'Show Less' : 'Show More'}</span>
							{/* {showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
						</button>
					)}
				</div>
				<Atom.Button type="primary" className="mx-auto w-[15%]" onClick={handleSubmitAvatar}>Continue</Atom.Button>
			</div>
		</ScrollArea>
	);
};

export default ChooseAvatar;


