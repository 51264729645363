import React from "react";

const TableRow = ({ children, onClick }) => {
   return (
      <tr
         className="hover:bg-gray-100 cursor-pointer"
         onClick={onClick}
      >
         {children}
      </tr>
   );
}

export default TableRow;
