import React from 'react';
import { Card } from "../../../components/ui/card";
import Atom from "common/components/atoms";

const AvatarCard = ({ name, status, imageUrl, createdOn, isSelected, onSelect, onDelete, isDisabled, className, type }) => {

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        const date = new Date(`${year}-${month}-${day}`);
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
        }).format(date);
    };

    return (
        <Card
            className={`w-40 h-56 flex flex-col rounded-lg overflow-hidden transition-transform duration-300 hover:shadow-lg 
            ${isSelected ? 'border-2 border-purple-600 scale-105 hover:shadow-none' : 'border-gray-300'} 
            ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''} 
            ${className} group`}
            onClick={!isDisabled ? onSelect : undefined}
        >
            <div className="relative h-1/2 rounded-t-lg">
                <img
                    src={imageUrl}
                    alt={`Avatar of ${name}`}
                    className="w-full h-full object-cover"
                />
                {type === "avatars-list" && !isDisabled && (
                    <Atom.DeleteButton
                        // className="absolute top-[6.1rem] right-[4.7rem] opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        className="absolute top-[0.4rem] left-[0.4rem] opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-8 h-8"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                        type="avatars_list"
                    />
                )}
            </div>
            <div className={`h-1/2 rounded-b-lg py-5 px-3 flex flex-col gap-2 justify-center ${isSelected ? 'bg-violet-100' : 'bg-gray-100'}`}>
                <h2 className="text-base font-bold">{name}</h2>
                <div>
                    <p className="text-xs text-gray-600">Status: {status}</p>
                    <p className="text-xs text-gray-600">Created on: {formatDate(createdOn)}</p>
                </div>
            </div>
        </Card>
    );
};

export default AvatarCard;

