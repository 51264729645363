import React from 'react';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';

const PrepareScriptPage = () => {
    return (
        <Template.CampaignSetup>
            <CampaignsOrganism.PrepareScript />
        </Template.CampaignSetup>
    )
}

export default PrepareScriptPage;