import camelCase from 'lodash/camelCase';
import { breakpoints } from './constants';

export const createColumnsForTable = (columns) => {
   return columns.map((column) => ({
      title: column,
      dataIndex: column === 'CAMPAIGN' ? 'thumbnail' : camelCase(column),
      key: camelCase(column),
   }));

}

export const isBreakpoint = (breakpoint) => {
   const screenWidth = window.innerWidth;
   return screenWidth >= breakpoints[breakpoint];
};
