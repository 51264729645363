import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Atom from '../atoms';
import { ScrollArea } from '../../../components/ui/scroll-area';
import { Camera, StartVideoTraining } from '../../assets/images';
import Organism from '../organisms';
import { ThreeCircles } from 'react-loader-spinner';
import { Separator } from '../../../components/ui/separator';


const VideoInstructions = ({
    navigateToRecord,
    // navigeAfterUpload,
    title,
    subHeading,
    type,
    onUpload,
    onContinue,
    isUploading,
    openDialog,
    setOpenDialog,
    fileUploadProp,
}) => {
    const { campaignId, step } = useParams();

    const navigate = useNavigate();

    const ProceedToRecordVideoHandler = () => {
        navigate(navigateToRecord);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    return (
        <ScrollArea>
            {isUploading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 " style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            )}
            <div className='flex flex-col gap-2 md:gap-4 xl:gap-3 2xl:gap-6 3xl:gap-9 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-2 2xl:py-10 my-0 2xl:my-4 3xl:my-10 rounded-2xl'>
                <h2 className='text-2xl text-center font-extrabold text-gray-800'>{title}</h2>
                <div className='flex flex-col gap-2 justify-center items-center px-10 py-3'>
                    <img src={Camera} alt="Video Instructions" className='w-10 h-10' />
                    {subHeading && <p className='text-sm text-gray-500 max-w-lg text-center'>{subHeading}</p>}
                </div>
                <div className='flex flex-col gap-2 2xl:gap-4'>
                    <h3 className='text-xl font-bold text-center'>Tips:</h3>
                    <Organism.Instructions type={type} />
                </div>
                <div className='flex gap-2 2xl:gap-4 items-center justify-center'>
                    <Atom.Button type="primary" onClick={ProceedToRecordVideoHandler}>Record Your Video</Atom.Button>
                    <Separator orientation="vertical" className="h-10" />
                    {/* <span className='text-xs'>Or</span> */}
                    <Atom.Button type="outline" onClick={() => setOpenDialog(true)}>Upload a Video</Atom.Button>
                </div>
            </div>
            <Organism.Modal
                open={openDialog}
                setOpen={setOpenDialog}
                handleClose={handleCloseDialog}
                showInput="false"
                title="Upload your video"
                confirmLabel="Continue"
                onCancel={handleCloseDialog}
                onConfirm={onContinue}
                handleFileSubmit={onUpload}
                fileUploadProp={fileUploadProp}
                type={type}
                className="z-50"
            // showSubHeading="true"
            // subHeading="You can either upload or record your video"
            />
        </ScrollArea>
    )
}

export default VideoInstructions;