import React, { useEffect, useState } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';


const IntegrationsPage = () => {

    const dummyAPIs = {
        clerkJsApiKey: "abcdef",
        openApikey: "ghijk",
        replicateKey: "lmnop",
        elevenLabsKey: "QRST",
        awsClientId: "uvwxy",
        awsClientPassword: "12345",
        mongoDbUrl: "abc.com",
        stripeKey: "abc123",
    }

    const [apiData, setApiData] = useState(dummyAPIs);

    return (
        <Template.AdminPanel titleText="Integrations">
            <AdminOrganism.Integrations apiData={apiData} />
        </Template.AdminPanel>
    )
}

export default IntegrationsPage;