import React, { useEffect, useState } from 'react';
import Atom from '../../atoms';
import ApiCards from './ApiCards';
import { ClerkLogo, OpenAILogo, ReplicateAILogo2, AWSLogo, ElevenLabsLogo2, MongoDBLogo, StripeLogo } from '../../../../common/assets/images';
import { ScrollArea } from '../../../../components/ui/scroll-area';

const Integrations = ({ apiData }) => {

  const initialCardData = [
    { logo: ClerkLogo, header: "ClerkJS API", subHeader: "User authentication and management", keyLabel: "Your clerkJS API Key:", keyValue: "" },
    { logo: OpenAILogo, header: "OpenAI API", subHeader: "AI-powered language processing", keyLabel: "your openAI API API Key:", keyValue: "" },
    { logo: ReplicateAILogo2, header: "Replicate API", subHeader: "Machine learning model hosting", keyLabel: "your replicate API Key:", keyValue: "" },
    { logo: ElevenLabsLogo2, header: "Elevenlabs API", subHeader: "AI-driven text-to-speech", keyLabel: "your elevenLabs API Key:", keyValue: "" },
    { logo: AWSLogo, header: "AWS Client", subHeader: "Cloud service management", keyLabel: "your aws client id:", keyValue: "", additionalInputs: [{ key: 'clientPassword', value: '', placeholder: 'AWS Client Password' }] },
    { logo: MongoDBLogo, header: "MongoDB", subHeader: "Scalable NoSQL database", keyLabel: "your mongoDB url:", keyValue: "" },
    { logo: StripeLogo, header: "Stripe API", subHeader: "Payment processing integration", keyLabel: "your stripe Key:", keyValue: "" },
  ];

  const [cardsData, setCardsData] = useState(initialCardData);
  const [editingCardIndex, setEditingCardIndex] = useState(null);

  useEffect(() => {
    const cardsWithAPIs = cardsData.map(card => {
      if (card.header === "ClerkJS API") card.keyValue = apiData?.clerkJsApiKey;
      if (card.header === "OpenAI API") card.keyValue = apiData?.openApikey;
      if (card.header === "Replicate API") card.keyValue = apiData?.replicateKey;
      if (card.header === "Elevenlabs API") card.keyValue = apiData?.elevenLabsKey;
      if (card.header === "AWS Client") {
        card.keyValue = apiData?.awsClientId;
        card.additionalInputs[0].value = apiData?.awsClientPassword;
      }
      if (card.header === "MongoDB") card.keyValue = apiData?.mongoDbUrl;
      if (card.header === "Stripe API") card.keyValue = apiData?.stripeKey;
      return card;
    });

    setCardsData(cardsWithAPIs);
  }, [])

  const handleEdit = (index) => {
    setEditingCardIndex(index);
  };

  const handleSave = () => {
    setEditingCardIndex(null);
  };

  const handleChange = (index, key, value) => {
    const updatedCards = cardsData.map((card, i) => {
      if (i === index) {
        if (key === 'keyValue') {
          return { ...card, keyValue: value };
        } else {
          const updatedInputs = card.additionalInputs ? card.additionalInputs.map((input) =>
            input.key === key ? { ...input, value: value } : input
          ) : [];
          return { ...card, additionalInputs: updatedInputs };
        }
      }
      return card;
    });
    setCardsData(updatedCards);
  };

  const handleSubmit = () => {
    const updatedValues = {
      clerkJsApiKey: cardsData[0].keyValue,
      openApikey: cardsData[1].keyValue,
      replicateKey: cardsData[2].keyValue,
      elevenLabsKey: cardsData[3].keyValue,
      awsClientId: cardsData[4].keyValue,
      awsClientPassword: cardsData[4].additionalInputs ? cardsData[4].additionalInputs[0].value : '',
      mongoDbUrl: cardsData[5].keyValue,
      stripeKey: cardsData[6].keyValue,
    };
    console.log(updatedValues);
  };

  return (
    <ScrollArea>
      <div className='m-5 flex items-center justify-center'>
        <div className='flex flex-col gap-6 items-center justify-center rounded-2xl border p-10 py-14 xl:w-[80%] 2xl:w-[70%]'>
          <div className='grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4 gap-4'>
            {cardsData.map((card, index) => (
              <ApiCards
                key={index}
                logo={card.logo}
                header={card.header}
                subHeader={card.subHeader}
                keyLabel={card.keyLabel}
                inputValue={card.keyValue}
                isEditing={editingCardIndex === index}
                onEdit={() => handleEdit(index)}
                onSave={handleSave}
                onChange={(value) => handleChange(index, 'keyValue', value)}
                logoClassName={card.logoClassName}
                additionalInputs={
                  card.additionalInputs?.map(input => ({
                    ...input,
                    onChange: (value) => handleChange(index, input.key, value)
                  })) || []
                }
              />
            ))}
          </div>
          <Atom.Button type="outline" onClick={handleSubmit}>Save changes</Atom.Button>
        </div>
      </div>
    </ScrollArea>
  );
};

export default Integrations;
