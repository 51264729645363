import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Webcam from 'react-webcam';
import { Progress } from '../../../../components/ui/progress';
import Atom from '../../atoms';
import { VideoOutline } from '../../../assets/images';
import { VideoIcon, Square, MoveLeft, MoveRight, Play, RefreshCw } from 'lucide-react';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import Organism from '..';
import { fetchData, sendFiles } from 'apis/APIWrapper';
import AppContext from 'common/context/AppContext';
import { ThreeCircles } from 'react-loader-spinner';
import { useToast } from "components/ui/use-toast"


const arrayOfParas = [
    {
        id: 1,
        value: "Face the camera and keep your mouth closed.",
    },
    {
        id: 2,
        value: "Face the camera and keep your mouth slightly open.",
    },
    {
        id: 3,
        value:
            "Read a short script:\n Hi, my name is <your name>. Its great to connect with you. \nI’d love to have a chat with you, about, how I can help you grow your business.”",
    },
];


const VideoTrainingStart = () => {
    const { toast } = useToast()
    const [step, setStep] = useState(0);
    const { videoUrl, setVideoUrl } = useContext(AppContext);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingStopped, setIsRecordingStopped] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timerId, setTimerId] = useState(null);
    const prevIsRecordingRef = useRef(isRecording);
    const videoRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const { avatarId } = useContext(AppContext)



    useEffect(() => {
        if (isRecording && !prevIsRecordingRef.current) {
            const id = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
            setTimerId(id);
        } else if (!isRecording && prevIsRecordingRef.current) {

            clearInterval(timerId);
            setElapsedTime(0);
        }
        prevIsRecordingRef.current = isRecording;
        return () => clearInterval(timerId);
    }, [isRecording, timerId]);


    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleOpenCamera = () => {
        setIsCameraOpen(true);
    };

    const handleCloseCamera = () => {
        if (webcamRef.current && webcamRef.current.video.srcObject) {
            const stream = webcamRef.current.video.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            webcamRef.current.video.srcObject = null;
            setIsCameraOpen(false);
            setIsRecording(false);
            setIsRecordingStopped(false);
            setRecordedChunks([]);
        }
    };

    const handleStartRecording = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            setIsRecording(true);
            setRecordedChunks([]);
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/webm' });
            mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
            mediaRecorderRef.current.start();
        }
    };

    const handleStopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setIsRecordingStopped(true);
        }

        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
    };

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordedChunks(prev => prev.concat(data));
        }
    }, []);

    const handleRetake = () => {
        setRecordedChunks([]);
        setIsRecordingStopped(false);
        handleStartRecording();

    };

    const handlePlay = () => {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);

        if (videoRef.current) {
            videoRef.current.play(); //doing it to trigger the video playback directly but not working
        }
    };

    const incrementStep = () => {
        if (step < arrayOfParas.length - 1) {
            setStep(step + 1);
            setIsRecordingStopped(false);
        } else {
            // setStep(step + 1);
            // setTrainingCompleted(true);
            setIsRecordingStopped(true);
            setOpenDialog(true);
        }
    }


    const handleContinueClick = async () => {
        if (recordedChunks.length > 0) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });
            const file = new File([blob], 'recordedVideo.mp4', { type: 'video/mp4' });
            setIsUploading(true);
            try {
                const current_step = {
                    'step': 11
                }
                const response = await sendFiles(`/avatar/private/upload/${avatarId}?data_type=video`, file, current_step)
                if (response) {
                    setIsUploading(false);
                    incrementStep();
                    setRecordedChunks([]);
                } else {
                    console.log("Error in uploading the video data")
                }
            } catch (error) {
                console.error("Error uploading video:", error);
                setIsUploading(false);
            }
        }
    };

    const handleBackClick = () => {
        if (step > 0) {
            setStep(step - 1);
        } else {
            // Handle completion or next step actions
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setStep(step - 1);
    };

    const handleSaveAvatar = async () => {
        // setOpenDialog(false);
        console.log('savinn avatar');
        setIsUploading(true);
        const response = await fetchData(`/avatar/private/training/completed/${avatarId}`, 'POST')
        if (response) {
            toast({
                duration: 3000,
                variant: "success",
                title: "Avatar successfully created",
            });
            setOpenDialog(false);
            setIsUploading(false);
            navigate("/avatars");
        } else {
            console.log("Error in creating avatar");
            setIsUploading(false);
            toast({
                duration: 3000,
                variant: "destructive",
                title: "Failed to create avatar",
                description: "An error occurred while creating your avatar. Please try again.",
            });
        }
    }

    // const currentSentence = arrayOfParas[step];

    const videoConstraints = {
        facingMode: 'user',
        filename: "test-filename",
        fileType: "mp4",
        width: 1920,
        height: 1080,
    };

    return (
        <ScrollArea>
            {isUploading && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            )}
            <div className='flex flex-col gap-8 2xl:gap-12 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-8 2xl:py-10 my-auto 2xl:my-1 rounded-2xl items-center'>
                <h2 className='text-2xl text-center font-extrabold text-gray-600'>Video Training</h2>
                <Progress
                    value={(step / arrayOfParas.length) * 100}
                    className="w-[60%] h-[13px]"
                />
                <div className='flex flex-col gap-5'>
                    <p className="text-[#868686] text-center">For the best result, you need speak for at least 2 mins to record your face and expressions.</p>
                    <div className='flex items-center justify-center lg:h-[359px] 2xl:h-[446px] bg-[#E8E6ED] rounded-3xl'>
                        {isCameraOpen && !isRecordingStopped ? (
                            <Webcam
                                audio={true}
                                muted={true}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                                className='block mx-auto w-[100%] h-[100%] rounded-3xl'
                            />
                        ) : isRecordingStopped ? (
                            <video
                                autoPlay
                                loop
                                controls
                                src={videoUrl}
                                className='w-[100%] h-[100%] rounded-3xl'
                            />
                        ) : (
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <img
                                    src={VideoOutline}
                                    alt="videoOutline"
                                />
                            </div>
                        )}
                    </div>
                    <div className='mx-auto flex gap-2'>
                        {!isCameraOpen ? (
                            <Atom.Button
                                type="primary"
                                onClick={handleOpenCamera}
                            >
                                Open Camera
                            </Atom.Button>
                        ) : isRecordingStopped ? (
                            <>
                                <Atom.Button
                                    type="icon"
                                    onClick={handleBackClick}
                                    className="p-3"
                                // disabled={audioBlob === null}
                                >
                                    {/* <Tooltip title="Go Back"> */}
                                    <MoveLeft className='h-6 w-5' />{" "}
                                    {/* </Tooltip> */}
                                </Atom.Button>
                                <Atom.Button
                                    type="primary"
                                    onClick={handleRetake}
                                >
                                    Retake
                                </Atom.Button>
                                <Atom.Button
                                    type="primary"
                                    onClick={handlePlay}
                                >
                                    Play
                                </Atom.Button>
                                <Atom.Button
                                    type="icon"
                                    onClick={handleContinueClick}
                                    className="p-3"
                                // disabled={audioBlob === null}
                                >
                                    {/* <Tooltip title="Continue"> */}
                                    <MoveRight className='h-6 w-5' />{" "}
                                    {/* </Tooltip> */}
                                </Atom.Button>
                            </>
                        ) : (
                            <>
                                {!isRecording && (
                                    <Atom.Button
                                        type="primary"
                                        onClick={handleCloseCamera}
                                    >
                                        Close Camera
                                    </Atom.Button>
                                )}
                                {isRecording ? (
                                    <Atom.Button
                                        type="primary"
                                        onClick={handleStopRecording}
                                        className="flex items-center gap-x-2 bg-[#FEE2E2] hover:bg-[#FEE2E2]/80 border-none text-gray-800"
                                    >
                                        <Square fill="#EF4444" strokeWidth={0} className='h-5 w-5 font-semibold' />
                                        {formatTime(elapsedTime)}
                                    </Atom.Button>
                                ) : (
                                    <Atom.Button
                                        type="primary"
                                        onClick={handleStartRecording}
                                        className="flex items-center gap-x-2"
                                    >
                                        <VideoIcon className='h-5 w-5 font-semibold' />
                                        Start recording
                                    </Atom.Button>
                                )}
                            </>
                        )}
                    </div>
                    <p className='text-center font-semibold text-[#868686]'>3 things to keep in mind while recording:</p>
                    <div className=" flex flex-col gap-2 text-[#868686] text-center italic text-sm">
                        <p>1. Stay 2-3 ft (50-100cm) from the camera</p>
                        <p>2. Minimize head/face movement</p>
                        <p>3. Keep the background simple</p>
                    </div>
                </div>
                <Organism.Modal
                    open={openDialog}
                    setOpen={setOpenDialog}
                    handleClose={handleCloseDialog}
                    showInput="false"
                    title="Are you sure you want to save your avatar?"
                    confirmLabel="Yes Save It"
                    onCancel={handleCloseDialog}
                    onConfirm={handleSaveAvatar}
                />
            </div>
        </ScrollArea>
    )
}

export default VideoTrainingStart;