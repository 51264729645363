import React from 'react'

const LibraryIcon = ({ isIconActive }) => {

    return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.0498 17.5L9.0498 1M12.5498 17.5L12.5498 1M16.0498 17.5L16.0498 1" stroke={isIconActive ? "#612FFF" : "#444444"} fill={isIconActive ? "#612FFF" : "none"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 17.1529L6.04958 1.44456" stroke={isIconActive ? "#612FFF" : "#444444"} fill={isIconActive ? "#612FFF" : "none"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default LibraryIcon