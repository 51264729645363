import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const AfterPaymentPage = () => {
    const navigate = useNavigate();
    const paymentSuccess = true;


    const SuccessIcon = () => (
        <div className="flex justify-center items-center">
            <svg className="w-20 h-20 text-green-500 animate-pulse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle cx="26" cy="26" r="25" className="fill-current text-green-400 animate-checkmarkCircle" style={{ strokeDasharray: '166', strokeDashoffset: '166' }} />
                <path d="M14.1 27.2l7.1 7.2 16.7-16.8" className="stroke-current text-green-700 animate-checkmarkCheck" strokeWidth="3" fill="none" style={{ strokeDasharray: '48', strokeDashoffset: '48' }} />
            </svg>
        </div>
    );

    const FailedIcon = () => (
        <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 80 80" className="animate-pulse">
                <path fill="#f78f8f" d="M40,77.5C19.322,77.5,2.5,60.678,2.5,40S19.322,2.5,40,2.5S77.5,19.322,77.5,40S60.678,77.5,40,77.5 z"></path>
                <path fill="#c74343" d="M40,3c20.402,0,37,16.598,37,37S60.402,77,40,77S3,60.402,3,40S19.598,3,40,3 M40,2 C19.013,2,2,19.013,2,40s17.013,38,38,38s38-17.013,38-38S60.987,2,40,2L40,2z"></path>
                <path fill="#fff" d="M37 20H43V60H37z" transform="rotate(-134.999 40 40)"></path>
                <path fill="#fff" d="M37 20H43V60H37z" transform="rotate(-45.001 40 40)"></path>
            </svg>

        </div>
    );


    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 4000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className={`text-center p-12 shadow-lg rounded-lg ${paymentSuccess ? 'bg-green-100' : 'bg-red-100'} shadcn flex flex-col gap-10`}>
                {paymentSuccess ? (
                    <>
                        <SuccessIcon />
                        <div className='flex flex-col gap-2'>
                            <h2 className="text-3xl font-bold text-gray-800">Payment Successful!</h2>
                            <p className="mt-2 text-gray-600">Thank you for your purchase.</p>
                        </div>
                    </>
                ) : (
                    <>
                        <FailedIcon />
                        <h2 className="text-3xl font-bold text-gray-800">Payment Failed!</h2>
                        <p className="mt-2 text-gray-600">Please try again later.</p>
                    </>
                )}
            </div>
            {/* <Link to="/" className="mt-6 px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded-lg shadow shadcn">
                Return to Homepage
            </Link> */}
        </div >
    );

}

export default AfterPaymentPage