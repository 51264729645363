import React, { useEffect, useState, useContext } from 'react';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';
import AppContext from '../../common/context/AppContext';


const VariablesAssignPage = () => {

    // const VariablesSetInCampaignSetup = [
    //     {
    //         index: "1",
    //         value: "first name",
    //     },
    //     {
    //         index: "2",
    //         value: "last name",
    //     },
    //     {
    //         index: "3",
    //         value: "company name",
    //     },
    //     {
    //         index: "4",
    //         value: "city",
    //     },
    //     {
    //         index: "5",
    //         value: "contact",
    //     },
    // ];

    // const { setVariables } = useContext(AppContext);

    // useEffect(() => {
    //     setVariables(VariablesSetInCampaignSetup)
    // }, []);

    return (
            <Template.CampaignSetup>
                <CampaignsOrganism.VariablesAssign />
            </Template.CampaignSetup>
    )
}

export default VariablesAssignPage;