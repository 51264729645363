import React from 'react';
import { useNavigate } from "react-router-dom";
import Atom from '../../atoms';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { Camera, StartVideoTraining } from '../../../assets/images';
import Organisms from '..';

const VideoTrainingInstructions = ({ navigateTo, title, subHeading }) => {
    const navigate = useNavigate();
    const ProceedToRecordVideoHandler = () => {
        navigate(navigateTo);
    }

    return (
        <ScrollArea>
            <div className='flex flex-col gap-3 2xl:gap-9 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-8 2xl:py-10 my-0 2xl:my-0 rounded-2xl'>
                <h2 className='text-2xl text-center font-extrabold text-gray-800'>{title}</h2>
                <div className='flex flex-col gap-4 justify-center items-center px-10 py-4 '>
                    <img src={StartVideoTraining} alt="Video Instructions" className='2xl:w-40 2xl:h-40' />
                    <p className='text-sm text-gray-500 max-w-lg text-center'>{subHeading}</p>
                </div>
                <div className='flex flex-col gap-2 2xl:gap-4'>
                    <h3 className='text-xl font-bold text-center'>Tips:</h3>
                    {/* <div className='flex flex-col gap-4 bg-violet-100 px-6 py-5 rounded-3xl'>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                            <p className='text-sm'>Use a High-Resolution Camera and do the recording in a well-lit environment.</p>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-2 items-start'>
                                <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                                <p className='text-sm'>Keep your face in 1 position for at least 5 seconds. The positions should be:</p>
                            </div>
                            <div className='flex flex-col gap-1 px-28'>
                                <p className='text-sm'>A. Facing front with mouth closed for 5 sec </p>
                                <p className='text-sm'>B. Turned to the right for 5 sec </p>
                                <p className='text-sm'>C. Turned to the left for 5 sec </p>
                                <p className='text-sm'>D. Facing front and speaking for 5 seconds. </p>
                            </div>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                            <p className='text-sm'>Just record your upper body (up to chest area) and DO NOT bring your hands into the frame.</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[4%] h-[4%] 2xl:w-[2.8%] 2xl:h-[2.8%] text-green-700 mt-1 rounded-sm' />
                            <p className='text-sm'> Only one person should be present in the video frame. There should be NO other faces in the background (including any portraits hanging in the wall).</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                            <p className='text-sm'>Do not wear glasses when recording the video.</p>
                        </div>
                    </div> */}
                    <Organisms.Instructions type="video-training"/>
                </div>
                <div className='flex gap-2 2xl:gap-4 items-start justify-center '>
                    <Atom.Button type="primary" onClick={ProceedToRecordVideoHandler}>Record Your Video</Atom.Button>
                    <Atom.Button type="skull">Or Upload a Video</Atom.Button>
                </div>
            </div>
        </ScrollArea>
    )
}

export default VideoTrainingInstructions;