import React, { useState, useEffect } from 'react';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';
import { fetchData } from 'apis/APIWrapper';
import { getFromSessionStorage } from 'lib/storage';
import { ThreeCircles } from 'react-loader-spinner';


const ChooseAvatarPage = () => {

   const [myAvatars, setMyAvatars] = useState([]);
   const [publicAvatars, setPublicAvatars] = useState([]);
   const [isLoading, setIsLoading] = useState(false);


   // Simulated fetch functions
   // const dummyPublicAvatars = [
   //    { id: '1', name: 'John', status: 'Trained', imageUrl: 'https://media.istockphoto.com/id/1275842510/photo/young-man-waving-with-hand-on-video-call-at-home-office.jpg?s=612x612&w=0&k=20&c=Jjnme5ARHRHRf6MN57HYK45ZLPIAAW4RxVOshBDMeIY=', createdOn: '10 Nov 23', },
   //    { id: 2, name: 'Ian', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1198252585/photo/happy-indian-woman-look-at-webcam-doing-job-interview-videochat.jpg?s=612x612&w=0&k=20&c=4xvWW9lNc_VKMdMx9WYR491-NEoNt9su5Ug5CSHdD64=', createdOn: '15 Dec 23', },
   //    { id: 3, name: 'Steve', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1319103417/photo/inspired-female-teacher-in-headphones-meet-students-online-give-class.jpg?s=612x612&w=0&k=20&c=cSGIQIwPFpVi7m4MR2_SLlwDcpBELdnNnLNgdtH6Bak=', createdOn: '15 Dec 23', },
   //    { id: 4, name: 'David', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1357265437/photo/young-smiling-woman-with-bluetooth-headphones-having-video-call-at-home.jpg?s=612x612&w=0&k=20&c=qvAcfXFfpo7uAARmuw8J20_n1CcsTvFClPkutKzx3RU=', createdOn: '15 Dec 23', },
   //    { id: 5, name: 'Chris', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1329932069/photo/man-doing-a-video-call-at-home-webcam-point-of-view.jpg?s=612x612&w=0&k=20&c=jIcEu4Vx0plLVQhPL2s60WpUTrjgbMFYrsxmD4R91Jk=', createdOn: '15 Dec 23', },
   //    { id: 6, name: 'Martin', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1203044192/photo/african-applicant-look-at-webcam-conference-calling-for-job-interview.jpg?s=612x612&w=0&k=20&c=MVmJPxhxl7EKqVidxJ4LblqTC02rzfqNkrRi-4PTC1M=', createdOn: '15 Dec 23', },
   //    { id: 7, name: 'Erling', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1295425402/photo/businesswoman-talking-during-video-call-in-the-office.jpg?s=612x612&w=0&k=20&c=ql2yfi5_sEpxWwasArETDOA9o3k1fP5SACj3e3G407o=', createdOn: '15 Dec 23', },
   //    { id: 8, name: 'Mathew', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1256299197/photo/the-busy-but-flexible-life-of-a-freelancer.jpg?s=612x612&w=0&k=20&c=1tfRytxf0k1ryQbnYx0zli0C86oUvO7sBLxe0zXJ1NU=', createdOn: '15 Dec 23', },
   //    { id: 9, name: 'Andrew', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1332741385/photo/man-doing-a-virtual-business-call-at-home-webcam-point-of-view.jpg?s=612x612&w=0&k=20&c=TvGnfwksyVcPYp0nv_q_4UAgRrug2RUl_3phKAzbOoo=', createdOn: '15 Dec 23', },
   //    { id: 10, name: 'Shane', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1176848710/photo/businessman-making-video-call-looking-at-camera-and-talking.jpg?s=612x612&w=0&k=20&c=AQDZDL--JSOBEXp-_pAhLcMVfxQ6F5bpQlBXqdl_ks4=', createdOn: '15 Dec 23', },
   //    { id: 11, name: 'Michael', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1164377489/photo/happy-old-mature-online-teacher-tutor-looking-at-camera.jpg?s=612x612&w=0&k=20&c=M_2Pz2cBOztuPyqKTuqe4ANMoo7s0t3pjY2fjak6z3Y=', createdOn: '15 Dec 23', },
   //    { id: 12, name: 'Kane', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1277612580/photo/headshot-of-young-woman-have-video-call-at-home.jpg?s=612x612&w=0&k=20&c=GKuzbutPbjIwSJKZJHepmRIvlbFotOriNSJNhA3L5fY=', createdOn: '15 Dec 23', },
   //    { id: 13, name: 'Kobe', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1299493619/photo/smiling-senior-woman-talking-on-video-call-at-home.jpg?s=612x612&w=0&k=20&c=8XUyszMrv-7M5IxmJGBC3_oimwJ1IPHENVi3ZkjFoRo=', createdOn: '15 Dec 23', },

   // ];


   // const myAvatars = [
   //    { id: '66267264621523c338b79089', name: 'Daryl', status: 'Trained', imageUrl: 'https://media.istockphoto.com/id/918365128/photo/african-american-man-talking-on-web-camera-video-call-headshot-portrait.jpg?s=612x612&w=0&k=20&c=fk5AbEmqVpECRjMiOBu6d5xbMvUmlfiMLRqRTxUZ1x8=', createdOn: '27 July 23', },
   //    { id: 102, name: 'Nick', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1355480439/photo/smiling-man-having-video-call-from-home.jpg?s=612x612&w=0&k=20&c=k4AtJeY_sE7ZBR5ThpuYjIMJGE9hQoLapDnCAkFp0EE=', createdOn: '12 Aug 23', },
   //    { id: 103, name: 'Alice', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1288561789/photo/portrait-of-woman-with-headset.jpg?s=612x612&w=0&k=20&c=YXEDF0NQaLLmlGLVLdz1yDL5Y9YS67u2-xpwZ0bcR74=', createdOn: '12 Aug 23', },
   //    { id: 104, name: 'Damien', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1254536871/photo/technology-makes-connecting-with-the-outside-world-so-much-easier.jpg?s=612x612&w=0&k=20&c=4HTq8_9FnoybMUnfUilr7dgMFgf3sXmvm7smDyuPlsY=', createdOn: '12 Aug 23', },
   //    { id: 105, name: 'Justin', status: 'Training', imageUrl: 'https://media.istockphoto.com/id/1332972396/photo/portrait-of-successful-handsome-executive-businessman-smart-casual-wear-looking-at-camera-and.jpg?s=612x612&w=0&k=20&c=4M71U3wUX3YuvoUjq2jD5uBUtqt0zslHIYYMKhSHJTM=', createdOn: '12 Aug 23', },
   //    { id: 106, name: 'Paul', status: 'Training', imageUrl: 'https://images.pexels.com/photos/234286/pexels-photo-234286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', createdOn: '12 Aug 23', },
   //    { id: 107, name: 'Bob', status: 'Training', imageUrl: 'https://images.pexels.com/photos/234286/pexels-photo-234286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', createdOn: '12 Aug 23', },
   //    { id: 108, name: 'Luke', status: 'Training', imageUrl: 'https://images.pexels.com/photos/234286/pexels-photo-234286.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', createdOn: '12 Aug 23', },
   //    // Add more avatars as needed
   // ];

   useEffect(() => {
      const loadData = async () => {
         setIsLoading(true);
         const Avatars = await fetchData("/avatar/private", 'POST', { created_by_company: getFromSessionStorage("company") });
         if (Avatars) {
            const privateAvatars = Avatars.filter(avatar => avatar.type === 'Private');
            const publicAvatars = Avatars.filter(avatar => avatar.type === 'Public');
            setMyAvatars(privateAvatars);
            setPublicAvatars(publicAvatars);
         }
         setIsLoading(false);
      };

      loadData();
   }, []);




   return (
      <>
         {isLoading ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
               <ThreeCircles
                  visible={true}
                  height="100"
                  width="100"
                  color="#e6a6e0"
                  ariaLabel="three-circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
               />
            </div>

         ) :
            <Template.CampaignSetup>
               <CampaignsOrganism.ChooseAvatar publicAvatars={publicAvatars} myAvatars={myAvatars} />
            </Template.CampaignSetup>
         }
      </>
   )
}

export default ChooseAvatarPage;