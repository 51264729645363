import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Webcam from 'react-webcam';
import Atom from 'common/components/atoms';
import { VideoOutline } from 'common/assets/images';
import { VideoIcon, Square, Play, RefreshCw } from 'lucide-react';
import { ScrollArea } from 'components/ui/scroll-area';
import AppContext from 'common/context/AppContext';
import { ThreeCircles } from 'react-loader-spinner';
import { sendFiles } from 'apis/APIWrapper';

const RecordVideo = () => {
	const { videoUrl, setVideoUrl } = useContext(AppContext);
	const { transcription, setTranscription } = useContext(AppContext);
	const webcamRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const [isCameraOpen, setIsCameraOpen] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [isRecordingStopped, setIsRecordingStopped] = useState(false);
	const [recordedChunks, setRecordedChunks] = useState([]);
	// const [playbackUrl, setPlaybackUrl] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(0);
	const [timerId, setTimerId] = useState(null);
	const prevIsRecordingRef = useRef(isRecording);
	const videoRef = useRef(null);
	const { campaignId, step } = useParams();
	const [isUploading, setIsUploading] = useState(false);
	const { script } = useContext(AppContext)
	const navigate = useNavigate();

	useEffect(() => {
		if (isRecording && !prevIsRecordingRef.current) {
			const id = setInterval(() => {
				setElapsedTime(prevTime => prevTime + 1);
			}, 1000);
			setTimerId(id);
		} else if (!isRecording && prevIsRecordingRef.current) {

			clearInterval(timerId);
			setElapsedTime(0);
		}
		prevIsRecordingRef.current = isRecording;
		return () => clearInterval(timerId);
	}, [isRecording, timerId]);


	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};

	const handleOpenCamera = () => {
		setIsCameraOpen(true);
	};

	const handleCloseCamera = () => {
		if (webcamRef.current && webcamRef.current.video.srcObject) {
			const stream = webcamRef.current.video.srcObject;
			const tracks = stream.getTracks();
			tracks.forEach(track => track.stop());
			webcamRef.current.video.srcObject = null;
			setIsCameraOpen(false);
			setIsRecording(false);
			setIsRecordingStopped(false);
			setRecordedChunks([]);
		}
	};

	const handleStartRecording = () => {
		if (webcamRef.current && webcamRef.current.stream) {
			setIsRecording(true);
			setRecordedChunks([]);
			mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/webm' });
			mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
			mediaRecorderRef.current.start();
		}
	};

	const handleStopRecording = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
			setIsRecordingStopped(true);
		}

		const blob = new Blob(recordedChunks, { type: 'video/webm' });
		const url = URL.createObjectURL(blob);
		// setPlaybackUrl(url);
		setVideoUrl(url);
	};

	const handleDataAvailable = useCallback(({ data }) => {
		if (data.size > 0) {
			setRecordedChunks(prev => prev.concat(data));
		}
	}, []);

	const handleRetake = () => {
		setRecordedChunks([]);
		setIsRecordingStopped(false);
		handleStartRecording();

	};

	const handlePlay = () => {
		const blob = new Blob(recordedChunks, { type: 'video/webm' });
		const url = URL.createObjectURL(blob);
		// setPlaybackUrl(url);
		setVideoUrl(url);

		if (videoRef.current) {
			videoRef.current.play(); //doing it to trigger the video playback directly but not working
		}
	};


	const handleContinue = async () => {
		const blob = new Blob(recordedChunks, { type: 'video/webm' });
		const file = new File([blob], 'recordedVideo.webm', { type: 'video/webm' });
		setVideoUrl(URL.createObjectURL(blob));
		setIsUploading(true);
		const mode = {
			'video_mode': 'recorded'
		}

		const response = await sendFiles(`/campaigns/upload/video/${campaignId}/${parseInt(step) + 1}`, file, mode);
		if (response) {
			setIsUploading(false)
			setTranscription(response.transcription)
			navigate(`/campaigns/create/variables/${campaignId}/${parseInt(step) + 1}`);
		}
	}

	// with videMode 
	// const handleContinue = async () => {
	// 	const blob = new Blob(recordedChunks, { type: 'video/webm' });
	// 	const file = new File([blob], 'recordedVideo.mp4', { type: 'video/mp4' });

	// 	setVideoUrl(URL.createObjectURL(blob));
	// 	setIsUploading(true);

	// 	const formData = new FormData();
	// 	formData.append('video', file);
	// 	formData.append('videoMode', 'recorded');
	// 	console.log(formData);

	// 	const response = await sendFiles(`/campaigns/upload/video/${campaignId}/${parseInt(step) + 1}`, formData);
	// 	if (response) {
	// 		setIsUploading(false);
	// 		setTranscription(response.transcription);
	// 		navigate(`/campaigns/create/variables/${campaignId}/${parseInt(step) + 1}`);
	// 	}
	// };


	const videoConstraints = {
		facingMode: 'user',
		filename: "test-filename",
		fileType: "mp4",
		width: 1920,
		height: 1080,
	};

	return (
		<ScrollArea className="my-auto">
			{isUploading && (
				<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
					<ThreeCircles
						visible={true}
						height="100"
						width="100"
						color="#e6a6e0"
						ariaLabel="three-circles-loading"
						wrapperStyle={{}}
						wrapperClass=""
					/>
				</div>

			)}
			<div className='flex flex-col justify-center gap-6 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-10 my-auto bg-[#f8f8f8] rounded-2xl'>
				<h2 className='text-2xl font-bold text-center'>Record your video</h2>
				<div className='flex flex-col gap-6 items-center'>
					<div className='border rounded-3xl py-4 px-6 bg-white w-[90%]'>
						{script?.script}
					</div>
					<div className='flex items-center justify-center lg:w-[89%] 2xl:w-[90%] lg:h-[359px] 2xl:h-[446px] bg-[#E8E6ED] rounded-3xl'>
						{isCameraOpen && !isRecordingStopped ? (
							<Webcam
								audio={true}
								muted={true}
								ref={webcamRef}
								videoConstraints={videoConstraints}
								className='block mx-auto w-[100%] h-[100%] rounded-3xl'
							/>
						) : isRecordingStopped ? (
							<video
								autoPlay
								loop
								controls
								// src={playbackUrl}
								src={videoUrl}
								className='w-[100%] h-[100%] rounded-3xl'
							/>
						) : (
							<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
								<img
									src={VideoOutline}
									alt="videoOutline"
								/>
							</div>
						)}
					</div>
					<div className='mx-auto flex gap-2'>
						{!isCameraOpen ? (
							<Atom.Button
								type="primary"
								onClick={handleOpenCamera}
							>
								Open Camera
							</Atom.Button>
						) : isRecordingStopped ? (
							<>
								<Atom.Button
									type="primary"
									onClick={handleRetake}
								>
									Retake
								</Atom.Button>
								<Atom.Button
									type="primary"
									onClick={handlePlay}
								>
									Play
								</Atom.Button>
								<Atom.Button
									type="primary"
									onClick={handleContinue}
								>
									Continue
								</Atom.Button>
							</>
						) : (
							<>
								{!isRecording && (
									<Atom.Button
										type="primary"
										onClick={handleCloseCamera}
									>
										Close Camera
									</Atom.Button>
								)}
								{isRecording ? (
									<Atom.Button
										type="primary"
										onClick={handleStopRecording}
										className="flex items-center gap-x-2 bg-[#FEE2E2] hover:bg-[#FEE2E2]/80 border-none text-gray-800"
									>
										<Square fill="#EF4444" strokeWidth={0} className='h-5 w-5 font-semibold' />
										{formatTime(elapsedTime)}
									</Atom.Button>
								) : (
									<Atom.Button
										type="primary"
										onClick={handleStartRecording}
										className="flex items-center gap-x-2"
									>
										<VideoIcon className='h-5 w-5 font-semibold' />
										Start recording
									</Atom.Button>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</ScrollArea>
	);
};

export default RecordVideo;





