import axios from "axios";

import {
   getFromSessionStorage,
   clearSessionStorageCredentials,
} from "../lib/storage";

// Here we can set the default base url for personiq backend
axios.defaults.baseURL = "/";

const setAuthHeaders = () => {
   const token = getFromSessionStorage("authToken");
   const email = getFromSessionStorage("authEmail");

   email && (axios.defaults.headers["X-Auth-Email"] = email);
   token && (axios.defaults.headers["X-Auth-Token"] = token);
};

const resetAuthTokens = () => {
   delete axios.defaults.headers["X-Auth-Email"];
   delete axios.defaults.headers["X-Auth-Token"];
};

const handleSuccessResponse = (response) => {
   return response;
};

const handleErrorResponse = async (error, signOutFn) => {
   if (error.response?.status === 401) {
      clearSessionStorageCredentials();
      await signOutFn();
   }
   return Promise.reject(error);
};

const registerIntercepts = (signOutFn) => {
   axios.interceptors.response.use(handleSuccessResponse, (error) =>
      handleErrorResponse(error, signOutFn)
   );
};

export { setAuthHeaders, resetAuthTokens, registerIntercepts };
