import React from 'react';
import Organism from '../organisms';

const AdminPanel = ({ children, titleText }) => {

    const title = titleText ? titleText : "Admin Panel";

    return (
        <div className='flex flex-col h-full gap-1'>
            <Organism.Header title={title} elements={[]} />
            <main className='flex flex-col h-full overflow-auto'>{children}</main>
        </div>
    )
}

export default AdminPanel;