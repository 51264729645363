import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
    AUTH_ROUTES,
    LOGIN_PATH,
    MAIN_ROUTES,
    LANDING_PAGE_ROUTES,
    ADMIN_PANEL_ROUTES,
    NOT_FOUND_PAGE_PATH
} from "./routeConstants";
import { SignedIn, SignedOut, useAuth, useClerk } from "@clerk/clerk-react";
import { setAuthHeaders, registerIntercepts } from "../apis/axios";
import Layout from "../Layout";

const AppRouter = () => {
    const { sessionId } = useAuth();
    const { signOut } = useClerk();

    useEffect(() => {
        registerIntercepts(signOut);
        setAuthHeaders();
    }, [signOut]);

    return (
        <>
            <SignedOut>
                <Routes>
                    <Route path="/" element={<Navigate to={LOGIN_PATH} />} />
                    {AUTH_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<route.component />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {/* Redirect any attempt to access private routes to login when signed out */}
                    <Route
                        path="*"
                        element={<Navigate to={LOGIN_PATH} />}
                    />
                </Routes>
            </SignedOut>
            <SignedIn>
                <Routes>
                    <Route path="/" element={<Navigate to={"/campaigns"} />} />
                    {/* Redirect auth routes to campaigns if signed in */}
                    {AUTH_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<Navigate to={"/campaigns"} />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {MAIN_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<Layout>{<route.component />}</Layout>}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {LANDING_PAGE_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<route.component />}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {ADMIN_PANEL_ROUTES.map((route) => (
                        <Route
                            exact
                            element={<Layout>{<route.component />}</Layout>}
                            key={route.path}
                            path={route.path}
                        />
                    ))}
                    {/* Catch incomplete or invalid paths and redirect to 404 */}
                    <Route
                        path={NOT_FOUND_PAGE_PATH}
                        element={<notFoundPage />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={NOT_FOUND_PAGE_PATH} />}
                    />
                </Routes>
            </SignedIn>
        </>
    );
};

export default AppRouter;
