import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Organism from '../../organisms';
import { fetchData } from "../../../../apis/APIWrapper";
import { getFromSessionStorage } from 'lib/storage';


const CreateNewCampaign = ({
	openDialog,
	setOpenDialog,
	handleCloseDialog,

}) => {

	const [campaignName, setCampaignName] = useState("");
	const [campaignNameError, setCampaignNameError] = useState("");
	const [charCount, setCharCount] = useState(campaignName.length || 0);
	const maxCharLimit = 40;

	const navigate = useNavigate();

	useEffect(() => {
		if (!openDialog) {
			setCharCount(0);
		}
	}, [openDialog]);


	const handleNameChange = (event) => {
		setCampaignName(event.target.value);
		if (campaignNameError) {
			setCampaignNameError("");
		}
	};

	//  const handleCreateCampaign = (event) => {
	//      event.preventDefault();
	//      console.log('Form submitted with campaignName:', campaignName);
	//      let isError = false;
	//      if (!campaignName.trim()) {
	//          setCampaignNameError("Campaign name cannot be empty");
	//          isError = true;
	//      } else {
	//          setCampaignNameError("");
	//      }

	//      if (isError) {
	//          return;
	//      }

	//      const campaignObject = {
	//          campaign_title: campaignName,
	//      };

	//      // navigate("/campaigns/create");


	//      // setCampaignName("");

	//      handleCloseDialog();
	//  }

	const handleCreateCampaign = async (event) => {
		event.preventDefault();

		if (!campaignName.trim()) {
			setCampaignNameError("Campaign name cannot be empty");
			return;
		} else {
			setCampaignNameError("");
		}
		const payload = {
			"name": campaignName,
			"created_by_company": getFromSessionStorage("company")
		}

		try {
			const response = await fetchData('/campaigns/create', 'POST', payload);
			if (response) {
				navigate("/campaigns/create/" + response + "/1");
				handleCloseDialog();
			}
		} catch (error) {
			console.error('Error creating campaign:', error);
		}
	};

	return (
		<Organism.Modal
			open={openDialog}
			setOpen={setOpenDialog}
			handleClose={handleCloseDialog}
			showInput="true"
			handleNameChange={handleNameChange}
			inputvalue={campaignName}
			errorMessage={campaignNameError}
			title="Name your campaign"
			inputPlaceholder="Campaign name"
			confirmLabel="Create Your Campaign"
			onCancel={handleCloseDialog}
			onConfirm={handleCreateCampaign}
			type="createCampaign"
			charCount={charCount}
			setCharCount={setCharCount}
			maxCharLimit={maxCharLimit}
		/>
	)
}

export default CreateNewCampaign;