import React from 'react'
import Organism from 'common/components/organisms';

const CheckoutFormPage = () => {

    return (
        <Organism.CheckoutForm />
    )
}

export default CheckoutFormPage;