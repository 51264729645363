import React, { useState, useEffect } from 'react';
import { fetchData } from 'apis/APIWrapper';
import { getFromSessionStorage } from 'lib/storage';
import { useParams, useNavigate } from "react-router-dom";
import AvatarOrganism from '../../common/components/organisms/avatars';
import Template from '../../common/components/templates';
import { useLocation } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner';
import Atom from "common/components/atoms";
import { NOT_FOUND_PAGE_PATH } from 'routes/routeConstants';

const AudioTrainingStartPage = () => {
    const location = useLocation();
    const step = location.state?.step;
    const { avatarId } = useParams();
    const navigate = useNavigate();

    const [avatarName, setAvatarName] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadData = async () => {
            try {
                const Avatars = await fetchData("/avatar/private", 'POST', { created_by_company: getFromSessionStorage("company") });
                if (Avatars) {
                    const privateAvatars = Avatars.filter(avatar => avatar.type === 'Private');
                    const avatar = privateAvatars.find(avatar => avatar.id === avatarId);

                    if (avatar) {
                        setAvatarName(avatar.name);
                    } else {
                        navigate(NOT_FOUND_PAGE_PATH);
                        return;
                    }
                } else {
                    navigate(NOT_FOUND_PAGE_PATH);
                    return;
                }
            } catch (error) {
                console.error("Error loading avatar data:", error);
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [avatarId, navigate]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <Atom.Visibility state={isLoading}>
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            </Atom.Visibility>
            <Atom.Visibility state={!isLoading}>
                <Template.AvatarSetup avatarName={avatarName}>
                    <AvatarOrganism.AudioTrainingStart step={step} />
                </Template.AvatarSetup>
            </Atom.Visibility>
        </>
    );
}

export default AudioTrainingStartPage;