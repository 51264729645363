import React from "react";
import { cn } from "../../../lib/utils";

const TableHead = ({ children, className }) => {
  return (
    <thead
      // className="sticky top-0 bg-white text-gray-600 font-medium"
      className={cn('sticky top-0 bg-white text-gray-600 font-medium',className)}
      style={{ boxShadow: "0 2px 0 0 #eaecf0" }}
    >
      {children}
    </thead>
  );
}

export default TableHead;
