import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Template from '../../common/components/templates';
import Organism from 'common/components/organisms';
import { fetchData, sendFiles, sendFormData } from 'apis/APIWrapper';
import AppContext from 'common/context/AppContext';
import { useToast } from "components/ui/use-toast"


const VideoTrainingInstructionsPage = () => {
    const { toast } = useToast()
    const [videoFiles, setVideoFiles] = useState({ video1: null, video2: null, video3: null });
    const { avatarId } = useContext(AppContext);
    const [isUploading, setIsUploading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    const [openSaveDialog, setOpenSaveDialog] = useState(false);


    const handleFileSubmit = (fileData, name) => {
        const file = fileData.files[0];
        setVideoFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
    };


    const handleContinue = async () => {
        console.log('checking on continue');
        // if (videoFiles.video1 && videoFiles.video2 && videoFiles.video3) {
        if (videoFiles.video1) {
            const current_step = 11;
            setIsUploading(true);
            try {
                const formData1 = new FormData();
                formData1.append('file', videoFiles.video1);
                formData1.append('step', current_step);


                // const formData2 = new FormData();
                // formData2.append('file', videoFiles.video2);
                // formData2.append('step', current_step);


                // const formData3 = new FormData();
                // formData3.append('file', videoFiles.video3);
                // formData3.append('step', current_step);


                const responses = await Promise.all([
                    sendFormData(`/avatar/private/upload/${avatarId}?data_type=video`, formData1),
                    // sendFormData(`/avatar/private/upload/${avatarId}?data_type=video`, formData2),
                    // sendFormData(`/avatar/private/upload/${avatarId}?data_type=video`, formData3)
                ]);

                if (responses.every(response => response !== null)) {
                    setIsUploading(false);
                    setOpenDialog(false);
                    setOpenSaveDialog(true);
                } else {
                    console.log("Error in uploading one or more video files");
                    setIsUploading(false);
                    toast({
                        duration: 4000,
                        variant: "destructive",
                        title: "Video Uploading Failed!",
                        description: "An error occurred while uploading your video. Please try again.",
                    });
                }
            } catch (error) {
                console.error("Error uploading videos:", error);
                setIsUploading(false);
            }
        } else {
            console.log("Please upload all three videos before continuing.");
        }
    };



    const fileUploadProp = (name) => ({
        border: "dashed",
        acceptType: "video/mp4",
        onChange: (event) => {
            console.log(`onChange for ${name}`);
            handleFileSubmit(event.target, name);
        },
        onDrop: (event) => {
            console.log(`onDrop for ${name}`);
            handleFileSubmit(event.dataTransfer, name);
        },
    });

    const handleCloseDialog = () => {
        setOpenSaveDialog(false);
    }

    const handleSaveAvatar = async () => {
        setIsUploading(true);
        const response = await fetchData(`/avatar/private/training/completed/${avatarId}`, 'POST')
        if (response) {
            setOpenSaveDialog(false);
            toast({
                duration: 3000,
                variant: "success",
                title: "Avatar successfully created",
            });
            setOpenDialog(false);
            setOpenSaveDialog(false);
            setIsUploading(false);
            navigate("/avatars");
        } else {
            console.log("Error in creating avatar");
            setIsUploading(false);
            toast({
                duration: 3000,
                variant: "destructive",
                title: "Failed to create avatar",
                description: "An error occurred while creating your avatar. Please try again.",
            });
        }
    }

    return (
        <Template.AvatarSetup>
            <Organism.VideoInstructions
                type="video-training"
                title="Video Training Instructions"
                subHeading="During the Video training, you will record a few seconds of your face that will allow us to accurately generate your AI avatar for personalized videos."
                navigateToRecord={`/avatars/create/video-training/start/${avatarId}`}
                onUpload={handleFileSubmit}
                onContinue={handleContinue}
                isUploading={isUploading}
                openDialog={openDialog && !openSaveDialog}
                setOpenDialog={setOpenDialog}
                fileUploadProp={fileUploadProp}
            />

            <Organism.Modal
                open={openSaveDialog}
                setOpen={setOpenSaveDialog}
                handleClose={handleCloseDialog}
                showInput="false"
                title="Are you sure you want to save your avatar?"
                confirmLabel="Yes Save It"
                onCancel={handleCloseDialog}
                onConfirm={handleSaveAvatar}
            />
        </Template.AvatarSetup>
    );
};

export default VideoTrainingInstructionsPage;
