import React, { useState, useEffect } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';
import { fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';
import { getFromSessionStorage } from 'lib/storage';


const PublicAvatarsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [publicAvatars, setPublicAvatars] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            const publicAvatars = await fetchData("/admin/public/avatars", 'GET');
            if (publicAvatars) {
                setPublicAvatars(publicAvatars);
            }
            setIsLoading(false);
        };

        loadData();
    }, []);


    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.AdminPanel titleText="Public Avatars">
                    <AdminOrganism.PublicAvatars publicAvatars={publicAvatars} />
                </Template.AdminPanel>
            }
        </>
    )
}

export default PublicAvatarsPage;