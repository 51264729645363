export const breakpoints = {
	"sm": 640,
	"md": 768,
	"lg": 1024,
	"xl": 1280,
	"2xl": 1536,
}

export const invitationStatus = {
	Created: {
		color: "text-white",
		secondary_color: "black",
		label: "Created",
	},
	Published: {
		color: "text-green-400",
		secondary_color: "green",
		label: "Published",
	},
	Draft: {
		color: "text-yellow-400",
		secondary_color: "yellow",
		label: "Draft",
	},
};