import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import Atom from '../../atoms';
import {
   Form,
   FormControl,
   FormDescription,
   FormField,
   FormItem,
   FormLabel,
   FormMessage,
} from '../../../../components/ui/form';
import {
   Select,
   SelectContent,
   SelectItem,
   SelectTrigger,
   SelectValue,
} from '../../../../components/ui/select';
import AppContext from '../../../context/AppContext';


// import { toast } from '@/components/ui/use-toast';
import { Input } from '../../../../components/ui/input';
import { Check } from 'lucide-react';
import { X } from 'lucide-react';
import { fetchData } from '../../../../apis/APIWrapper';

const FormSchema = Yup.object().shape({
   objective: Yup.string()
      .required('Please select an objective'),
});

const CampaignSetup = () => {
   const { variables, setVariables } = useContext(AppContext);

   const [isInputVisible, setIsInputVisible] = useState(false);
   const navigate = useNavigate();
   const { campaignId, step } = useParams();


   const form = useForm({
      resolver: yupResolver(FormSchema),
      defaultValues: {
         newVariable: '',
      },
   });

   const { register, handleSubmit, watch, setValue, formState: { errors } } = form;

   const newVariable = watch('newVariable');

   const toggleInputVisibility = () => {
      setIsInputVisible(!isInputVisible);
   };

   const addVariable = () => {
      if (newVariable) {
         setVariables(prev => [...prev, { index: (prev.length + 1).toString(), value: newVariable }]);
         setValue('newVariable', '');
         setIsInputVisible(false);
      }
   };

   const deleteVariable = (variableToDelete) => {
      setVariables(variables.filter(variable => variable.value !== variableToDelete));
   };

   const onSubmit = async (data) => {
      const submissionData = {
         objective: data.objective,
         variables: variables,
      };
      if (Object.keys(errors).length === 0) {
         const response = await fetchData(`/campaigns/objective/${campaignId}/${parseInt(step) + 1}`, 'POST', submissionData)
         if (response) {
            navigate(`/campaigns/create/avatar/${campaignId}/${parseInt(step) + 1}`);
         }
      } else {
         console.log('Validation errors:', errors);
         // Handle validation errors
      }
   }

   const Tag = ({ variable, onDelete }) => {
      return (
         <div className="relative rounded-xl font-medium text-xs text-center items-center justify-between py-1 pl-2 pr-5 bg-violet-100 text-violet-600 inline-block mr-2 mb-1">
            <div className='mr-1' title={variable}>
               {variable}
            </div>
            <div className='absolute right-0 top-0 bottom-0 flex flex-col justify-center'>
               <X className="mr-2 cursor-pointer h-3 w-3 text-gray-600" onClick={onDelete} />
            </div>
         </div>
      );
   }


   const VariableTag = ({ variables, onDelete }) => {
      return (
         <div className=''>
            {variables.map(variable => (
               <Tag
                  key={variable.index}
                  variable={variable.value}
                  onDelete={() => onDelete(variable.value)}
               />
            ))}
            <Atom.Button type="skull" onClick={toggleInputVisibility}>+Add Variable</Atom.Button>
         </div>
      );
   };


   return (
      <div className='flex flex-col mx-auto lg:my-auto p-10 2xl:py-14 gap-10 md:w-4/6 2xl:w-1/2 bg-[#f8f8f8] rounded-2xl'>
         <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 lg:w-2/3 mx-auto">
               <FormField
                  control={form.control}
                  name="objective"
                  render={({ field }) => (
                     <FormItem>
                        <FormLabel className="text-lg text-gray-600">Objective</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                           <FormControl>
                              <SelectTrigger>
                                 <SelectValue placeholder="Select your objective" />
                              </SelectTrigger>
                           </FormControl>
                           <SelectContent>
                              <SelectItem value="B2B Sales Outreach">B2B Sales Outreach</SelectItem>
                              <SelectItem value="Sales Follow Up">Sales Follow Up</SelectItem>
                              <SelectItem value=" Contact Customer Feedback">Contact Customer Feedback</SelectItem>
                              <SelectItem value="Thank Customer">Thank Customer</SelectItem>
                              <SelectItem value="Customer Retention">Customer Retention</SelectItem>
                              <SelectItem value="Onboard New Customers">Onboard New Customers</SelectItem>
                              <SelectItem value="Contact Customer Feedback">Contact Customer Feedback</SelectItem>

                           </SelectContent>
                        </Select>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <FormField
                  control={form.control}
                  name="variables"
                  render={({ field }) => (
                     <FormItem>
                        <FormLabel className="text-lg text-gray-600">Script Variables</FormLabel>
                        <VariableTag
                           variables={variables}
                           onDelete={deleteVariable}
                        />
                        {/* <Atom.Button type="skull" onClick={toggleInputVisibility}>Add Variable</Atom.Button> */}
                        {isInputVisible && (
                           <div className="relative w-80 sm:w-auto">
                              <Input
                                 {...register('newVariable')}
                                 type="text"
                                 value={newVariable}
                                 onChange={(e) => setValue('newVariable', e.target.value.replace(/\s/g, ''))}
                                 onKeyPress={(e) => {
                                    if (e.key === ' ') e.preventDefault();
                                    if (e.key === 'Enter') {
                                       e.preventDefault();
                                       addVariable();
                                    }
                                 }}
                                 className="flex-grow rounded-3xl"
                                 placeholder="Enter new variable"
                              />
                              <Check className="absolute text-[#9B9B9B] h-4 w-4 top-3 right-4" onClick={addVariable} />
                              {errors.newVariable && <p>{errors.newVariable.message}</p>}
                           </div>
                        )}
                        <FormDescription>Note - These variables will later be mapped to your contact data. So please ensure that you only add variables that exists in your dataset.</FormDescription>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <Atom.Button type="primary" width="fullwidth">Continue</Atom.Button>
            </form>
         </Form>
      </div>
   );
}

export default CampaignSetup