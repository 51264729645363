import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Progress } from '../../../../components/ui/progress';
import { Microphone } from '../../../assets/images';
import Atom from '../../atoms';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { BadgeCheck } from 'lucide-react';


const AudioTraining = () => {
    const { avatarId } = useParams();
    const navigate = useNavigate();
    // const [progress, setProgress] = useState(0);
    const startAudioTrainingHandler = () => {
        navigate(`/avatars/create/audio-training/start/${avatarId}`);
    }
    return (
        <ScrollArea>
            <div className='flex flex-col gap-2 2xl:gap-9 overflow-auto mx-auto lg:w-[75%] 2xl:w-[60%] px-16 py-1 my-4 2xl:py-10 2xl:my-6 rounded-2xl'>
                <h2 className='text-2xl text-center font-extrabold text-gray-800'>Audio Instructions</h2>
                <div className='flex flex-col gap-3 justify-center items-center px-10 py-3 '>
                    <img src={Microphone} alt="Audio Instructions" className='w-10 h-10' />
                    <p className='text-sm text-gray-500 max-w-lg text-center'>During the Audio training, you will record a few sentences that will allow us to accurately capture your voice.</p>
                </div>
                <div className='flex flex-col gap-2 2xl:gap-4'>
                    <h3 className='text-xl font-bold text-center'>Tips:</h3>
                    <div className='flex flex-col gap-4 bg-violet-100 px-6 py-6 rounded-3xl'>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1 rounded-sm' />
                            <p className='text-sm'>Use the same microphone that you plan to use when recording your campaign video subsequently.</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1' />
                            <p className='text-sm'>Read the sentences you see on the Audio Training screen as clearly as possible.</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[3.7%] h-[3.7%] 2xl:w-[2.7%] 2xl:h-[2.7%] text-green-700 mt-1' />
                            <p className='text-sm'>If you make a mistake on any of the sentences it doesn’t matter. Just correct the mistake and continue reading it after (no need to re-record).</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1' />
                            <p className='text-sm'>The audio & video must be of the same person that that will record the campaign videos later.</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1' />
                            <p className='text-sm'> Please do your recording in a sound-proof environment (without any background noises).</p>
                        </div>
                        <div className='flex gap-2 items-start'>
                            <BadgeCheck className='w-[2.5%] h-[2.5%] 2xl:w-[2.2%] 2xl:h-[2.2%] text-green-700 mt-1' />
                            <p className='text-sm'>Use emotions in your speech as much as possible.</p>
                        </div>
                    </div>
                </div>
                <div className='flex gap-2 2xl:gap-4 items-center justify-center '>
                    <Atom.Button type="primary" className="w-[25%] 2xl:w-[20%]" onClick={startAudioTrainingHandler}>Yeah, let's train!</Atom.Button>
                </div>
            </div>
        </ScrollArea>
    )
}

export default AudioTraining;