import React from 'react';
import { cn } from "lib/utils";


const DeleteButton = ({ onClick, className, type }) => {
    return (
        <button
            className={cn(`w-8 h-8 bg-red-500 text-white text-xs rounded-full flex items-center justify-center bg-opacity-45 transition-opacity duration-300 hover:bg-opacity-100 ${type === "avatars_list" ? "opacity-0 group-hover:opacity-90 " : null}`, className)}

            onClick={onClick}
        >
            <svg
                viewBox="0 0 448 512"
                className={`transition-all duration-300 group-hover:w-[1.2rem] transform group-hover:translate-y-1/5 ${type === "avatars_list" ? "w-[.1rem]" : "w-[1rem]"}`}
            >
                <path
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                    fill="white"
                />
            </svg>
        </button>
    );
};

export default DeleteButton;
