import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Template from '../../common/components/templates';
import Organisms from 'common/components/organisms';
import AppContext from 'common/context/AppContext';
import { sendFiles } from 'apis/APIWrapper';



const VideoCampaignInstructionsPage = () => {
    const { campaignId, step } = useParams();
    const { videoUrl, setVideoUrl, transcription, setTranscription } = useContext(AppContext);
    const [isUploading, setIsUploading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    const fileUploadProp = {
        border: "dashed",
        acceptType: "video/mp4",
        onChange: (event) => {
            handleFileSubmit(event.target)
            // handleFileSubmit()

        },
        onDrop: (event) => {
            handleFileSubmit(event.dataTransfer)
            // handleFileSubmit()

        },
    };

    const handleFileSubmit = (event) => {
        if (event.files !== null && event?.files?.length > 0) {
            const file = event.files[0];
            // props.setUploadedVideo(file);
            // if (file && props.type === 'campaign') {
            setVideoUrl(URL.createObjectURL(file));
            // localStorage.setItem("transcriptionDone", false)
            // } else {
            // fileUploadProp.description = "description"
            // }
        }
    }

    // with videoMode
    // const handleContinue = async () => {
    //     const res = await fetch(videoUrl);
    //     const videoBlob = await res.blob();
    //     const videoFile = new File([videoBlob], 'video.mp4', { type: 'video/mp4' });

    //     const formData = new FormData();
    //     formData.append('video', videoFile);
    //     formData.append('videoMode', 'uploaded');

    //     for (let pair of formData.entries()) {
    //         console.log(pair[0] + ':', pair[1]);
    //     }

    //     const response = await sendFiles(`/campaigns/upload/video/${campaignId}/${parseInt(step) + 1}`, formData);

    //     setIsUploading(true);
    //     if (response) {
    //         setIsUploading(false);
    //         setTranscription(response.transcription);
    //         navigate(`/campaigns/create/variables/${campaignId}/${parseInt(step) + 1}`);
    //     } else {
    //         console.log('No response found');
    //     }
    //     setOpenDialog(false);
    // };


    const handleContinue = async () => {
        const res = await fetch(videoUrl);
        const videoBlob = await res.blob();
        const videoFile = new File([videoBlob], "video.mp4", { type: 'video/mp4' });
        setIsUploading(true);
        const mode = {
			'video_mode': 'uploaded'
		}
        const response = await sendFiles(`/campaigns/upload/video/${campaignId}/${parseInt(step) + 1}`, videoFile, mode);
        if (response) {
            setIsUploading(true);
            setTranscription(response.transcription);
            navigate(`/campaigns/create/variables/${campaignId}/${parseInt(step) + 1}`);
        } else {
            console.log('No response found');
        }
        setOpenDialog(false);
    };


    return (
        <Template.CampaignSetup>
            <Organisms.VideoInstructions
                type="video-campaign"
                title="Campaign Video Instructions"
                navigateToRecord={`/campaigns/create/video/${campaignId}/${parseInt(step) + 1}`}
                // navigeAfterUpload={`/campaigns/create/variables/${campaignId}/${parseInt(step) + 1}`}
                onUpload={handleFileSubmit}
                onContinue={handleContinue}
                isUploading={isUploading}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                fileUploadProp={fileUploadProp}
            />
        </Template.CampaignSetup>
    )
}

export default VideoCampaignInstructionsPage;