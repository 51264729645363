import React, { useState, useEffect } from 'react';
import {
    Command,
    CommandGroup,
    CommandList,
} from "../../../../components/ui/command";
import {
    Popover,
    PopoverContent,
} from "../../../../components/ui/popover";

import { PlusCircle, Search, } from 'lucide-react'
import { Input } from '../../../../components/ui/input';
import Atom from '../../atoms';


const VariableDropdown = ({ x, y, dropdownSelect, handleClose, vars, setVars, removeVars, highlightedWordIndex, setHighlightedWordIndex,assignedVariable, assignedVariables, setAssignedVariable, updateAssignedVariables }) => {
    const [value, setValue] = useState("");
    const [filteredVars, setFilteredVars] = useState(vars);
    const [newOption, setNewOption] = useState("");


    useEffect(() => {
        if (highlightedWordIndex !== null) {
            const assignedVar = vars.find((variable) => variable.index === highlightedWordIndex);
            if (assignedVar) {
                setAssignedVariable(assignedVar.value);
            } else {
                setAssignedVariable(""); 
            }
        }
    }, [highlightedWordIndex, vars]);

    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setValue(searchTerm);
        setFilteredVars(
            vars.filter((variable) => variable.value.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };


    const handleVariableSelect = (statusValue) => {
        dropdownSelect(statusValue);
        setAssignedVariable(statusValue);
        updateAssignedVariables(highlightedWordIndex, statusValue);
    };

    const handleAddNewOption = () => {
        if (newOption) {
            const lastIndex = vars.length > 0 ? parseInt(vars[vars.length - 1].index) : 0;
            const newVar = { index: (lastIndex + 1).toString(), value: newOption };
            setVars(prevVars => [...prevVars, newVar]);
            setFilteredVars([...filteredVars, newVar]);
            setNewOption("");
            setAssignedVariable(newOption);
        }
    };

    const handleRemoveVariable = () => {
        removeVars(highlightedWordIndex); 
        setHighlightedWordIndex(null); 
    };

    return (
        <Popover open={true} onOpenChange={() => { }}>
            <PopoverContent className="w-56 p-0 rounded-lg rounded-b-lg shadow-xl" style={{ position: 'absolute', left: `${x}px`, top: `${y}px` }}>
                <Command>
                    <div className="relative w-80 sm:w-auto">
                        <Input
                            placeholder="Search variable..."
                            value={value}
                            onChange={handleInputChange}
                            className='rounded-md'
                        />
                        <Search className="absolute text-[#9B9B9B] h-4 w-4 top-3 right-4" />
                    </div>
                    <CommandList className="max-h-40 overflow-y-auto">
                        <CommandGroup>
                            {filteredVars.map((variable) => (
                                <button
                                    key={variable.value}
                                    onClick={(value) => {
                                        handleVariableSelect(variable.value); 
                                    }}
                                    className={`w-full rounded-md py-2 px-4 text-left block whitespace-no-wrap text-sm ${variable.value === value ? 'bg-gray-200 text-gray-500' : 'hover:bg-violet-100 hover:text-violet-600'} focus:outline-none`}
                                >
                                    {variable.value}
                                </button>

                            ))}
                        </CommandGroup>
                    </CommandList>
                    <div className='flex flex-col gap-0 mt-4'>
                        <div className="relative">
                            <Input
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                placeholder="Add new variable..."
                                className='rounded-t-md rounded-b-none border-'
                            />
                            <PlusCircle
                                onClick={handleAddNewOption}
                                className="absolute text-[#9B9B9B] hover:text-[#612FFF] h-4 w-4 top-3 right-4"
                            />
                        </div>
                        <div className={`grid ${assignedVariables[highlightedWordIndex] ? 'grid-cols-2' : 'grid-cols-1'}`}>
                            {assignedVariables[highlightedWordIndex] && (
                                <Atom.Button
                                    type="secondary"
                                    onClick={handleRemoveVariable}
                                    className="rounded-br-none rounded-bl-lg rounded-t-none"
                                >
                                    Remove
                                </Atom.Button>
                            )}
                            <Atom.Button
                                type="primary"
                                onClick={handleClose}
                                className="rounded-br-lg rounded-bl-none rounded-t-none"
                            >
                                Close
                            </Atom.Button>
                        </div>
                    </div>
                </Command>
            </PopoverContent>
        </Popover >
    );
}
export default VariableDropdown;



