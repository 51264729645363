import Avatar from "./Avatar";
import Button from "./Button";
import Visibility from "./Visibility";
import TableCell from "./TableCell";
import Loader from "./Loader";
import Checkbox from "./Checkbox";
import DeleteButton from "./DeleteButton";

const Atom = {
  Avatar,
  Button,
  Visibility,
  TableCell,
  Loader,
  Checkbox,
  DeleteButton,
}

export default Atom;
