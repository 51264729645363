import React from 'react';
import CampaignsOrganism from '../../common/components/organisms/campaigns';
import Template from '../../common/components/templates';
import { useLocation } from 'react-router-dom';


const AddThumbnailPage = () => {
    const location = useLocation();
    const { isEditing, returnPath } = location.state || {};


    return (
        <Template.CampaignSetup>
            <CampaignsOrganism.CustomizeThumbnail isEditing={isEditing} returnPath={returnPath}/>
        </Template.CampaignSetup>
    )
}

export default AddThumbnailPage;