import React from 'react';

const AvatarsDefault = () => {
    return (
        <div className='flex justify-center'>
            <h2>This will be the Default page if no avatars being created yet</h2>
        </div>
    )
}

export default AvatarsDefault;
