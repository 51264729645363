import React from 'react';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator, BreadcrumbPage } from 'components/ui/breadcrumb';

const BreadcrumbTrails = ({ breadcrumbs }) => {
    return (
        <Breadcrumb className="mx-5 mb-5">
            <BreadcrumbList className="py-2 3xl:py-4 text-xl font-semibold">
                {breadcrumbs.map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                        <BreadcrumbItem>
                            {breadcrumb.href ? (
                                <BreadcrumbLink href={breadcrumb.href}>{breadcrumb.text}</BreadcrumbLink>
                            ) : (
                                <BreadcrumbPage className="text-violet-600 font-semibold">{breadcrumb.text}</BreadcrumbPage>
                            )}
                        </BreadcrumbItem>
                        {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                    </React.Fragment>
                ))}
            </BreadcrumbList>
        </Breadcrumb>
    );
};

export default BreadcrumbTrails;
