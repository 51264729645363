import React from "react";
import { ClerkProvider } from "@clerk/clerk-react";
import { BrowserRouter } from 'react-router-dom';
import AppRouter from "./routes/AppRouter";
import { AppContextProvider } from "./common/context/AppContext";
import { Toaster } from "./components/ui/toaster";

function App() {

  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  return (
    <AppContextProvider>
      <BrowserRouter>
        <ClerkProvider publishableKey={clerkPubKey}>
          <div className="h-[100vh]">
            <main className="h-[100vh]">
              <AppRouter />
              <Toaster />
            </main>
          </div>
        </ClerkProvider>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;


