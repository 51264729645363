import React, { useState, useEffect } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';
import { fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';

const CustomersPage = () => {
    const [customers, setCustomers] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const dummyCustomersData = [
        {
            id: 1,
            customerName: "New XP",
            email: "abc@gmail.com",
            signUpDate: "2024-06-28T13:04:28.204750",
            billingPlan: "FREE",
            lastBillingDate: "2024-06-26T13:04:28.204754",
            lastActivityDate: "2024-06-26T13:04:28.204750",
            noOfAvatars: "50",
            noOfCampaigns: "10",
            noOfContacts: "50",
            videosGenerated: "20",
        },
        {
            id: 2,
            customerName: "ReferScout",
            email: "abc@gmail.com",
            signUpDate: "2024-06-28T13:04:28.204750",
            billingPlan: "STARTUP",
            lastBillingDate: "2024-06-28T13:04:28.204750",
            lastActivityDate: "2024-06-28T13:04:28.204750",
            noOfAvatars: "30",
            noOfCampaigns: "16",
            noOfContacts: "50",
            videosGenerated: "20",
        },
        {
            id: 3,
            customerName: "Dealintent",
            email: "abc@gmail.commmmm",
            signUpDate: "2024-06-28T13:04:28.204750",
            billingPlan: "FREE",
            lastBillingDate: "2024-06-28T13:04:28.204750",
            lastActivityDate: "2024-06-28T13:04:28.204750",
            noOfAvatars: "20",
            noOfCampaigns: "10",
            noOfContacts: "50",
            videosGenerated: "20",
        },
    ]

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            const customersList = await fetchData("/admin/list/customer", 'GET');
            console.log(customersList);
            if (customersList) {
                setCustomers(customersList);
            }
            setIsLoading(false);
        };

        loadData();
    }, []);

    console.log(customers);

    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.AdminPanel titleText="Customers">
                    <AdminOrganism.Customers customers={customers} />
                </Template.AdminPanel>
            }
        </>
    )
}

export default CustomersPage;