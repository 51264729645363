import React, { useState, useEffect } from 'react'
import AdminOrganism from '../../../common/components/organisms/admin';
import Template from '../../../common/components/templates';
import Organism from 'common/components/organisms';
import { useParams, useNavigate } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner';
import { fetchData } from 'apis/APIWrapper';
import { NOT_FOUND_PAGE_PATH } from 'routes/routeConstants';
import CampaignDetails from 'common/components/organisms/admin/CampaignDetails';


const CampaignDetailsPage = () => {

    const { campaignId } = useParams();
    const [campaignData, setCampaignData] = useState({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const dummyCampaignDetailsData = {
        campaignName: "ReferScout intro",
        customerId: "2",
        variables: [
            {
                index: 1,
                variable: "first_name",
            },
            {
                index: 2,
                variable: "company_name",
            },
            {
                index: 2,
                variable: "last_name",
            },
        ],
        details: [
            {
                id: 1,
                email: "john@gmail.com",
                status: "GENERATED",
                first_name: "JOHN",
                company_name: "google",
                last_name: "DOE",
                videoUrl: "2024-06-26T13:04:28.204750",
                landingPageUrl: "2024-06-26T13:04:28.204750",
                thumbnailUrl: "2024-06-26T13:04:28.204750",
            },
            {
                id: 2,
                email: "mary@gmail.com",
                status: "QUEUED",
                first_name: "JACK",
                company_name: "facebook",
                last_name: "FRASER",
                videoUrl: "2024-06-28T13:04:28.204750",
                landingPageUrl: "2024-06-28T13:04:28.204750",
                thumbnailUrl: "2024-06-28T13:04:28.204750",
            },
            {
                id: 3,
                email: "kck@gmail.com",
                status: "QUEUED",
                first_name: "STEVE",
                company_name: "microsoft",
                last_name: "SMITH",
                videoUrl: "2024-06-28T13:04:28.204750",
                landingPageUrl: "2024-06-28T13:04:28.204750",
                thumbnailUrl: "2024-06-28T13:04:28.204750",
            },
        ]
    }


    useEffect(() => {
        fetchCampaignData();
    }, [campaignId]);

    const fetchCampaignData = async () => {
        try {
            const response = await fetchData(`/summary/campaign/${campaignId}`);
            if (response && response.campaign_name) {
                setCampaignData(response);
            } else {
                navigate(NOT_FOUND_PAGE_PATH);
                return;
            }
        } catch (error) {
            console.error("Error fetching campaign data:", error);
            navigate(NOT_FOUND_PAGE_PATH);
            return;
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
                    <ThreeCircles
                        visible={true}
                        height="100"
                        width="100"
                        color="#e6a6e0"
                        ariaLabel="three-circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>

            ) :
                <Template.AdminPanel titleText="ReferScout">
                    <Organism.BreadcrumbTrails
                        breadcrumbs={[
                            { href: "/admin/customers", text: "Customers" },
                            { href: `/admin/customers/details/${dummyCampaignDetailsData.customerId}`, text: "Campaigns" },
                            { text: campaignData?.campaignName }
                        ]}
                    />
                    <AdminOrganism.CampaignDetails campaign={campaignData} />
                </Template.AdminPanel>
            }
        </>
    )

}

export default CampaignDetailsPage;