import React, { useState, useEffect } from "react";
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import Atom from "../../atoms";
import Molecule from "../../molecules";
import Organism from "../../organisms";

import { isBreakpoint, createColumnsForTable } from "../../../../lib/index";
import { ScrollArea } from "../../../../components/ui/scroll-area";

const CustomerDetails = ({
    customer,
    customAvatars,
}) => {
    const [columns, setColumns] = useState([]);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [initialCardsToShow, setInitialCardsToShow] = useState(4);
    const [showMoreMy, setShowMoreMy] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const totalPages = Math.ceil(customer?.length / itemsPerPage);

    const getStartingIndex = () => (currentPage - 1) * itemsPerPage;

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        // Here we can also fetch new data based on the new page if the data is fetched from an API
    };


    const DataCell = ({ requiredData }) => {
        return (
            <div className="flex justify-center items-center">
                {requiredData}
            </div>
        );
    };

    const DateCell = ({ requiredData }) => {
        return (
            <div className="flex justify-center items-center">
                {formatDate(requiredData)}
            </div>
        );
    };

    const dataSource = customer
        ?.slice(getStartingIndex(), getStartingIndex() + itemsPerPage)
        .map((customerDetail, index) => ({
            key: customerDetail.id,
            campaignName: <DataCell requiredData={customerDetail?.campaignName} />,
            createdDate: <DateCell requiredData={customerDetail?.createdDate} />,
            noOfContacts: <DataCell requiredData={customerDetail?.noOfContacts} />,
            videosGenerated: <DataCell requiredData={customerDetail?.videosGenerated} />,
            lastActivityDate: <DateCell requiredData={customerDetail?.lastActivityDate} />,
        }));

    useEffect(() => {
        let adjustedColumn = createColumnsForTable(["CAMPAIGN NAME", "VIDEOS GENERATED"]);

        if (isBreakpoint("md")) {
            adjustedColumn = createColumnsForTable(["CUSTOMER NAME", "NO. OF CONTACTS", "VIDEOS GENERATED"]);
        }

        if (isBreakpoint("lg")) {
            adjustedColumn = createColumnsForTable([
                "CAMPAIGN NAME",
                "CREATED DATE",
                "NO. OF CONTACTS",
                "VIDEOS GENERATED",
                "LAST ACTIVITY DATE",
            ]);
            adjustedColumn.push({
                title: "",
                dataIndex: "edit",
                key: "edit",
            });
        }
        setColumns(adjustedColumn);
        setIsTableVisible(true);
    }, []);


    useEffect(() => {
        const updateInitialCardsToShow = () => {
            if (window.matchMedia('(min-width: 1536px)').matches) {
                setInitialCardsToShow(7); // For 2xl screens
            } else if (window.matchMedia('(min-width: 1024px)').matches) {
                setInitialCardsToShow(5); // For 2xl screens
            } else if (window.matchMedia('(min-width: 768px)').matches) {
                setInitialCardsToShow(4); // For lg screens
            } else if (window.matchMedia('(min-width: 576px)').matches) {
                setInitialCardsToShow(3); // For md screens
            } else {
                setInitialCardsToShow(2); // For smaller screens
            }
        };

        updateInitialCardsToShow();
        window.addEventListener('resize', updateInitialCardsToShow);

        return () => window.removeEventListener('resize', updateInitialCardsToShow);
    }, []);

    const toggleShowMoreMy = () => setShowMoreMy(!showMoreMy);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? "0" + day : day;
        const formattedMonth = month < 10 ? "0" + month : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    return (
        <ScrollArea>
            <Organism.Content className="px-5 gap-10 2xl:gap-12">
                <div className="flex flex-col gap-4 2xl:gap-8">
                    <h1 className="text-2xl font-semibold">Campaigns</h1>
                    <Atom.Visibility state={customer?.length !== 0}>
                        <Molecule.ContentTable className="px-0 flex-none border rounded-xl xl:h-52 2xl:h-72">
                            <Atom.Visibility state={isTableVisible}>
                                <Organism.Table dataSource={dataSource} columns={columns} type="customerDetails" />
                            </Atom.Visibility>
                        </Molecule.ContentTable>
                        {/* <Molecule.ContentPagination className="h-[55px]">
                    <Organism.CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Molecule.ContentPagination> */}
                    </Atom.Visibility>
                    <Atom.Visibility state={customer?.length === 0}>
                        <h3 className="text-lg text-gray-500 text-center">No campaigns created by this customer yet !</h3>
                    </Atom.Visibility>

                </div>
                <div className="flex flex-col gap-4 2xl:gap-8">
                    <h1 className="text-2xl font-semibold">Customer Avatars</h1>
                    <Atom.Visibility state={customAvatars?.length !== 0}>
                        <div className="flex flex-col gap-6 overflow-auto mx-auto lg:w-[90%] 2xl:w-[90%] px-4 py-4 my-auto bg-[#f8f8f8] rounded-2xl">
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-5">
                                {customAvatars?.slice(0, showMoreMy ? customAvatars.length : initialCardsToShow).map((avatar, index) => (
                                    <Organism.AvatarCard
                                        key={index}
                                        name={avatar.name}
                                        status={avatar.status}
                                        imageUrl={avatar.imageUrl}
                                        createdOn={avatar.createdOn}
                                    // isSelected={selectedAvatar === avatar.id}
                                    // onSelect={() => handleSelect(avatar)}
                                    />
                                ))}
                            </div>
                            {customAvatars?.length > initialCardsToShow && (
                                <button onClick={toggleShowMoreMy} className="mt-4 flex items-center justify-center text-gray-500 text-xs">
                                    {showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
                                    <span className="mx-2">{showMoreMy ? 'Show Less' : 'Show More'}</span>
                                    {/* {showMoreMy ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />} */}
                                </button>
                            )}
                        </div>
                    </Atom.Visibility>
                    <Atom.Visibility state={customAvatars?.length === 0}>
                        <h3 className="text-lg text-gray-500 text-center">No private avatars created by this customer !</h3>
                    </Atom.Visibility>
                </div>

            </Organism.Content>
        </ScrollArea>
    );
};

export default CustomerDetails;
