import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { ScrollArea } from '../ui/scroll-area';
import Atom from '../../common/components/atoms';
import { fetchData } from 'apis/APIWrapper';
import { ThreeCircles } from 'react-loader-spinner';
import { MainLogo } from 'common/assets/images';


// const dummyLandingPageData = {
//   layout: 'theme 1',
//   backgroundColor: '#ccc',
//   fontColor: '#333',
//   buttonColor: '#612FFF',
//   logoImageUrl: '',
//   logoWidth: '8',
//   // logoHeight: '20',
//   titleText: 'Here is your title Text, Okay!aaa bbb cccc dddd eeee',
//   descriptionText: 'Here is your sub heading to express what you mean in detail Here is your sub heading to express what you mean in detailaaa bbbb cccc dddd eee fff ggg hhhh',
//   buttonText: 'Lets Go',
//   showButton: true,
// videoUrl: "https://videos.pexels.com/video-files/15921892/15921892-sd_640_360_25fps.mp4"
//   iframeScript: `
//   <!-- Calendly inline widget begin -->
//   <div class="calendly-inline-widget" data-url="https://calendly.com/debangagoswami737/30min" style="min-width:320px;height:700px;"></div>
//   <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
//   <!-- Calendly inline widget end -->
// `
// }


const LandingPage = () => {
  const { landingPageId } = useParams();
  const iframeRef = useRef(null);
  const [landingPageData, setLandingPageData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLandingPageData();
  }, [])

  const fetchLandingPageData = async () => {
    setIsLoading(true);
    const response = await fetchData(`/landingPage/${landingPageId}`)
    if (response) {
      setLandingPageData(response);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (landingPageData.iframeScript) {
      const iframe = iframeRef.current;
      if (iframe) {
        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(`
          <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Embedded Script</title>
            </head>
            <body>
              ${landingPageData.iframeScript}
            </body>
          </html>
        `);
        doc.close();
      }
    }
  }, []);



  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50" style={{ zIndex: 1050 }}>
          <ThreeCircles
            visible={true}
            height="100"
            width="100"
            color="#e6a6e0"
            ariaLabel="three-circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

      ) :
        <ScrollArea className="h-[100%] w-full"> {/* set h-[100%] pb-6 for no outer scroll and scroll in custo block */}  {/* h-[99%] & 2xl:h-[94%] */}
          <div className={`h-[100%] min-h-[600px] md:min-h-[870px] 2xl:min-h-[900px] flex flex-grow flex-col gap-4 px-12 lg:px-14 py-10  ${landingPageData.layout === 'theme 1' ? 'items-start' : 'items-center'}`} style={{ backgroundColor: landingPageData.backgroundColor }}>  {/* remove h- style for no outer scroll and scroll in custo block */}
            <img
              src={landingPageData.logoImageUrl || MainLogo}
              alt="Logo"
              style={{ width: `${landingPageData.logoWidth}%` }}
              className="mb-4"
            />
            {/* <div className={`flex ${landingPageData.layout === 'theme 1' ? 'flex-row' : 'flex-col'} items-center justify-center gap-4 min-w-[100%]`}> */}
            <div className={`flex flex-col sm:flex-col ${landingPageData.layout === 'theme 1' ? 'lg:flex-row' : 'lg:flex-col'} items-center justify-center gap-4 min-w-[100%]`}>
              <div className="flex flex-col items-center text-wrap">
                <div className={`flex flex-col gap-1 items-center max-w-[90%] 2xl:max-w-[90%] min-w-[90%] 2xl:min-w-[90%] text-wrap`}>
                  <h1 className="text-2xl mb-2 font-semibold break-words" style={{ color: landingPageData.fontColor }}>
                    {landingPageData.titleText}
                  </h1>
                  <p className="text-lg mb-4 text-center break-words" style={{ color: landingPageData.fontColor }}>
                    {landingPageData.descriptionText}
                  </p>
                </div>

                {landingPageData.showButton && (
                  <Atom.Button type="primary" style={{ backgroundColor: landingPageData.buttonColor, border: "none", }} className="min-w-[35%] lg:min-w-[12%]">
                    {landingPageData.buttonText}
                  </Atom.Button>
                )}
              </div>
              <video
                controls
                src={landingPageData.videoUrl}
                className="mb-4 rounded-3xl w-full max-w-[710px] 2xl:max-w-[760px] min-w-[270px] 2xl:min-w-[470px] max-h-[370px] 2xl:max-h-[396px] min-h-[100px] 2xl:min-h-[260px] object-cover"

              />
            </div>

            {landingPageData.iframeScript && (
              <iframe
                ref={iframeRef}
                className="w-[100%] h-[750px]"
                title="Embedded Script"
              />
            )}
          </div>
        </ScrollArea>
      }
    </>
  )
}

export default LandingPage;